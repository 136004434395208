import styles from "./PageTitle.module.css"

const PageTitle = (props: {
  children: React.ReactNode
}) => {
  return (
    <div
      className={[
        styles.container,
        "d-flex align-items-center justify-content-between mb-4"
      ].join(" ")}
    >
      {props.children}
    </div>
  )
}

export default PageTitle