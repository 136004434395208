import { v4 } from "uuid";
import { INCOME_TYPES } from "../lib/constants";
import { Income } from "../lib/types";

export const createIncome = (memberId: string | null, type: keyof typeof INCOME_TYPES = INCOME_TYPES.OTHER_ANNUAL_INCOME): Income => {
  const income = {
    id: createIncomeId(),
    type,
    memberId,
    amount: 0,
    name: ""
  }
  return income
}

export const createIncomeId = (): string => {
  return v4()
}