import { Debt } from "../../lib/types"
import { UNLOAD_DATA } from "../actions/data"
import { ADD_DEBT, REMOVE_DEBT, SET_DEBTS, UPDATE_DEBT } from "../actions/debts"

const initial: Debt[] = []

const debts = (state = initial, action) => {
  switch (action.type) {
    case SET_DEBTS:
      return action.payload || []

    case ADD_DEBT:
      const newState = state.filter(debt => debt.id !== action.payload.id)
      newState.push(action.payload)
      return newState

    case UPDATE_DEBT:
      return state.map(debt => {
        if (debt.id === action.payload.id) debt = Object.assign({}, debt, action.payload)
        return debt
      })

    case REMOVE_DEBT:
      return state.filter(debt => debt.id !== action.payload.id)

    case UNLOAD_DATA:
      return initial
    default:
      return state
  }
}

export default debts