import { call, put, select } from "redux-saga/effects"
import * as members from "../services/members"
import { addMember } from "../store/actions/members"

export const selectMembersState = (state) => state.members

export function* createMember(action) {
  const id = yield call(members.createMemberId)
  const membersInState = yield select(selectMembersState)
  const color = yield call(members.createMemberColor, membersInState)
  const member = yield call(members.createMember, { id, color, ...action.payload })
  yield put(addMember(member))
}