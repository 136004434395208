import Card from "../../components/Household/Card"
import PageTitle from "../../components/Household/PageTitle"
import CardTitle from "../../components/Household/CardTitle"
import Cards from "../../components/Household/Cards"
import Property from "../../components/Household/Property"
import { LOCATION, MEMBER_TYPES, NAME, YEAR_OF_BIRTH } from "../../lib/constants"
import { useDispatch } from "react-redux"
import { createMember, updateMember, removeMember } from "../../store/actions/members"
import { setHousehold } from "../../store/actions/household"
import { Member, Household } from "../../lib/types"
import PropertyList from "../../components/Household/PropertyList"
import { confirmMessages } from "../../lib/config/copy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import Suggestions from "../../components/Suggestions";
import memberIcons from "../../lib/config/memberIcons";
import { memberAgeCategory } from "../../lib/utils/member";

const HouseholdAndMembers = ({ household, members }: { household: Household, members: Member[] }) => {
  const dispatch = useDispatch()

  const noMembers = useMemo(() => {
    return !members.length
  }, [members])

  const hasPrimaryMember = useMemo(() => {
    return members.find(member => member.type === MEMBER_TYPES.PRIMARY)
  }, [members])

  const hasPartnerMember = useMemo(() => {
    return members.find(member => member.type === MEMBER_TYPES.PARTNER)
  }, [members])

  const handleMemberChange = (newMember) => {
    dispatch(updateMember(newMember))
  }

  const handleHouseholdChange = (newHousehold) => {
    dispatch(setHousehold(newHousehold))
  }

  const handleCreate = (type) => {
    // if (members[members.length - 1] && !members[members.length - 1].firstName) return
    dispatch(createMember({ ...(type ? { type } : {}) }))
  }

  const handleRemove = (member: Member) => {
    if (!window.confirm(confirmMessages['memberRemove'])) return
    dispatch(removeMember({ id: member.id }))
  }

  const addMemberConfig = [
    {
      title: 'Add partner',
      value: MEMBER_TYPES.PARTNER,
      icon: 'heart',
    },
    {
      title: 'Add other member',
      value: MEMBER_TYPES.OTHER,
      icon: 'family'
    }
  ]

  return (
    <div>
      <PageTitle>
        <div className="headline-5">Household and member details</div>
        {/*<Button onClick={() => handleCreate()}>+ Add new member</Button>*/}
      </PageTitle>
      <Cards>
        <Card>
          <CardTitle variant="household" title="Household details" />
          <PropertyList>
            <Property id={LOCATION} type={LOCATION} icon="location-dot" data={household} onChange={handleHouseholdChange} />
          </PropertyList>
        </Card>
        {members.map(member => (
          <Card key={member.id}>
            <CardTitle
              variant='member'
              secondaryIcon={memberIcons[memberAgeCategory(new Date().getFullYear(), member.yearOfBirth)]}
              initial={member.firstName?.[0] || ''}
              color={member.color}
              title={member.firstName || "-"}
              {...(member.type !== MEMBER_TYPES.PRIMARY ? { onRemove: () => handleRemove(member) } : {})}
            />
            <PropertyList>
              <Property id={member.id + NAME} type={NAME} icon="address-card" data={member} onChange={handleMemberChange} />
              <Property id={member.id + YEAR_OF_BIRTH} type={YEAR_OF_BIRTH} icon="cake" data={member} onChange={handleMemberChange} />
            </PropertyList>
          </Card>
        ))}
        {noMembers && (
          <Card variant="CTA" onClick={() => handleCreate(MEMBER_TYPES.PRIMARY)}>
            <div className='py-3'>
              <div className='text-center'>
                <FontAwesomeIcon icon={["fas", "user-alt"]} />
                <div>Add first member</div>
              </div>
            </div>
          </Card>
        )}
        {hasPrimaryMember && !hasPartnerMember && (
          <Card>
            <div className='py-3'>
              <Suggestions suggestions={addMemberConfig} onClick={(value) => handleCreate(value)} variant='centered' />
            </div>
          </Card>
        )}
        {hasPartnerMember && (
          <Card variant="CTA" onClick={() => handleCreate(MEMBER_TYPES.OTHER)}>
            <div className='py-3'>
              <div className='text-center'>
                <FontAwesomeIcon icon={["fas", "family"]} />
                <div>Add other member</div>
              </div>
            </div>
          </Card>
        )}
      </Cards>
    </div>
  )
}

export default HouseholdAndMembers