import { Button, ButtonGroup, FloatingLabel, Form } from "react-bootstrap";
import styles from './Input.module.css'
import DatePicker from "./DatePicker";
import LocationPicker from "./LocationPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EFFECT_OPERATIONS, FREQUENCY_TYPES } from "../../lib/constants";
import Tooltip from "../Tooltip";
import { useEffect, useState } from "react";

const Input = (props: {
  type: "amount" | "text" | "number" | "date" | "location" | "select" | "frequency",
  name: string,
  label: string,
  value: string | number,
  min?: number
  tooltip?: string | React.ReactNode
  placeholder?: string
  readOnly?: boolean,
  handleChange: (any, string) => void,
  options?: { name: string, value: string | number }[]

}) => {

  const [value, setValue] = useState<number | string | null>(null)

  useEffect(() => {
    setValue(props?.value)
  }, [props.value])

  const checkMin = {
    year: (_value, name) => {
      if (!props.min) return props.handleChange(_value, name)
      // if (!_value) return
      setValue(_value)
      if (_value < props.min) return
      props.handleChange(_value, name)
    }
  }

  const formatAmount = (amount) => {
    if (!amount) return ''
    return new Intl.NumberFormat(
      'en',
      {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(amount)
  }

  const parseAmount = (amount: string) => {
    const _amount = amount.trim().replaceAll(',', "").replace('$', '')
    if (!_amount) return 0
    const parsed = parseInt(_amount)
    if (isNaN(parsed)) return 0
    return parsed
  }

  const inputTypes = {
    operation: () => (
      <ButtonGroup className={[styles.buttonGroup, "w-100"].join(" ")}>
        <Button
          variant={"light"}
          className={props.value === EFFECT_OPERATIONS.increase ? "bg-white" : "bg-light text-gray-40"}
          disabled={props.readOnly}
          onClick={() => props?.handleChange(EFFECT_OPERATIONS.increase, "operation")}
        >
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <FontAwesomeIcon icon={[props.value === EFFECT_OPERATIONS.increase ? "fas" : "far", "up"]} />
            <span className="caption">Increase</span>
          </div>
        </Button>
        <Button
          variant={"light"}
          className={props.value === EFFECT_OPERATIONS.decrease ? "bg-white" : "bg-light text-gray-40"}
          disabled={props.readOnly}
          onClick={() => props?.handleChange(EFFECT_OPERATIONS.decrease, "operation")}
        >
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <FontAwesomeIcon icon={[props.value === EFFECT_OPERATIONS.decrease ? "fas" : "far", "down"]} />
            <span className="caption">Decrease</span>
          </div>
        </Button>
      </ButtonGroup>
    ),
    frequency: () => (
      <ButtonGroup className={[styles.buttonGroup, "w-100"].join(" ")}>
        <Button
          variant={"light"}
          className={props.value === FREQUENCY_TYPES.MONTHLY ? "bg-white fw-bold" : "bg-light text-gray-40"}
          disabled={props.readOnly}
          onClick={() => props?.handleChange(FREQUENCY_TYPES.MONTHLY, "frequency")}
        >
          <span className="caption">Monthly</span>
        </Button>
        <Button
          variant={"light"}
          className={(!props.value || props.value === FREQUENCY_TYPES.YEARLY) ? "bg-white fw-bold" : "bg-light text-gray-40"}
          disabled={props.readOnly}
          onClick={() => props?.handleChange(FREQUENCY_TYPES.YEARLY, "frequency")}
        >
          <span className="caption">Yearly</span>
        </Button>
      </ButtonGroup>
    ),
    text: () => (
      <FloatingLabel className={styles.label} label={props?.label}>
        <Form.Control
          className={styles.input}
          type='text'
          name={props?.name}
          value={props?.value || ""}
          placeholder={props.placeholder || ""}
          onChange={(e) => props?.handleChange(e.target.value, e.target.name)}
          disabled={props.readOnly}
        />
      </FloatingLabel>
    ),
    // TODO 
    percentage: () => (
      <FloatingLabel className={styles.label} label={props?.label}>
        <Form.Control
          className={styles.input}
          type='text'
          name={props?.name}
          placeholder={props.placeholder || ""}
          value={Math.round(parseFloat((props?.value || "") + "" || "0") * 100) || ""}
          onChange={(e) => props?.handleChange(Math.min(parseFloat(e.target.value || "0") / 100, 1), e.target.name)}
          disabled={props.readOnly}
        />
      </FloatingLabel>
    ),
    number: () => (
      <div>
        <FloatingLabel className={styles.label} label={props?.label}>
          <Form.Control
            className={[styles.input, ((props?.min && value && value < props.min) ? "is-invalid" : '')].join(' ')}
            type='number'
            name={props?.name}
            value={value || ""}
            placeholder={props.placeholder || ""}
            onChange={e => checkMin.year(parseInt(e.target.value || "0"), e.target.name)}
            disabled={props.readOnly}
          />
        </FloatingLabel>
        {props?.min && value && value < props.min ?
          <div className="caption text-danger pt-1">Value cannot be lower than {props.min}</div> : <></>
        }
      </div>
    ),
    term: () => (
      <FloatingLabel className={styles.label} label={props?.label}>
        <Form.Control
          className={styles.input}
          type='number'
          name={props?.name}
          value={props?.value || ""}
          placeholder={props.placeholder || "∞"}
          onChange={(e) => props?.handleChange(parseInt(e.target.value || "0"), e.target.name)}
          disabled={props.readOnly}
        />
      </FloatingLabel>
    ),
    amount: () => (
      <FloatingLabel label={props?.label} className={styles.label}>
        <Form.Control
          autoComplete="off"
          className={styles.input}
          type='text'
          name={props?.name}
          placeholder={props.placeholder || ""}
          value={props?.value ? formatAmount(props?.value) : ''}
          onChange={(e) => props?.handleChange(parseAmount(e.target.value), e.target.name)}
          disabled={props.readOnly}
        />
      </FloatingLabel>
    ),
    select: () => (
      <FloatingLabel label={props?.label} className={styles.label}>
        <Form.Select
          className={styles.input}
          name={props?.name}
          placeholder={props.placeholder || props?.label}
          value={props?.value || ""}
          onChange={e => props?.handleChange(e.target.value, e.target.name)}
          disabled={props.readOnly}
        >
          {props?.options?.map((option, index) => (
            <option key={option.value + '_' + index} value={option.value}>{option.name}</option>
          ))}
        </Form.Select>
      </FloatingLabel>
    ),
    date: () => (
      <DatePicker value={props?.value || ''} name={props?.name} readOnly={props.readOnly} handleChange={props?.handleChange} />
    ),
    location: () => (
      <LocationPicker value={props?.value || ''} name={props?.name} readOnly={props.readOnly} handleChange={props?.handleChange} />
    )
  }

  return (
    <Form.Group className='mb-2 position-relative'>
      {inputTypes[props.type]()}
      {props?.tooltip && <div className={[styles.tooltipWrapper, "d-flex align-items-center pe-3"].join(" ")}>
        <div className={styles.tooltip}>
          <Tooltip>
            {props.tooltip}
          </Tooltip>
        </div>
      </div>
      }
    </Form.Group>
  )
}

export default Input