import { IRALimitsConfig } from "../types"

const IRALimits: IRALimitsConfig = {
  "2021": [{
    minAge: 0,
    maxAge: 50,
    limit: 6000
  }, {
    minAge: 50,
    limit: 7000
  }]
}

export default IRALimits