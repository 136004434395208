import goalCategories from "../../lib/config/goalCategories"
import { Goal } from "../../lib/modules/timeline/types"
import Input from "../Input"
import { BABY_COST, GOAL_CATEGORIES } from "../../lib/constants"
import { Expense, Household, Income, Member } from "../../lib/types"
import Pill from "../Pill"
import { formatAmount } from "../../lib/utils/number"
import { incomeSum } from "../../lib/utils/income"
import styles from "./GoalEditor.module.css"
import Info from "../Info"
import { expenseSum } from "../../lib/utils/expense";

const GoalEditor = (props: {
  goal?: Goal
  readOnly?: boolean
  context: {
    members: Member[]
    household: Household,
    expenses: Expense[],
    incomes: Income[],
  }
  onChange: (any) => any
}) => {
  const update = (updates) => {
    const nextGoal = Object.assign({ ...(props.goal || {}) }, updates)
    props.onChange(nextGoal)
  }

  const monthlyExpenses = expenseSum(props?.context?.expenses) / 12

  const suggestions = {
    [GOAL_CATEGORIES.EDUCATION]: {
      name: () => {
        const children = props.context.members.filter(member => member.yearOfBirth && (member.yearOfBirth + 18 > props.context.household.year))
        return (<>
          {children.map(child => (
            <Pill key={child.id} content={child.firstName + "'s university"} className="clickable mb-1" onClick={() => update({
              name: child.firstName + "'s university",
              year: child?.yearOfBirth && child.yearOfBirth + 18
            })} />
          ))}
        </>)
      },
      amount: () => {
        const amounts = [22000 * 4, 39000 * 4, 51000 * 4, 61000 * 4,]
        return (<>
          {amounts.map(amount => (
            <Pill key={amount} content={formatAmount(amount, { short: true })} className="clickable" onClick={() => update({ amount })} />
          ))}
        </>)
      }
    },
    [GOAL_CATEGORIES.EMERGENCY_FUND]: {
      amount: () => {
        const amounts = [
          {
            title: "for 3 months",
            amount: Math.ceil((3 * monthlyExpenses) / 1000) * 1000
          },
          {
            title: "for 6 months",
            amount: Math.ceil((6 * monthlyExpenses) / 1000) * 1000
          }
        ]
        return (<>
          {amounts.map(amount => (
            <Pill key={amount.amount} content={amount.title} className="clickable" onClick={() => update({ amount: amount.amount, name: "Emergency fund" })} />
          ))}
        </>)
      }
    },
    [GOAL_CATEGORIES.HOUSE]: {
      amount: () => {
        const grossIncome = incomeSum(props.context.incomes)
        const amount = (grossIncome * 4)
        return (<>{amount && <Pill content={formatAmount(amount)} className="clickable" onClick={() => update({ amount })} />}</>)
      },
      downPaymentPercentage: () => {
        return <Pill content={"20%"} className="clickable" onClick={() => update({ downPaymentPercentage: 0.2 })} />
      },
      closingCostsPercentage: () => {
        return <Pill content={"5%"} className="clickable" onClick={() => update({ closingCostsPercentage: 0.05 })} />
      },
    },
    [GOAL_CATEGORIES.CAR]: {
      downPaymentPercentage: () => {
        return <Pill content={"20%"} className="clickable" onClick={() => update({ downPaymentPercentage: 0.2 })} />
      },
    },
    [GOAL_CATEGORIES.NEW_FAMILY_MEMBER]: {
      amount: () => {
        return <Pill content={formatAmount(BABY_COST)} className="clickable" onClick={() => update({ amount: BABY_COST })} />
      },
    },
    [GOAL_CATEGORIES.OTHER]: {
      name: () => {
        return <>
          {["Taking a sabbatical", "Family celebration", "Major vacation", "Charity", "New pet"].map(line => (
            <Pill key={line} content={line} className="clickable" onClick={() => update({ name: line })} />
          ))}
        </>
      },
    },
  }

  return <div>
    {props.goal?.category && goalCategories[props.goal?.category] && goalCategories[props.goal?.category].fields.map(field => (
      <div key={field.name}>
        <Input
          type={field.type}
          name={field.name}
          value={props.goal?.[field.name]}
          min={props.context.household.year}
          label={field.label}
          placeholder={field.placeholder}
          handleChange={(val, field) => update({ [field]: val })}
          readOnly={props.readOnly}
          tooltip={field.tooltip && <Info category={field.tooltip} />}
        />
        {props.goal?.category && !props.goal[field.name] && suggestions[props.goal?.category]?.[field.name] && (
          <div className={[styles.suggestions, "pb-3 d-flex gap-1 flex-wrap mb-1"].join(" ")}>{suggestions[props.goal?.category]?.[field.name]?.()}</div>
        )}
      </div>
    ))}
  </div>

}

export default GoalEditor