import { Container } from "react-bootstrap"
import DashboardCard from "../../components/DashboardCard"
import DashboardRow from "../../components/DashboardRow"
import FlowChart from "../../components/FlowChart"
import MemberList from "../../components/MemberList"
import TimelineChart from "../../components/TimelineChart"
import useTax from "../../hooks/useTax"
import useTimeline from "../../hooks/useTimeline"
import { expenseSum } from "../../lib/utils/expense"
import { incomeSum } from "../../lib/utils/income"
import { useAppSelector } from "../../store"
import useBenchmarks from "../../hooks/useBenchmarks";
import Benchmark from "../../components/Benchmark";
import GoalCard from "../../components/GoalCard";
import { useMemo } from "react";
import { formatAmount } from "../../lib/utils/number";
import Pill from "../../components/Pill";
import Stacks from "../../components/Stacks";
import { convertLoansToEffects } from "../../lib/modules/timeline/utils"
import useNetWorth from "../../hooks/useNetWorth";
import { emptyStates } from "../../lib/config/copy";
import CTACard from "../../components/CTACard";
import EmptyText from "../../components/EmptyText";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { INFLATION_RATE } from "../../lib/constants"
import styles from './Dashboard.module.css'
import FluidWrapper from "../../components/FluidWrapper";
import EmptyStateImage from "../../components/EmptyStateImage";
import useEmptyState from "../../hooks/useEmptyState";

const Dashboard = () => {
  const { data, household, members, expenses, incomes, assets, effects, loans, goals, relocations, debts } = useAppSelector(state => ({
    data: state.data,
    household: state.household,
    members: state.members,
    expenses: state.expenses,
    incomes: state.incomes,
    assets: state.assets,
    debts: state.debts,
    effects: state.effects,
    loans: state.loans,
    goals: state.goals,
    relocations: state.relocations,
  }))

  const tax = useTax()
  const timeline = useTimeline({ household, members, expenses, incomes, assets, effects, loans, goals, relocations })
  const benchmarks = useBenchmarks()
  const netWorthData = useNetWorth({ assets, debts })
  const emptyState = useEmptyState({ members, household, expenses, assets, goals, debts })
  const dispatch = useDispatch()

  const [savings, savingsPercentage] = useMemo(() => {
    const income = incomeSum(incomes)
    const _expenses = expenseSum([...expenses]) + (tax?.total || 0)
    return [income - _expenses, Math.floor((income - _expenses) / (income / 100))]
  }, [members, household, expenses, tax])

  return (
    <Container fluid className="pt-4">
      <DashboardRow title="My Finances" description="Where you are today: The big picture view of your current finances with benchmarking to help you understand your strengths and opportunities.">
        <DashboardCard
          title="My household"
          description={members.length + " member" + (members.length > 1 ? "s" : "")}
          footerCTA="edit"
          footerCTALink="/household"
        >
          {members.length > 0 && (<MemberList members={members} />)}
          {members.length <= 1 && (
            <div className={members.length === 0 ? '' : 'mt-3'} style={{ width: "250px" }}>
              <CTACard
                cta={emptyStates.members.cta}
                action={() => dispatch(push('/household'))}
              >
                {emptyStates.members.body}
              </CTACard>
            </div>
          )}
        </DashboardCard>
        <DashboardCard
          title="Annual savings potential"
          description={
            emptyState?.incomes || emptyState?.expenses ? (
              <EmptyText textLength={16} />
            ) : (
              <div className='d-flex align-items-center'>
                <div className='me-2'>{formatAmount(savings)}</div>
                {benchmarks?.saving &&
                  <Pill
                    variant='savings'
                    negative={!benchmarks?.saving?.positive}
                    content={savingsPercentage.toString() + '% of your income'}
                    icon={benchmarks?.saving?.positive ? "thumbs-up" : "thumbs-down"}
                  />
                }
              </div>
            )
          }
          footerCTA="open"
          footerCTALink="/finances"
          disabled={emptyState?.incomes && emptyState?.expenses}
        >
          {
            emptyState?.incomes || emptyState?.expenses ? (
              <EmptyStateImage image='/savingsEmptystate.png'>
                <CTACard
                  title={emptyStates.savings.title}
                  icons={{ prefix: 'far', items: ['rocket'] }}
                  cta={emptyStates.savings.cta}
                  action={() => dispatch(push(emptyState.incomes ? '/household#income' : '/household#expenses'))}
                  primary={emptyState?.incomes && emptyState.expenses}
                >
                  {emptyStates.savings.body}
                </CTACard>
              </EmptyStateImage>
            ) : (
              <div className={styles.flowChartContainer}>
                <FluidWrapper>
                  <FlowChart inflow={incomeSum(incomes)} outflow={expenseSum(expenses) + (tax?.total || 0)} />
                </FluidWrapper>
                <div className='d-flex flex-column gap-2 py-3 px-1'>
                  <Benchmark feedback={benchmarks?.income} />
                  {/*<Benchmark feedback={benchmarks?.expense} />*/}
                  <Benchmark feedback={benchmarks?.saving} />
                </div>
              </div>
            )
          }
        </DashboardCard>
        <DashboardCard
          title="Your Net Worth"
          description={
            emptyState?.assets && emptyState?.debts ? (
              <EmptyText textLength={16} />
            ) : (
              <>
                {formatAmount(netWorthData?.netWorth || 0)}
              </>
            )
          }
          footerCTA="open"
          footerCTALink="/finances#net-worth"
          disabled={emptyState?.assets && emptyState?.debts}
        >
          {
            (emptyState?.assets && emptyState?.debts) ? (
              <EmptyStateImage image='/networthEmptystate.png'>
                <CTACard
                  title={emptyStates.netWorth.title}
                  cta={emptyStates.netWorth.cta}
                  icons={{ prefix: 'far', items: ['rocket'] }}
                  action={() => dispatch(push('/household#assets'))}
                  primary={(!emptyState?.incomes && !emptyState?.expenses) && (emptyState?.assets && emptyState?.debts)}
                >
                  {emptyStates.netWorth.body}
                </CTACard>
              </EmptyStateImage>
            ) : (
              <div className='p-3 h-100'>
                <Stacks cols={netWorthData?.stacks} />
              </div>
            )
          }
        </DashboardCard>
        {/*<DashboardCard title="Did you know?" description="Tips and tricks">*/}
        {/*  <div style={{ width: "300px", height: "300px", background: "rgba(0,0,0,0.05)" }}></div>*/}
        {/*</DashboardCard>*/}
      </DashboardRow>
      <DashboardRow title="My Goals" description="Where you're going: Quickly figure out when you'll have the option not to work, or hit financial freedom, and identify what else money can make possible (e.g., college, kids, homes) along the way">
        <DashboardCard
          title="Your assets over time"
          description="My timeline"
          footerCTA="open"
          footerCTALink="/timeline"
          disabled={emptyState?.incomes || emptyState?.expenses}
        >
          <div className={styles.timelineContainer}>
            {
              emptyState?.incomes || emptyState?.expenses || emptyState?.goals ? (
                <div className={styles.timelineEmptyState}>
                  <CTACard
                    title={emptyStates.timeline.title}
                    icons={{ prefix: 'far', items: ['rocket'] }}
                    cta={emptyState?.incomes || emptyState?.expenses ? emptyStates.timeline.cta : emptyStates.timelineGoals.cta}
                    action={() => dispatch(push((emptyState?.incomes || emptyState?.expenses) ? (emptyState.incomes ? '/household#income' : '/household#expenses') : '/timeline'))}
                    primary={(!emptyState?.incomes && !emptyState?.expenses) && (!emptyState?.assets || !emptyState?.debts) && emptyState?.goals}
                  >
                    {emptyStates.timeline.body}
                  </CTACard>
                  <img src='/timelineEmptystate.png' className='img-fluid mh-100' alt='timeline' />
                </div>
              ) : (
                <TimelineChart
                  options={{ simple: true }}
                  timeline={timeline}
                  members={members}
                  goals={goals}
                  relocations={relocations.filter(relocation => !relocation.goalId)}
                  effects={[...convertLoansToEffects(loans, INFLATION_RATE), ...effects]}
                  loading={!data.ready}
                  onClick={(id) => dispatch(push(`/timeline${id ? '/' + id : ''}`))}
                />
              )
            }

          </div>
        </DashboardCard>
        {emptyState?.goals && (
          <DashboardCard placeholder={true}>
            <GoalCard category={'HOUSE'} />
          </DashboardCard>
        )}
        {goals.map((goal: any, index: number) => (
          <DashboardCard
            key={index}
            title={
              <>
                <div>{'By ' + goal?.year}</div>
                <Pill
                  content={timeline?.goals?.[goal.id]?.reached ? 'On track' : 'Needs attention'}
                  negative={!timeline?.goals?.[goal.id]?.reached}
                  variant='goals'
                />
              </>
            }
            description={goal?.name || '-'}
            footerCTA="open"
            footerCTALink={`/timeline/${goal.id}`}
          >
            <GoalCard amount={goal.amount} category={goal.category} />
          </DashboardCard>
        ))}
      </DashboardRow>
      {/*<DashboardRow title="Investment" description="Lorem ipsum">*/}
      {/*  <DashboardCard title="Title" description="Description" footerCTA="open" footerCTALink="/investment">*/}
      {/*    <Comparison*/}
      {/*      subjects={["cash", "investment"]}*/}
      {/*      current={[20, 80]}*/}
      {/*      recommended={[30, 70]}*/}
      {/*    />*/}
      {/*  </DashboardCard>*/}
      {/*  <DashboardCard title="Title" description="Description" footerCTA="open" footerCTALink="/investment">*/}
      {/*    <Comparison*/}
      {/*      subjects={["stock", "bond"]}*/}
      {/*      current={[50, 50]}*/}
      {/*      recommended={[60, 40]}*/}
      {/*    />*/}
      {/*  </DashboardCard>*/}
      {/*</DashboardRow>*/}
    </Container>
  )
}

export default Dashboard