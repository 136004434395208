import { Goal } from "../../lib/modules/timeline/types"
import { ActionCreator } from "../../lib/types"

export const SET_GOALS = "GOALS/setGoals"
export const ADD_GOAL = "GOALS/addGoal"
export const UPDATE_GOAL = "GOALS/updateGoal"
export const REMOVE_GOAL = "GOALS/removeGoal"


export const setGoals: ActionCreator<{
  [K in keyof Goal]: Goal[K]
}[]> = (payload) => {
  return {
    type: SET_GOALS,
    payload
  }
}
export const addGoal: ActionCreator<{
  [K in keyof Goal]: Goal[K]
}> = (payload) => {
  return {
    type: ADD_GOAL,
    payload
  }
}

export const updateGoal: ActionCreator<{
  [K in keyof Goal]: Goal[K]
}> = (payload) => {
  return {
    type: UPDATE_GOAL,
    payload
  }
}

export const removeGoal: ActionCreator<{
  id: string
}> = (payload) => {
  return {
    type: REMOVE_GOAL,
    payload
  }
}