import { ASSET_CATEGORIES } from "../constants";

const assetCategories: {} = {

  [ASSET_CATEGORIES.HOUSE]: {
    title: 'House',
    icon: 'house'
  },
  [ASSET_CATEGORIES.OTHER]: {
    title: 'Other',
    icon: 'treasure-chest'
  },
  [ASSET_CATEGORIES.PERSONAL_INVESTMENT]: {
    title: 'Personal investment',
    icon: 'arrow-trend-up'
  },
  [ASSET_CATEGORIES.TRADITIONAL_401K]: {
    title: '401k, 403b, 457',
    icon: 'arrow-trend-up'
  },
  [ASSET_CATEGORIES.ROTH_401K]: {
    title: 'Roth 401k, 403b, 457',
    icon: 'arrow-trend-up'
  },
  [ASSET_CATEGORIES.TRADITIONAL_IRA]: {
    title: 'IRA',
    icon: 'arrow-trend-up'
  },
  [ASSET_CATEGORIES.ROTH_IRA]: {
    title: 'Roth IRA',
    icon: 'arrow-trend-up'
  },
  [ASSET_CATEGORIES._529]: {
    title: '529',
    icon: 'arrow-trend-up'
  },
  [ASSET_CATEGORIES.HSA]: {
    title: 'HSA',
    icon: 'arrow-trend-up'
  }
}

export default assetCategories