import { useEffect, useState } from "react"

const Loading = props => {
  const [dots, setDots] = useState(0)
  useEffect(() => {
    const counter = setInterval(() => {
      setDots(dots + 1 > 3 ? 0 : dots + 1)
    }, 200)
    return () => clearInterval(counter)
  })
  return <div className="vh-100 d-flex align-items-center justify-content-center m-auto bg bg-base" style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, zIndex: 1000000000 }}>
    <div>
      Loading{Array(3).fill(" ").map((item, index) => <span key={"dot" + index} style={{ color: index < dots ? "black" : "transparent" }}>.</span>)}
    </div>
  </div>
}

export default Loading