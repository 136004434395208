import defaultAPRs from "../../lib/config/defaultAPRs"
import { FREQUENCY_TYPES, INFO_CATEGORIES } from "../../lib/constants"
import { Loan } from "../../lib/modules/timeline/types"
import { yearlyPaymentOfLoan } from "../../lib/modules/timeline/utils"
import { formatAmount } from "../../lib/utils/number"
import Info from "../Info"
import Input from "../Input"

const LoanEditor = (props: {
  loan?: Loan
  readOnly?: boolean
  hiddenFields?: string[]
  onChange: (any) => any
}) => {
  const update = (updates) => {
    const nextLoan = Object.assign({ ...(props.loan || {}) }, updates)
    props.onChange(nextLoan)
  }

  return <div>
    <div>
      <Input
        type="select"
        name="activeYears"
        value={props.loan?.activeYears + ""}
        label="Term"
        readOnly={props.readOnly}
        handleChange={(val) => update({ "activeYears": parseInt(val), annualPercentageRate: defaultAPRs[val] })}
        options={[
          { name: "5 years", value: "5" },
          { name: "10 years", value: "10" },
          { name: "20 years", value: "20" },
          { name: "30 years", value: "30" },
        ]}
      />
    </div>
    <div>
      <Input
        type="text"
        name=""
        value={
          props.loan ?
            formatAmount(yearlyPaymentOfLoan(props.loan), { short: true, frequency: FREQUENCY_TYPES.YEARLY })
            : "0"}
        tooltip={<Info category={INFO_CATEGORIES.AVERAGE_APR} />}
        label="Added expense"
        readOnly={true}
        handleChange={console.log}
      />
    </div>
  </div>
}

export default LoanEditor