import styles from './CTACard.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { IconPrefix } from "@fortawesome/pro-regular-svg-icons";

const CTACard = (
  props:
    {
      title?: string,
      icons?: { prefix: IconPrefix, items: IconName[] },
      children: React.ReactNode,
      cta: string,
      primary?: boolean,
      action: any
    }
) => {

  return (
    <div className={styles.container}>
      <div>
        {props.title && (
          <div className='d-flex align-items-center mb-3 text-black'>
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={[(props.icons?.prefix || 'far'), (props.icons?.items?.[0] || 'lightbulb')]} className='mx-2' />
            </div>
            <div className='subtitle-2'>{props.title}</div>
          </div>
        )}
        {(!props.title && props.icons) && (
          <div className='d-flex align-items-center justify-content-center gap-3 mt-1 mb-3'>
            {props.icons && props.icons.items.map(icon => (
              <FontAwesomeIcon icon={[(props?.icons?.prefix || 'far'), icon]} className='text-gray-60' key={icon} />
            ))}
          </div>
        )}
        <div className='caption'>
          {props.children}
        </div>
      </div>
      <div className='w-100 text-center'>
        {props.primary ? (
          <Button onClick={props.action} className={`${styles.button} subtitle-2`} size='sm'>{props.cta}</Button>
        ) : (
          <div className={`${styles.cta} subtitle-2 clickable`} onClick={props.action}>
            {props.cta}
          </div>
        )}
      </div>
    </div>
  )
}

export default CTACard