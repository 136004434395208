import { v4 } from "uuid";
import Card from "../../components/Household/Card"
import PageTitle from "../../components/Household/PageTitle"
import CardTitle from "../../components/Household/CardTitle"
import Cards from "../../components/Household/Cards"
import Pill from "../../components/Pill"
import Property from "../../components/Household/Property"
import { FREQUENCY_TYPES, INCOME_TYPES, MEMBER_TYPES } from "../../lib/constants"
import { Income, Member } from "../../lib/types"
import { useDispatch } from "react-redux"
import { incomeSum } from "../../lib/utils/income"
import CardFooter from "../../components/Household/CardFooter"
import PropertyList from "../../components/Household/PropertyList";
import { formatAmount } from "../../lib/utils/number";
import memberIcons from "../../lib/config/memberIcons";
import { memberAgeCategory } from "../../lib/utils/member";
import { useMemo, useState } from "react";
import { addIncome, removeIncome, updateIncome } from "../../store/actions/incomes";
import { confirmMessages } from "../../lib/config/copy";

const Incomes = ({ members, incomes }: { members: Member[], incomes: Income[] }) => {
  const dispatch = useDispatch()

  const [lastAdded, setLastAdded] = useState<string | null>(null)

  const requiredFields = ['amount', 'name']

  const addNewIncome = (member: Member | null, type: keyof typeof INCOME_TYPES) => {
    // TODO
    const newIncome: Income = {
      id: v4(),
      memberId: member?.id || null,
      name: "",
      amount: 0,
      type
    }

    dispatch(addIncome(newIncome))
    setLastAdded(newIncome.id)
  }

  const handleIncomeChange = (newIncome) => {
    dispatch(updateIncome(newIncome))
  }

  const handleIncomeRemove = (income) => {
    if (requiredFields.every(field => !income[field])) return dispatch(removeIncome(income))
    if (!window.confirm(confirmMessages.incomeRemove)) return
    dispatch(removeIncome(income))
  }

  const sortedIncomes = useMemo(() => {
    return [...incomes].sort((a, b) => {
      if (a.type === INCOME_TYPES.ANNUAL_BASE_SALARY) return -1
      if (b.type === INCOME_TYPES.ANNUAL_BASE_SALARY && a.type === INCOME_TYPES.ANNUAL_BONUS) return 1
      if (a.type === INCOME_TYPES.ANNUAL_BONUS) return -1
      return 0
    })
  }, [incomes])

  return (
    <div>
      <PageTitle>
        <div className="headline-5 d-flex align-items-center">
          <div className='me-2'>Incomes</div>
          <Pill variant="incomes" content={formatAmount(incomeSum(incomes), { frequency: FREQUENCY_TYPES.YEARLY })} />
        </div>
      </PageTitle>
      <Cards>

        {members.filter(member => [MEMBER_TYPES.PRIMARY, MEMBER_TYPES.PARTNER].includes(member.type as any)).map(member => (
          <Card key={member.id}>
            <CardTitle
              color={member.color}
              initial={member.firstName?.[0]}
              variant='member'
              secondaryIcon={memberIcons[memberAgeCategory(new Date().getFullYear(), member.yearOfBirth)]}
              type="incomes"
              title={member.firstName || "-"}
              sum={formatAmount(incomeSum(incomes.filter(income => income.memberId === member.id)), { frequency: FREQUENCY_TYPES.YEARLY, short: true })}
            />
            <PropertyList>
              {sortedIncomes.filter(income => income.memberId === member.id).map(income => (
                <Property
                  type={income.type}
                  icon="money-bills"
                  id={income.id}
                  key={income.id}
                  data={income}
                  context={{ firstName: member.firstName }}
                  open={lastAdded === income.id}
                  onChange={(value) => handleIncomeChange(value)}
                  {...(income.type === INCOME_TYPES.OTHER_ANNUAL_INCOME ?
                    { onRemove: () => handleIncomeRemove(income) } :
                    {}
                  )}
                />
              ))}
            </PropertyList>
            <CardFooter onAdd={() => addNewIncome(member, INCOME_TYPES.OTHER_ANNUAL_INCOME)} />
          </Card>
        ))}
        <Card>
          <CardTitle
            variant="household"
            title="Other incomes"
            sum={formatAmount(incomeSum(incomes.filter(income => !income.memberId)), { frequency: FREQUENCY_TYPES.YEARLY, short: true })}
            type="incomes"
          />
          <PropertyList>
            {incomes.filter(income => !income.memberId).map(income => (
              <Property
                type={INCOME_TYPES.OTHER_ANNUAL_INCOME}
                icon="money-bills"
                id={income.id}
                key={income.id}
                data={income}
                open={lastAdded === income.id}
                onChange={(value) => handleIncomeChange(value)}
                onRemove={() => handleIncomeRemove(income)}
              />
            ))}
          </PropertyList>
          <CardFooter onAdd={() => addNewIncome(null, INCOME_TYPES.OTHER_ANNUAL_INCOME)} />
        </Card>
      </Cards>
    </div>
  )
}

export default Incomes