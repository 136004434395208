import styles from "./SidebarPanel.module.css"

const SidebarPanel = (props: {
  active?: boolean
  children: React.ReactNode
}) => {
  return <div className={[styles.container, (props.active) ? styles.active : ""].join(" ")}>
    {props.children}
  </div>
}

export default SidebarPanel