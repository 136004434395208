import { traditionalIRATaperingConfig } from "../types"

const traditionalIRATaperingData: traditionalIRATaperingConfig = {
  "2021": {
    single: {
      covered: [66000, 76000],
      spouseCovered: null
    },
    married: {
      covered: [105000, 125000],
      spouseCovered: [198000, 208000]
    }
  },
  "2022": {
    single: {
      covered: [68000, 78000],
      spouseCovered: null
    },
    married: {
      covered: [109000, 129000],
      spouseCovered: [204000, 214000]
    }
  }
}

export default traditionalIRATaperingData