import { all, takeLatest, takeLeading } from 'redux-saga/effects'
import { AUTH_CALLBACK, AUTH_GET_TOKEN_FAIL, AUTH_GET_TOKEN_START, AUTH_LOGIN_START, AUTH_LOGOUT_START } from "../store/actions/auth";
import { DATA_CHANGED, GET_DATA_REQUEST, RESET_DATA_REQUEST, UPDATE_DATA_REQUEST } from '../store/actions/data';
import * as auth from '../sagas/auth'
import * as data from '../sagas/data'
import * as members from '../sagas/members'
import * as incomes from '../sagas/incomes'
import * as admin from '../sagas/admin'
import { ADD_MEMBER, CREATE_MEMBER, REMOVE_MEMBER } from '../store/actions/members';
import { GET_ADMIN_HOUSEHOLD_ID_REQUEST, SET_ADMIN_HOUSEHOLD_ID_REQUEST, DELETE_ADMIN_HOUSEHOLD_ID_REQUEST } from '../store/actions/admin';

export default function* root() {
  yield all([
    takeLeading(AUTH_CALLBACK, auth.callback),
    takeLeading(AUTH_LOGIN_START, auth.login),
    takeLeading(AUTH_LOGOUT_START, auth.logout),
    takeLeading(AUTH_GET_TOKEN_START, auth.getToken),
    takeLeading(AUTH_GET_TOKEN_FAIL, auth.login),

    takeLeading(GET_ADMIN_HOUSEHOLD_ID_REQUEST, admin.getHouseholdId),
    takeLeading(SET_ADMIN_HOUSEHOLD_ID_REQUEST, admin.setHouseholdId),
    takeLeading(DELETE_ADMIN_HOUSEHOLD_ID_REQUEST, admin.deleteHouseholdId),

    takeLeading(GET_DATA_REQUEST, data.get),
    takeLatest(UPDATE_DATA_REQUEST, data.sync),
    takeLatest(DATA_CHANGED, data.changed),
    takeLatest(RESET_DATA_REQUEST, data.reset),

    takeLeading(CREATE_MEMBER, members.createMember),
    takeLeading(ADD_MEMBER, incomes.createDefaultIncomes),
    takeLeading(REMOVE_MEMBER, incomes.removeMemberIncomes)
  ])
}