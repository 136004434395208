import { Relocation } from "../../lib/modules/timeline/types"
import { UNLOAD_DATA } from "../actions/data"
import { SET_RELOCATIONS, ADD_RELOCATION, UPDATE_RELOCATION, REMOVE_RELOCATION } from "../actions/relocations"


const initial: Relocation[] = []

const relocations = (state = initial, action) => {
  switch (action.type) {
    case SET_RELOCATIONS:
      return action.payload || []

    case ADD_RELOCATION:
      const newState = state.filter(relocation => relocation.id !== action.payload.id)
      newState.push(action.payload)
      return newState

    case UPDATE_RELOCATION:
      return state.map(relocation => {
        if (relocation.id === action.payload.id) relocation = Object.assign({}, relocation, action.payload)
        return relocation
      })

    case REMOVE_RELOCATION:
      return state.filter(relocation => relocation.id !== action.payload.id)

    case UNLOAD_DATA:
      return initial

    default:
      return state
  }
}

export default relocations