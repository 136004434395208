import { useEffect, useState } from "react"
import { MEMBER_TYPES } from "../lib/constants"
import timeline from "../lib/modules/timeline"
import { Effect, TimelineResult, Goal, Loan, Relocation } from "../lib/modules/timeline/types"
import { Asset, Expense, Household, Income, Member } from "../lib/types"

const useTimeline = (props: {
  household: Household,
  members: Member[],
  incomes: Income[],
  expenses: Expense[],
  assets: Asset[],
  goals: Goal[],
  effects: Effect[],
  loans: Loan[],
  relocations: Relocation[],
}): TimelineResult => {
  const [result, setResult] = useState<any>(null)
  const { household, members, expenses, incomes, assets, goals, effects, loans, relocations } = props

  useEffect(() => {
    if (!household) return
    const reducedIncomes = incomes.reduce((result, income) => {
      if (!income.amount) return result
      result[income.type] = [...(result[income.type] || []), income.amount]
      return result
    }, {})
    const relationshipStatus = members.find(member => member.type === MEMBER_TYPES.PARTNER) ? "married" : "single"

    const nextResult = timeline({
      year: household.year,
      relationshipStatus,
      location: {
        state: household.state,
        city: household.city
      },
      incomes: reducedIncomes,
      expenses,
      relocations,
      effects,
      loans,
      assets,
      goals
    })
    console.log(nextResult)
    setResult(nextResult)
  }, [household, members, expenses, assets, effects, loans, goals, relocations])
  return result || {}
}

export default useTimeline