import { User } from "../../lib/types"
import { AUTH_LOGIN_START, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAIL, AUTH_GET_TOKEN_FAIL, AUTH_GET_TOKEN_START, AUTH_GET_TOKEN_SUCCESS, AUTH_LOGOUT_FAIL, AUTH_LOGOUT_START, AUTH_LOGOUT_SUCCESS } from "../actions/auth"

const initial = {
  token: null as string | null,
  user: null as User | null,
  loading: false as boolean,
  error: false as boolean,
}

const auth = (state = initial, action) => {
  switch (action.type) {
    case AUTH_LOGIN_START:
      return Object.assign({}, state, {
        loading: true,
        error: false
      })
    case AUTH_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        token: action.payload.token,
        user: action.payload.user,
        loading: false,
        error: false
      })

    case AUTH_LOGIN_FAIL:
      return Object.assign({}, state, {
        token: null,
        user: null,
        loading: false,
        error: true
      })

    case AUTH_GET_TOKEN_START:
      return Object.assign({}, state, {
        loading: true,
        error: false
      })
    case AUTH_GET_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        token: action.payload.token,
        user: action.payload.user,
        loading: false,
        error: false
      })

    case AUTH_GET_TOKEN_FAIL:
      return Object.assign({}, state, {
        token: null,
        user: null,
        loading: false,
        error: true
      })

    case AUTH_LOGOUT_START:
      return Object.assign({}, state, {
        loading: true,
        error: false
      })
    case AUTH_LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        token: null,
        user: null,
        loading: false,
        error: false
      })

    case AUTH_LOGOUT_FAIL:
      return Object.assign({}, state, {
        token: null,
        user: null,
        loading: false,
        error: true
      })

    default:
      return state
  }
}

export default auth