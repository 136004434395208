import { GET_ADMIN_HOUSEHOLD_ID_REQUEST, GET_ADMIN_HOUSEHOLD_ID_SUCCESS, GET_ADMIN_HOUSEHOLD_ID_FAILED, SET_ADMIN_HOUSEHOLD_ID_REQUEST, SET_ADMIN_HOUSEHOLD_ID_SUCCESS, SET_ADMIN_HOUSEHOLD_ID_FAILED, DELETE_ADMIN_HOUSEHOLD_ID_FAILED, DELETE_ADMIN_HOUSEHOLD_ID_REQUEST, DELETE_ADMIN_HOUSEHOLD_ID_SUCCESS } from "../actions/admin"

const initial: {
  householdId: string | null
  checked: boolean,
  loading: boolean
} = {
  householdId: null,
  checked: false,
  loading: false
}

const admin = (state = initial, action) => {
  switch (action.type) {
    case GET_ADMIN_HOUSEHOLD_ID_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        checked: false,
        householdId: null
      })
    case GET_ADMIN_HOUSEHOLD_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        checked: true,
        householdId: action.payload
      })
    case GET_ADMIN_HOUSEHOLD_ID_FAILED:
      return Object.assign({}, state, {
        loading: false,
        checked: true,
        householdId: null
      })
    case SET_ADMIN_HOUSEHOLD_ID_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        checked: true,
        householdId: null
      })
    case SET_ADMIN_HOUSEHOLD_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        checked: true,
        householdId: action.payload
      })
    case SET_ADMIN_HOUSEHOLD_ID_FAILED:
      return Object.assign({}, state, {
        loading: false,
        checked: true,
        householdId: null
      })
    case DELETE_ADMIN_HOUSEHOLD_ID_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        checked: true
      })
    case DELETE_ADMIN_HOUSEHOLD_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        checked: true,
        householdId: null
      })
    case DELETE_ADMIN_HOUSEHOLD_ID_FAILED:
      return Object.assign({}, state, {
        loading: false,
        checked: true
      })
    default:
      return state
  }
}

export default admin