import { ActionCreator } from "../../lib/types"

export const GET_DATA_REQUEST = "DATA/getDataRequest"
export const GET_DATA_SUCCESS = "DATA/getDataSuccess"
export const GET_DATA_FAILED = "DATA/getDataFailed"

export const UPDATE_DATA_REQUEST = "DATA/updateDataRequest"
export const UPDATE_DATA_SUCCESS = "DATA/updateDataSuccess"
export const UPDATE_DATA_FAILED = "DATA/updateDataFailed"

export const DATA_CHANGED = "DATA/changed"

export const RESET_DATA_REQUEST = 'DATA/resetDataRequest'
export const RESET_DATA_SUCCESS = 'DATA/resetDataSuccess'
export const RESET_DATA_FAILED = 'DATA/resetDataFailed'

export const UNLOAD_DATA = 'DATA/unload'

export const getDataRequest: ActionCreator<string> = (payload) => {
  return {
    type: GET_DATA_REQUEST,
    payload
  }
}

export const getDataSuccess: ActionCreator<any> = (payload) => {
  return {
    type: GET_DATA_SUCCESS,
    payload
  }
}

export const getDataFailed: ActionCreator = (payload) => {
  return {
    type: GET_DATA_FAILED,
    payload
  }
}

export const updateDataRequest: ActionCreator = (payload) => {
  return {
    type: UPDATE_DATA_REQUEST,
    payload
  }
}

export const updateDataSuccess: ActionCreator = (payload) => {
  return {
    type: UPDATE_DATA_SUCCESS,
    payload
  }
}

export const updateDataFailed: ActionCreator = (payload) => {
  return {
    type: UPDATE_DATA_FAILED,
    payload
  }
}

export const dataChanged: ActionCreator = (payload) => {
  return {
    type: DATA_CHANGED,
    payload
  }
}

export const resetDataRequest: ActionCreator = (payload) => {
  return {
    type: RESET_DATA_REQUEST,
    payload
  }
}

export const resetDataSuccess: ActionCreator = (payload) => {
  return {
    type: RESET_DATA_SUCCESS,
    payload
  }
}

export const resetDataFailed: ActionCreator = (payload) => {
  return {
    type: RESET_DATA_FAILED,
    payload
  }
}

export const unloadData: ActionCreator = (payload) => {
  return {
    type: UNLOAD_DATA,
    payload
  }
}

