import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./Timeline.module.css"

const EffectPin = (props: {
  icon: IconName
  title: string
  onClick
}) => {
  return <div
    className={styles.effectPoint}
  >
    <div
      className={styles.connect}
    ></div>
    <div className={styles.effectIcons}>
      <div className={styles.effectIconTitles}>
        <div>{props.title}</div>
      </div>
      <div className={styles.effectIconWrapper}>
        <div
          className={[styles.effectIcon, 'clickable'].join(' ')}
          onClick={() => props.onClick()}
        >
          <div>
            <FontAwesomeIcon icon={["fas", props.icon]} />
          </div>
        </div>
      </div>

    </div>
  </div>
}

export default EffectPin