import standardFederalDeduction from "../deductions/standardFederalDeduction";
import standardFederalDeductionData from "../data/standardFederalDeductionData";
import stateStandardDeduction from "../deductions/stateStandardDeduction";
import stateStandardDeductionData from "../data/stateStandardDeductionData";
import statePersonalExemptions from "../deductions/statePersonalExemptions";
import statePersonalExemptionsData from "../data/statePersonalExemptionsData";
import traditionalIRADeduction from "./traditionalIRADeduction";
import IRALimits from "../data/IRALimits";
import traditionalIRATaperingData from "../data/traditionalIRATaperingData";
import _529Deduction from "./529Deduction";
import _529DeductionData from "../data/529DeductionData";
import HSADeduction from "./HSADeduction";
import HSADeductionData from "../data/HSADeductionData";

export default {
  standardFederalDeduction: standardFederalDeduction(standardFederalDeductionData),
  stateStandardDeduction: stateStandardDeduction(stateStandardDeductionData),
  statePersonalExemptions: statePersonalExemptions(statePersonalExemptionsData),
  traditionalIRADeduction: traditionalIRADeduction(IRALimits, traditionalIRATaperingData),
  _529Deduction: _529Deduction(_529DeductionData),
  HSADeduction: HSADeduction(HSADeductionData)
}