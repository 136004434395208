import { Income } from "../../lib/types"
import { UNLOAD_DATA } from "../actions/data"
import { ADD_INCOME, REMOVE_INCOME, SET_INCOMES, UPDATE_INCOME } from "../actions/incomes"

const initial: Income[] = []

const incomes = (state = initial, action) => {
  switch (action.type) {
    case SET_INCOMES:
      return action.payload || []

    case ADD_INCOME:
      const newState = state.filter(income => income.id !== action.payload.id)
      newState.push(action.payload)
      return newState

    case UPDATE_INCOME:
      return state.map(income => {
        if (income.id === action.payload.id) income = Object.assign({}, income, action.payload)
        return income
      })

    case REMOVE_INCOME:
      return state.filter(income => income.id !== action.payload.id)

    case UNLOAD_DATA:
      return initial

    default:
      return state
  }
}

export default incomes