const _529CreditsData = {
  "2021": [
    {
      state: "Indiana",
      percentage: 20,
      max: {
        single: 1000,
        married: 1000
      }
    },
    {
      state: "Oregon",
      percentage: 5, // simplification, most of our customers will fall into this limit
      max: {
        single: 150,
        married: 300
      }
    }
  ]
}

export default _529CreditsData