import "./Avatar.css"

import React, { ReactNode, useMemo } from "react"
import { useDispatch } from "react-redux"
import { authLogoutStart } from "../../store/actions/auth"
import { useAppSelector } from "../../store"
import { Dropdown } from "react-bootstrap"
import { resetDataRequest } from "../../store/actions/data";
import { push } from "connected-react-router";
import { MEMBER_TYPES } from "../../lib/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const CustomToggle = React.forwardRef<any, { onClick: any, children: ReactNode }>(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));


const Avatar = () => {
  const { user, members, admin } = useAppSelector((state) => ({ user: state.auth.user, members: state.members, admin: state.admin }))
  const dispatch = useDispatch()

  const isAdmin = useMemo(() => {
    return user?.['https://oursteward.com/household_id'] === 'admin'
  }, [user])


  const primaryMember = useMemo(() => {
    return members.find(member => member.type === MEMBER_TYPES.PRIMARY)
  }, [members])

  const resetData = () => {
    if (!window.confirm('Are you sure you want to delete all your data?')) return
    dispatch(resetDataRequest())
  }

  return (
    <div className="avatar-container">
      {user &&
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} variant="success" id="dropdown-custom-components">
            <div className="avatar" style={{ backgroundColor: primaryMember?.color }}>
              <div className="initials">
                {isAdmin ? <FontAwesomeIcon icon={["fas", "user-gear"]} /> : user.nickname.substring(0, 1).toUpperCase()}
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {(!isAdmin || admin.householdId) && <>
              <Dropdown.Item as="button" onClick={() => dispatch(push('/household'))}>My household</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => resetData()}>Reset data</Dropdown.Item>
            </>}
            <Dropdown.Item as="button" onClick={() => dispatch(authLogoutStart())}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      }
    </div>
  )
}

export default Avatar
