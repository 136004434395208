import { Link } from "react-router-dom"
import styles from "./DashboardCard.module.css"
import EmptyText from "../EmptyText";
import Pill from "../Pill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const DashboardCard = (props: {
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  footerCTA?: string
  footerCTALink?: string
  children: React.ReactNode,
  placeholder?: boolean,
  disabled?: boolean,
  id?: string
}) => {
  return (
    <div id={props.id} className={[styles.container, (props.placeholder ? styles.transparent : '')].join(' ')}>
      {props?.placeholder && <div className={styles.overlay}></div>}
      <div className={styles.titles}>
        <div className="small d-flex justify-content-between">
          {props.title || <EmptyText textLength={8} />}
          {props.placeholder && <Pill variant="incomes" content="######" placeholder={true} />}
        </div>
        <div className='headline-6 me-2'>
          {props.description || <EmptyText textLength={10} />}
        </div>
      </div>
      <div className={[styles.body].join(' ')}>
        {props.children}
      </div>
      {((props.footerCTA && props.footerCTALink) || props.placeholder) && (
        <div className={[styles.footer, (props.disabled && styles.disabled), 'body-bold'].join(' ')}>
          {props.placeholder ? (
            <div className={[styles.fakeButton].join(' ')} />
          ) : (
            <Link to={props.footerCTALink || ""}>
              <div className='d-flex align-items-center'>
                <div className='me-2'>{props.footerCTA || "Open"}</div>
                <FontAwesomeIcon icon={['far', 'chevron-right']} />
              </div>
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export default DashboardCard