import { Col, Container, Row } from "react-bootstrap"

const Investment = () => {
  return <Container fluid>
    <Row>
      <Col>
        Investment
      </Col>
    </Row>
  </Container>
}

export default Investment