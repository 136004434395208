import styles from "./DashboardRow.module.css"

const DashboardRow = (props: {
  title: string
  description: string
  children: React.ReactNode
}) => {
  return (
    <div className={styles.container + " mb-5"}>
      <div className="headline-5">{props.title}</div>
      <p className={["small", styles.description].join(" ")}>{props.description}</p>
      <div className={styles.scroller}>
        <div className={styles.scrollerInner}>
          <div className={styles.cards + " d-flex gap-2 flex-nowrap"}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardRow