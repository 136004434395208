import { call, put } from "redux-saga/effects";
import { getAdminHouseholdIdSuccess, getAdminHouseholdIdFailed, setAdminHouseholdIdSuccess, setAdminHouseholdIdFailed, deleteAdminHouseholdIdSuccess, deleteAdminHouseholdIdFailed } from '../store/actions/admin'
import * as ls from "local-storage";
import { unloadData } from "../store/actions/data";
const ADMIN_HOUSEHOLD_ID = "ADMIN_HOUSEHOLD_ID"

export function* getHouseholdId() {
  try {
    const householdId = yield call(ls.get, ADMIN_HOUSEHOLD_ID)
    yield put(getAdminHouseholdIdSuccess(householdId))
  } catch (e) {
    yield call(console.log, e)
    yield put(getAdminHouseholdIdFailed())
  }
}

export function* setHouseholdId(action) {
  try {
    yield call(ls.set, ADMIN_HOUSEHOLD_ID, action.payload)
    yield put(setAdminHouseholdIdSuccess(action.payload))
  } catch (e) {
    yield call(console.log, e)
    yield put(setAdminHouseholdIdFailed())
  }
}

export function* deleteHouseholdId() {
  try {
    yield call(ls.remove, ADMIN_HOUSEHOLD_ID)
    yield put(deleteAdminHouseholdIdSuccess())
    yield put(unloadData())
  } catch (e) {
    yield call(console.log, e)
    yield put(deleteAdminHouseholdIdFailed())
  }
}