import { medicareTaxConfig } from "../types"

const medicareTax: medicareTaxConfig = {
  "2021": {
    single: [{
      min: 0,
      max: 200000,
      bracket: 1.45,
      floor: 0
    },
    {
      min: 200000,
      bracket: 2.35,
      floor: 2900
    }],
    married: [{
      min: 0,
      max: 250000,
      bracket: 1.45, 
      floor: 0
    },
    {
      min: 250000,
      bracket: 2.35,
      floor: 3625
    }]
  }
}

export default medicareTax