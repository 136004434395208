import { EFFECT_CATEGORIES, EFFECT_OPERATIONS, EFFECT_SUBJECTS } from "../constants"

const effectCategories: {} = {
  [EFFECT_CATEGORIES.OTHER_EXPENSE_CHANGE]: {
    title: "Change in expense",
    icon: "arrow-down-arrow-up",
    subject: EFFECT_SUBJECTS.expense,
    fields: [
      {
        name: "operation",
        label: "",
        type: "operation"
      },
      {
        name: "name",
        label: "Name",
        type: "text"
      },
      {
        name: "amount",
        label: "Amount",
        placeholder: "Fill amount of expense change",
        type: "amount"
      },
      {
        name: "year",
        label: "Start year",
        type: "number"
      },
      {
        name: "activeYears",
        label: "Length (years)",
        type: "term"
      },
    ]
  },
  [EFFECT_CATEGORIES.OTHER_INCOME_CHANGE]: {
    title: "Change in income",
    icon: "arrow-down-arrow-up",
    subject: EFFECT_SUBJECTS.income,
    fields: [
      {
        name: "operation",
        label: "",
        type: "operation"
      },
      {
        name: "name",
        label: "Name",
        type: "text"
      },
      {
        name: "amount",
        label: "Amount",
        placeholder: "Fill amount of income change",
        type: "amount"
      },
      {
        name: "year",
        label: "Start year",
        type: "number"
      },
      {
        name: "activeYears",
        label: "Length (years)",
        type: "term"
      },
    ]
  },
  [EFFECT_CATEGORIES.CHILD_CARE]: {
    title: "Child care",
    icon: "children",
    subject: EFFECT_SUBJECTS.expense,
    operation: EFFECT_OPERATIONS.increase,
    fields: [
      {
        name: "amount",
        label: "Amount",
        placeholder: "Fill amount of child care",
        type: "amount"
      },
      {
        name: "year",
        label: "Start year",
        type: "number"
      },
      {
        name: "activeYears",
        label: "Length (years)",
        type: "term"
      },
    ]
  },
  [EFFECT_CATEGORIES.PARENT_CARE]: {
    title: "Care for aging parent",
    icon: "person-cane",
    subject: EFFECT_SUBJECTS.expense,
    operation: EFFECT_OPERATIONS.increase,
    fields: [
      {
        name: "amount",
        label: "Amount",
        placeholder: "Fill amount of parent care",
        type: "amount"
      },
      {
        name: "year",
        label: "Start year",
        type: "number"
      },
      {
        name: "activeYears",
        label: "Length (years)",
        type: "term"
      },
    ]
  },
  [EFFECT_CATEGORIES.SELLING_PROPERTY]: {
    title: "Selling property",
    icon: "house",
    subject: EFFECT_SUBJECTS.income,
    operation: EFFECT_OPERATIONS.increase,
    fields: [
      {
        name: "amount",
        label: "Value",
        placeholder: 'Fill value of property',
        type: "amount"
      },
      {
        name: "year",
        label: "Year",
        type: "number"
      },
    ]
  },
  [EFFECT_CATEGORIES.PRIVATE_SCHOOL]: {
    title: "Private school",
    icon: "building-columns",
    subject: EFFECT_SUBJECTS.expense,
    operation: EFFECT_OPERATIONS.increase,
    fields: [
      {
        name: "amount",
        label: "Amount",
        placeholder: 'Fill amount of tuition fee',
        type: "amount"
      },
      {
        name: "year",
        label: "Year",
        type: "number"
      },
      {
        name: "activeYears",
        label: "Length (years)",
        type: "term"
      },
    ]
  },
  [EFFECT_CATEGORIES.HOUSEHOLD_EXPENSES]: {
    title: "Maintenance + HOA",
    subject: EFFECT_SUBJECTS.expense,
    operation: EFFECT_OPERATIONS.increase,
    fields: [
      {
        name: "amount",
        label: "Annual cost",
        placeholder: 'Fill annual cost',
        type: "amount"
      },
      {
        name: "year",
        label: "Year",
        type: "number"
      },
    ]
  },
  [EFFECT_CATEGORIES.ONE_TIME_INCOME]: {
    title: "One-time income",
    icon: "sack-dollar",
    subject: EFFECT_SUBJECTS.income,
    operation: EFFECT_OPERATIONS.increase,
    fields: [
      {
        name: "amount",
        label: "Amount",
        placeholder: 'Fill amount of one-time income',
        type: "amount"
      },
      {
        name: "year",
        label: "Year",
        type: "number"
      },
    ]
  },
  [EFFECT_CATEGORIES.RELOCATION]: {
    title: "Tax location change",
    icon: "dolly",
  },
  [EFFECT_CATEGORIES.LOAN]: {
    icon: "building-columns",
  }
}

export default effectCategories