import { EFFECT_CATEGORIES, EFFECT_OPERATIONS, EFFECT_SUBJECTS, INCOME_TYPES } from "../../constants";
import { Effect, Loan } from "./types";

export const incomesAfterTaxes = (
  originalIncomes: { [K in keyof typeof INCOME_TYPES | "otherAnnualIncomes"]?: number[] },
  takeHomeIncome
): { [K in keyof typeof INCOME_TYPES | "otherAnnualIncomes"]?: number } => {
  const sums = Object.keys(originalIncomes).reduce((result, key) => {
    result[key] = originalIncomes[key].reduce((sum, item) => sum + item, 0)
    return result
  }, {})
  const total = Object.keys(sums).reduce((sum, key) => sum + sums[key], 0)
  const percentages = Object.keys(sums).reduce((result, key) => {
    result[key] = sums[key] / (total / 100)
    return result
  }, {})
  return Object.keys(sums).reduce((result, key) => {
    result[key] = Math.floor((takeHomeIncome / 100) * percentages[key])
    return result
  }, {})
}

export const yearlyPaymentOfLoan = (loan: Loan, inflationRate: number = 0): number => {
  // decrease percentage rate by inflation rate
  const adjustedAPR = loan.annualPercentageRate - inflationRate
  return loan.total * (adjustedAPR / 12) * Math.pow(1 + adjustedAPR / 12, loan.activeYears * 12) / (Math.pow(1 + adjustedAPR / 12, loan.activeYears * 12) - 1) * 12
}

export const convertLoansToEffects = (loans: Loan[], inflationRate: number = 0): Effect[] => {
  return loans.map(loan => {
    const payment = yearlyPaymentOfLoan(loan, inflationRate)
    return {
      id: loan.id,
      name: loan.name,
      year: loan.startYear,
      activeYears: loan.activeYears,
      amount: payment,
      subject: EFFECT_SUBJECTS.expense,
      category: EFFECT_CATEGORIES.LOAN,
      operation: EFFECT_OPERATIONS.increase
    }
  })
}