import styles from './Timeline.module.css'
import { formatAmount } from "../../lib/utils/number";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Effect } from "../../lib/modules/timeline/types";
import { EFFECT_OPERATIONS, EFFECT_SUBJECTS } from '../../lib/constants';
import { useMemo } from 'react';

const TopBar = ({ year, savings, effects }: { year: string | null, savings: number | null, effects: Effect[] }) => {
  const effectSum = useMemo(() => {
    return effects.reduce((sum, effect) => {
      if (effect.subject === EFFECT_SUBJECTS.expense) {
        sum += ((effect.operation === EFFECT_OPERATIONS.increase ? -1 : 1) * effect.amount)
      } else {
        sum += ((effect.operation === EFFECT_OPERATIONS.increase ? 1 : -1) * effect.amount)
      }
      return sum
    }, 0)
  }, [effects])

  return (
    <div className={styles.topbarContainer}>
      <div className='d-flex'>
        {year && <div className='me-5 body-small fw-semi-bold'>{year}</div>}
        {savings && (
          <div className='d-flex align-items-center fw-bold text-cta'>
            <FontAwesomeIcon icon={["far", "money-bill"]} className='me-2' />
            <div>{formatAmount(savings, { short: true })}</div>
          </div>
        )}
      </div>
      <div className='d-flex align-items-center gap-3'>
        {(effects.length ? true : false) && <div >
          {effects.length} financial effect{effects.length > 1 ? "s" : ""}: <span className={[(effectSum > 0 ? "text-inflow" : "text-outflow"), "fw-bold"].join(" ")}>{effectSum < 0 ? "-" : "+"}{formatAmount(Math.abs(effectSum), { short: true })}</span>
        </div>}
        {/* {effects.map((effect, index) => {
          const positive = (effect.subject === "income" && effect.operation === EFFECT_OPERATIONS.increase) || (effect.subject === "expense" && effect.operation === EFFECT_OPERATIONS.decrease)
          return (
            <div key={effect.id + '_' + index} className='d-flex'>
              <div className='me-1'>{effect.name}</div>
              <div className={[positive ? 'text-inflow' : 'text-outflow', 'fw-bold'].join(' ')}>
                {[positive ? '+' : '-'] + formatAmount(effect.amount, { short: true }) + '/y'}
              </div>
            </div>
          )
        })} */}
      </div>
    </div>
  )
}

export default TopBar