import { v4 } from "uuid";
import Card from "../../components/Household/Card"
import PageTitle from "../../components/Household/PageTitle"
import CardTitle from "../../components/Household/CardTitle"
import Cards from "../../components/Household/Cards"
import Pill from "../../components/Pill"
import CardFooter from "../../components/Household/CardFooter"
import { EXPENSE_CATEGORIES, EXPENSE_GROUPS, EXPENSE_TYPES, FREQUENCY_TYPES } from "../../lib/constants"
import Property from "../../components/Household/Property"
import { Expense } from "../../lib/types"
import { useDispatch } from "react-redux"
import { addExpense, updateExpense, removeExpense } from "../../store/actions/expenses"
import { expenseSum } from "../../lib/utils/expense";
import PropertyList from "../../components/Household/PropertyList";
import { formatAmount } from "../../lib/utils/number";
import { confirmMessages } from "../../lib/config/copy";
import expenseCategories from "../../lib/config/expenseCategories";
import { useState } from "react";

const Expenses = ({ expenses }: { expenses: Expense[] }) => {
  const dispatch = useDispatch()

  const [lastAdded, setLastAdded] = useState<string | null>(null)

  const expensesRequiredFields = ['amount', 'name']

  const handleExpenseChange = (value: Expense) => {
    dispatch(updateExpense(value))
  }


  const handleExpenseCreate = (category?: keyof typeof EXPENSE_CATEGORIES) => {
    // const lastAdded = [...expenses].pop()
    // if (lastAdded && expensesRequiredFields.every(key => !lastAdded[key])) return
    const expense: Expense = {
      id: v4(),
      frequency: FREQUENCY_TYPES.MONTHLY,
      name: "",
      category: category || EXPENSE_CATEGORIES.OTHER,
      amount: 0
    }
    setLastAdded(expense.id)
    dispatch(addExpense(expense))
  }


  const handleExpenseRemove = (expense: Expense) => {
    if (expensesRequiredFields.every(field => !expense[field])) return dispatch(removeExpense({ id: expense.id }))
    if (!window.confirm(confirmMessages['expenseRemove'])) return
    dispatch(removeExpense({ id: expense.id }))
  }

  const expenseOptions = [
    EXPENSE_CATEGORIES.RENT,
    EXPENSE_CATEGORIES.CHILD_CARE,
    EXPENSE_CATEGORIES.TUITION_OR_EDUCATION,
    EXPENSE_CATEGORIES.CREDIT_CARD_BILLS,
    EXPENSE_CATEGORIES.MORTGAGE_PAYMENT,
    EXPENSE_CATEGORIES.CAR_LOAN_PAYMENT,
    EXPENSE_CATEGORIES.STUDENT_LOAN_PAYMENT,
    EXPENSE_CATEGORIES.OTHER
  ]

  return (
    <div>
      <PageTitle>
        <div className="headline-5 d-flex align-items-center">
          <div className='me-2'>Expenses</div>
          <Pill variant="expenses" content={formatAmount(expenseSum([...expenses]), { frequency: FREQUENCY_TYPES.YEARLY })} />
        </div>
      </PageTitle>
      <Cards>
        <Card>
          <CardTitle
            variant="collection"
            title="Expenses"
            icon={"coins"}
            sum={formatAmount(expenseSum(expenses) / 12, { frequency: FREQUENCY_TYPES.MONTHLY, short: true })}
            type="expenses"
          />
          <PropertyList>
            {expenses.map(expense => (
              <Property
                type={EXPENSE_GROUPS[EXPENSE_TYPES.LIABILITY].includes(expense.category as any) ? EXPENSE_TYPES.LIABILITY : EXPENSE_TYPES.EXPENSE}
                icon={expenseCategories[expense.category].icon || 'coins'}
                id={expense.id}
                key={expense.id}
                data={expense}
                open={lastAdded === expense.id}
                onChange={(value) => handleExpenseChange(value)}
                onRemove={() => handleExpenseRemove(expense)}
              />
            ))}
          </PropertyList>
          <CardFooter
            options={expenseOptions.map(category => ({
              title: expenseCategories[category].title,
              icon: expenseCategories[category].icon,
              value: category
            }))}
            onAdd={(category) => handleExpenseCreate(category)}
          />
        </Card>
      </Cards>
    </div>
  )
}

export default Expenses