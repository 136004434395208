import { Effect } from "../../lib/modules/timeline/types"
import { ActionCreator } from "../../lib/types"

export const SET_EFFECTS = "EFFECTS/setEffects"
export const ADD_EFFECT = "EFFECTS/addEffect"
export const UPDATE_EFFECT = "EFFECTS/updateEffect"
export const REMOVE_EFFECT = "EFFECTS/removeEffect"


export const setEffects: ActionCreator<{
  [K in keyof Effect]: Effect[K]
}[]> = (payload) => {
  return {
    type: SET_EFFECTS,
    payload
  }
}
export const addEffect: ActionCreator<{
  [K in keyof Effect]: Effect[K]
}> = (payload) => {
  return {
    type: ADD_EFFECT,
    payload
  }
}

export const updateEffect: ActionCreator<{
  [K in keyof Effect]: Effect[K]
}> = (payload) => {
  return {
    type: UPDATE_EFFECT,
    payload
  }
}

export const removeEffect: ActionCreator<{
  id: string
}> = (payload) => {
  return {
    type: REMOVE_EFFECT,
    payload
  }
}