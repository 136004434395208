import { useEffect, useMemo, useRef, useState } from "react";
import { throttle } from "throttle-debounce";

const FluidWrapper = (props: { children: React.ReactNode }) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const [availableSize, setAvailableSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 })
  const [contentSize, setContentSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 })

  useEffect(() => {
    const setSize = throttle(50, () => {
      setAvailableSize({
        width: containerRef?.current?.clientWidth || 1,
        height: containerRef?.current?.clientHeight || 1
      })
    })
    window.addEventListener('resize', setSize)
    return () => window.removeEventListener('resize', setSize)
  }, [])


  useEffect(() => {
    setContentSize({
      width: contentRef?.current?.clientWidth || 1,
      height: contentRef?.current?.clientHeight || 1,
    })
    // eslint-disable-next-line
  }, [contentRef?.current])


  useEffect(() => {
    setAvailableSize({
      width: containerRef?.current?.clientWidth || 1,
      height: containerRef?.current?.clientHeight || 1
    })
  }, [contentSize])

  const paddingBottom = useMemo(() => {
    return ((contentSize.width > contentSize.height ? contentSize.height / contentSize.width : contentSize.width / contentSize.height) * 100) + '%'
  }, [contentSize])

  const scale = useMemo(() => {
    return Math.min(availableSize.width / contentSize.width, availableSize.height / contentSize.height)
  }, [contentSize, availableSize])

  return (
    <div className='w-100 position-relative overflow-hidden' style={{ paddingBottom: paddingBottom }} ref={containerRef}>
      <div className='position-absolute top-50 start-50' style={{ transform: "translate(-50%, -50%) scale(" + scale + ")" }}>
        <div ref={contentRef}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default FluidWrapper