import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Switch,
  Route,
} from "react-router-dom";
import { useAppSelector } from "../store";
import { authCallback, authGetTokenStart } from "../store/actions/auth";
import { dataChanged, getDataRequest } from "../store/actions/data";
import AuthCallback from "./AuthCallback";
import Dashboard from "./Dashboard";
import Finances from "./Finances";
import Household from "./Household";
import Investment from "./Investment";
import Layout from "./Layout";
import Timeline from "./Timeline";
import LoadingBar from 'react-top-loading-bar'
import Loading from "../components/Loading";
import { cssValue } from "../lib/utils/ui";
import Onboarding from "../components/Onboarding";
import { getAdminHouseholdIdRequest } from "../store/actions/admin";

const Root = () => {
  const ref = useRef<any>(null)
  const router = useAppSelector((state) => state.router)
  const auth = useAppSelector((state) => state.auth)
  const data = useAppSelector((state) => state.data)

  const household = useAppSelector((state) => state.household)
  const members = useAppSelector((state) => state.members)
  const assets = useAppSelector((state) => state.assets)
  const debts = useAppSelector((state) => state.debts)
  const expenses = useAppSelector((state) => state.expenses)
  const incomes = useAppSelector((state) => state.incomes)
  const goals = useAppSelector((state) => state.goals)
  const effects = useAppSelector((state) => state.effects)
  const loans = useAppSelector((state) => state.loans)
  const relocations = useAppSelector((state) => state.relocations)
  const admin = useAppSelector(state => state.admin)

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router?.location?.pathname])

  useEffect(() => {
    if (router?.location?.pathname === "/auth-callback") {
      dispatch(authCallback({ redirectTo: "/" }))
    } else if (!auth.user) {
      if (router?.location?.pathname === "/signup") {
        console.log("signup")
        dispatch(authGetTokenStart({ authType: "signup" }))
      } else {
        dispatch(authGetTokenStart())
      }
    }
  }, [dispatch, router, auth.user])

  const isAdmin = useMemo(() => {
    return auth.user?.['https://oursteward.com/household_id'] === 'admin'
  }, [auth.user])

  useEffect(() => {
    console.log({ admin, data })
    if (auth.user && !data.ready && !data.loading && !data.error) {
      if (isAdmin && !admin.loading) {
        if (admin.checked && admin.householdId) dispatch(getDataRequest(admin.householdId))
        if (!admin.checked) dispatch(getAdminHouseholdIdRequest())
      }
      if (!isAdmin) dispatch(getDataRequest(auth.user['https://oursteward.com/household_id']))
    }
  }, [dispatch, auth, data, isAdmin, admin])

  useEffect(() => {
    // TODO this would be better as a saga or something 
    if (data.ready) dispatch(dataChanged())
    // eslint-disable-next-line
  }, [
    household,
    members,
    assets,
    debts,
    expenses,
    incomes,
    goals,
    effects,
    loans,
    relocations,
    dispatch
  ])

  useEffect(() => {
    if (data.ready && !loading && data.loading) setLoading(true)
    if (data.ready && loading && !data.loading) setLoading(false)
  }, [data, loading])

  useEffect(() => {
    if (loading) {
      ref?.current?.continuousStart()
    } else {
      ref?.current?.complete()
    }
  }, [loading])

  return (
    <div>
      {(!auth.user || !((isAdmin && admin.checked && !admin.householdId) || data?.ready)) && <Loading />}
      {/* {(isAdmin && admin.checked && !admin.householdId) && <Layout><AdminLandingPage /></Layout>} */}
      {auth.user && <>
        <LoadingBar color={cssValue("--cta--blue")} ref={ref} height={2} />
        {data?.ready && !members?.length ? <Onboarding household={household} user={auth.user} /> : <></>}
        <Layout>
          <Switch>
            <Route exact path="/auth-callback">
              <AuthCallback />
            </Route>
            {(!isAdmin || admin.householdId)
              ?
              <>
                <Route exact path="/">
                  <Dashboard />
                </Route>
                <Route path="/household">
                  <Household />
                </Route>
                <Route exact path="/finances">
                  <Finances />
                </Route>
                <Route path="/timeline/:id?">
                  <Timeline />
                </Route>
                <Route exact path="/investment">
                  <Investment />
                </Route>
              </>
              :
              <Route path="/">
                <div className="text-center py-5">
                  <div className="fw-bold mb-1">
                    Admin mode!
                  </div>
                  <div className="small text-muted">
                    Set a household ID in the top right corner!
                  </div>
                </div>
              </Route>
            }
          </Switch>
        </Layout>
      </>}
    </div>
  )
}

export default Root