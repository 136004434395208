import styles from "./SidebarPanelTitle.module.css"

const SidebarPanelTitle = (props: {
  children: React.ReactNode
}) => {
  return <div className={styles.container}>
    {props.children}
  </div>
}

export default SidebarPanelTitle