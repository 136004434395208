import { Expense } from "../../lib/types"
import { UNLOAD_DATA } from "../actions/data"
import { ADD_EXPENSE, REMOVE_EXPENSE, SET_EXPENSES, UPDATE_EXPENSE } from "../actions/expenses"

const initial: Expense[] = []

const expenses = (state = initial, action) => {
  switch (action.type) {
    case SET_EXPENSES:
      return action.payload || []

    case ADD_EXPENSE:
      const newState = state.filter(expense => expense.id !== action.payload.id)
      newState.push(action.payload)
      return newState

    case UPDATE_EXPENSE:
      return state.map(expense => {
        if (expense.id === action.payload.id) expense = Object.assign({}, expense, action.payload)
        return expense
      })

    case REMOVE_EXPENSE:
      return state.filter(expense => expense.id !== action.payload.id)
    
    case UNLOAD_DATA:
      return initial
    
    default:
      return state
  }
}

export default expenses