// import { GOAL_CATEGORIES } from "../../lib/constants"
import { Goal } from "../../lib/modules/timeline/types"
import { UNLOAD_DATA } from "../actions/data"
import { SET_GOALS, ADD_GOAL, UPDATE_GOAL, REMOVE_GOAL } from "../actions/goals"

const initial: Goal[] = []

const goals = (state = initial, action) => {
  switch (action.type) {
    case SET_GOALS:
      return action.payload || []

    case ADD_GOAL:
      const newState = state.filter(goal => goal.id !== action.payload.id)
      newState.push(action.payload)
      return newState

    case UPDATE_GOAL:
      return state.map(goal => {
        if (goal.id === action.payload.id) goal = Object.assign({}, goal, action.payload)
        return goal
      })

    case REMOVE_GOAL:
      return state.filter(goal => goal.id !== action.payload.id)

    case UNLOAD_DATA:
      return initial

    default:
      return state
  }
}

export default goals