import { FREQUENCY_TYPES } from "../constants"

export const formatAmount = (
  amount: number,
  options?: {
    frequency?: keyof typeof FREQUENCY_TYPES,
    short?: boolean
  }
): string => {
  if (!amount) return "$0"
  const absAmount = Math.abs(amount)
  const frequencies = {
    [FREQUENCY_TYPES.MONTHLY]: "/month",
    [FREQUENCY_TYPES.YEARLY]: "/year",
  }
  let formattedAmount = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3 }).format(absAmount)
  if (options?.short) {
    if (absAmount < 1000000) {
      formattedAmount = "$" + (absAmount / 1000).toFixed(0) + "k"
    } else {
      formattedAmount = "$" + (absAmount / 1000000).toFixed(2).replace(".00", "") + "M"
    }
    if (absAmount < 1000 && amount > 0) formattedAmount = "<$1k"
  }
  const formattedFrequency = options?.frequency ? frequencies[options.frequency] : ""
  const sign = amount < 0 ? "-" : ""
  return [sign, formattedAmount, formattedFrequency].join("")
}