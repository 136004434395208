import { Point } from "."
import goalCategories from "../../lib/config/goalCategories"
import { GOAL_CATEGORIES, RETIREMENT } from "../../lib/constants"
import GoalIcon from "../GoalIcon"
import styles from "./Timeline.module.css"

const GoalPin = (props: {
  goals: {
    id: string
    titles: string[]
    category?: keyof typeof GOAL_CATEGORIES
  }[]
  position: Point
  reached: boolean
  onMouseEnter,
  className,
  onClick
}) => {

  const isRetirementGoal = props.goals.find(goal => goal.category === RETIREMENT)

  return <>
    <div
      onMouseEnter={props.onMouseEnter}
      className={[styles.goalPoint, (props.reached ? styles.reached : ""), (isRetirementGoal ? styles.retirement : '')].join(" ")}
      style={{ bottom: (props.position.y - (props.reached ? 10 : 9)) + "px", left: props.position.x + "px" }}
      key={"point_" + JSON.stringify(props.position)}
    >
      <div
        className={[styles.connect, (props.reached ? styles.reached : ''), (isRetirementGoal ? styles.retirement : '')].join(' ')}
      ></div>
      <div className={styles.goalIcons}>
        <div className={[styles.goalIconTitles, props.className, (isRetirementGoal ? styles.retirement : '')].join(" ")}>
          {props.goals.map((goal, goalIndex) => (
            <div key={"titles_" + goal.id} className={goalIndex < props.goals.length - 1 ? "mb-1" : ""}>
              {goal.titles.map(title => title || goalCategories[goal.category || ""]?.title).map((title, index) => (
                <div key={"title_" + goal.id + index}>
                  {title}
                </div>
              ))}
            </div>
          ))}
        </div>
        {props.goals.map((goal) => (
          <div className={styles.goalIconWrapper} key={goal.id}>
            <div
              className={[
                (goal.category !== GOAL_CATEGORIES.RETIREMENT ? 'clickable' : ''),
                styles.goalIcon,
                (props.reached ? styles.reached : ''),
                (goal.category === RETIREMENT ? styles.retirement : '')
              ].join(' ')}
              {...(goal.category !== GOAL_CATEGORIES.RETIREMENT ? { onClick: () => props.onClick(goal.id) } : {})}
            >
              <div>
                <GoalIcon category={goal.category} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </>
}

export default GoalPin