import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth'
import data from './data'
import household from './household'
import members from './members'
import expenses from './expenses'
import incomes from './incomes'
import assets from './assets'
import debts from './debts'
import goals from './goals'
import effects from './effects'
import loans from './loans'
import relocations from './relocations'
import admin from './admin'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  data,
  admin,
  // -- processed stuff --
  household,
  members,
  expenses,
  incomes,
  assets,
  debts,
  goals,
  effects,
  loans,
  relocations
})

export default createRootReducer