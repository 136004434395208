import styles from './EmptyText.module.css'

const EmptyText = (props: { textLength: number }) => {

  const placeholderText = new Array(props.textLength).fill('#').join('')

  return (
    <div className='d-flex'>
      <div className='position-relative'>
        <div className='invisible'>
          {placeholderText}
        </div>
        <div className={styles.line} />
      </div>
    </div>
  )
}

export default EmptyText