import { localIncomeTaxConfig } from "../types";

const localIncomeTaxData: localIncomeTaxConfig = {
  "2021": [
    {
      city: "San Francisco",
      state: "California",
      bracket: 1.50
    },
    {
      city: "Newark",
      state: "New Jersey",
      bracket: 1
    },
    {
      city: "New York",
      state: "New York",
      bracket: 3.876
    },
    {
      city: "Philadelphia",
      state: "Pennsylvania",
      bracket: 3.8809
    },
    {
      city: "Reading",
      state: "Pennsylvania",
      bracket: 3.60
    },
    {
      city: "Scranton",
      state: "Pennsylvania",
      bracket: 3.40
    },
    {
      city: "Pittsburgh",
      state: "Pennsylvania",
      bracket: 3
    },
    {
      city: "Bethlehem",
      state: "Pennsylvania",
      bracket: 1
    },
    {
      city: "Lancaster",
      state: "Pennsylvania",
      bracket: 1.10
    },
    {
      city: "Portland",
      state: "Oregon",
      bracket: 0.6918
    },
    {
      city: "Akron",
      state: "Ohio",
      bracket: 2.50
    },
    {
      city: "Cleveland",
      state: "Ohio",
      bracket: 2.50
    },
    {
      city: "Columbus",
      state: "Ohio",
      bracket: 2.50
    },
    {
      city: "Dayton",
      state: "Ohio",
      bracket: 2.50
    },
    {
      city: "Youngstown",
      state: "Ohio",
      bracket: 2.75
    },
    {
      city: "Toledo",
      state: "Ohio",
      bracket: 2.25
    },
    {
      city: "Cincinnati",
      state: "Ohio",
      bracket: 2.10
    },
    {
      city: "Kansas City",
      state: "Missouri",
      bracket: 1
    },
    {
      city: "St. Louis",
      state: "Missouri",
      bracket: 1
    },
    {
      city: "Detroit",
      state: "Michigan",
      bracket: 2.40
    },
    {
      city: "Grand Rapids",
      state: "Michigan",
      bracket: 1.50
    },
    {
      city: "Highland Park",
      state: "Michigan",
      bracket: 2
    },
    {
      city: "Saginaw",
      state: "Michigan",
      bracket: 1.50
    },
    {
      city: "Bessemer",
      state: "Alabama",
      bracket: 1
    },
    {
      city: "Birmingham",
      state: "Alabama",
      bracket: 1
    },
    {
      city: "Gadsden",
      state: "Alabama",
      bracket: 2
    },
    {
      city: "Baltimore",
      state: "Maryland",
      bracket: 3.20
    },
    {
      city: "Columbia",
      state: "Maryland",
      bracket: 3.20
    },
    {
      city: "Louisville",
      state: "Kentucky",
      bracket: 2.20
    },
    {
      city: "Lexington",
      state: "Kentucky",
      bracket: 2.25
    },
    {
      city: "Indianapolis",
      state: "Indiana",
      bracket: 3.23
    },]
}

export default localIncomeTaxData