import { Benchmark } from "../types";
import { comparisonText } from "../utils";
import { SAVING } from "../../../constants";

const saving = (income: number, savings: number): Benchmark => {
  if (!income || !savings) return
  const recommendedPercentage: number = 20
  const actualPercentage: number = Math.round((savings / income) * 100)
  const difference: number = recommendedPercentage - actualPercentage
  const positive: boolean = actualPercentage >= recommendedPercentage
  return { text: comparisonText(SAVING, difference, positive), positive }
}

export default saving