import { Loan } from "../../lib/modules/timeline/types"
import { UNLOAD_DATA } from "../actions/data"
import { SET_LOANS, ADD_LOAN, UPDATE_LOAN, REMOVE_LOAN } from "../actions/loans"

const initial: Loan[] = []

const loans = (state = initial, action) => {
  switch (action.type) {
    case SET_LOANS:
      return action.payload || []

    case ADD_LOAN:
      const newState = state.filter(loan => loan.id !== action.payload.id)
      newState.push(action.payload)
      return newState

    case UPDATE_LOAN:
      return state.map(loan => {
        if (loan.id === action.payload.id) loan = Object.assign({}, loan, action.payload)
        return loan
      })

    case REMOVE_LOAN:
      return state.filter(loan => loan.id !== action.payload.id)

    case UNLOAD_DATA:
      return initial

    default:
      return state
  }
}

export default loans