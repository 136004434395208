import { ActionCreator, User } from "../../lib/types"

export const AUTH_LOGIN_START = 'AUTH/login_start'
export const AUTH_LOGIN_SUCCESS = 'AUTH/login_success'
export const AUTH_LOGIN_FAIL = 'AUTH/login_fail'

export const AUTH_LOGOUT_START = 'AUTH/logout_start'
export const AUTH_LOGOUT_SUCCESS = 'AUTH/logout_success'
export const AUTH_LOGOUT_FAIL = 'AUTH/logout_fail'

export const AUTH_GET_TOKEN_START = 'AUTH/getToken_start'
export const AUTH_GET_TOKEN_SUCCESS = 'AUTH/getToken_success'
export const AUTH_GET_TOKEN_FAIL = 'AUTH/getToken_fail'

export const AUTH_CALLBACK = 'AUTH/callback'

type authPayload = { user: User, token: string }

export const authLoginStart: ActionCreator = (payload) => ({
  type: AUTH_LOGIN_START,
  payload
})

export const authLoginSuccess: ActionCreator<authPayload> = (payload) => ({
  type: AUTH_LOGIN_SUCCESS,
  payload
})

export const authLoginFail: ActionCreator = (payload) => ({
  type: AUTH_LOGIN_FAIL,
  payload
})

export const authLogoutStart: ActionCreator = (payload) => ({
  type: AUTH_LOGOUT_START,
  payload
})

export const authLogoutSuccess: ActionCreator<authPayload> = (payload) => ({
  type: AUTH_LOGOUT_SUCCESS,
  payload
})

export const authLogoutFail: ActionCreator = (payload) => ({
  type: AUTH_LOGOUT_FAIL,
  payload
})

export const authGetTokenStart: ActionCreator<{ authType: "signup" | "login" } | void> = (payload) => ({
  type: AUTH_GET_TOKEN_START,
  payload
})

export const authGetTokenSuccess: ActionCreator<authPayload> = (payload) => ({
  type: AUTH_GET_TOKEN_SUCCESS,
  payload: payload
})

export const authGetTokenFail: ActionCreator<{ authType: "signup" | "login" } | void> = (payload) => ({
  type: AUTH_GET_TOKEN_FAIL,
  payload
})

export const authCallback: ActionCreator<{ redirectTo: string }> = (payload) => ({
  type: AUTH_CALLBACK,
  payload
})

