import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createRootReducer from './reducers'
import rootSaga from '../sagas'
import { TypedUseSelectorHook, useSelector } from 'react-redux'

export const history = createBrowserHistory()

const sagaLogger = store => next => action => {
  next(action)
  console.log(action)
}
const sagaMiddleware = createSagaMiddleware()
const store = createStore(
  createRootReducer(history),
  compose(
    applyMiddleware(
      sagaMiddleware,
      sagaLogger,
      routerMiddleware(history)
    )
  )
)

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store