import { call, put, select, take, delay } from "redux-saga/effects";
import { updateAuthHeader, getData, syncData, resetData } from "../services/db";
import { createHousehold } from "../services/household";
import { setAssets } from "../store/actions/assets";
import { AUTH_GET_TOKEN_SUCCESS } from "../store/actions/auth";
import { authGetTokenStart } from "../store/actions/auth";
import { getDataRequest, getDataFailed, getDataSuccess, resetDataFailed, resetDataSuccess, updateDataFailed, updateDataRequest, updateDataSuccess } from "../store/actions/data";
import { setEffects } from "../store/actions/effects";
import { setExpenses } from "../store/actions/expenses";
import { setGoals } from "../store/actions/goals";
import { setHousehold } from "../store/actions/household";
import { setLoans } from "../store/actions/loans";
import { setMembers } from "../store/actions/members";
import { setRelocations } from "../store/actions/relocations";
import { push } from "connected-react-router";
import { setDebts } from "../store/actions/debts";
import { setIncomes } from "../store/actions/incomes";

/**
 flow would be
 get/sync action
 trigger auth
 wait for auth to return token
 update apollo client config with new token
 make requests with updated apollo client
 */

export const selectHouseholdState = state => {
  const { household, members, expenses, incomes, goals, effects, loans, assets, debts, relocations } = state
  return { household, members, expenses, incomes, goals, effects, loans, assets, debts, relocations }
}

export function* get(action) {
  yield put(authGetTokenStart())
  const authSuccessAction = yield take(AUTH_GET_TOKEN_SUCCESS)
  try {
    yield call(updateAuthHeader, authSuccessAction?.payload?.token)
    // const householdId = authSuccessAction?.payload?.user?.["https://oursteward.com/household_id"]
    const data = action.payload ? yield call(getData, action.payload) : {}
    const household = yield call(createHousehold, (data?.household || { id: action.payload }))
    yield put(setHousehold(household))
    yield put(setMembers(data?.members || []))
    yield put(setIncomes(data?.incomes || []))
    yield put(setExpenses(data?.expenses || []))
    yield put(setAssets(data?.assets || []))
    yield put(setDebts(data?.debts || []))
    yield put(setGoals(data?.goals || []))
    yield put(setEffects(data?.effects || []))
    yield put(setLoans(data?.loans || []))
    yield put(setRelocations(data?.relocations || []))
    yield put(getDataSuccess(data))
  } catch (error) {
    yield call(console.log, error)
    yield put(getDataFailed())
  }
}

export function* changed() {
  yield delay(2000);
  yield put(updateDataRequest());
}

export function* sync() {
  yield delay(100)
  yield put(authGetTokenStart())
  const authSuccessAction = yield take(AUTH_GET_TOKEN_SUCCESS)
  try {
    yield call(updateAuthHeader, authSuccessAction?.payload?.token)
    const data = yield select(selectHouseholdState)
    const synced = yield call(syncData, data)
    if (synced) {
      yield put(updateDataSuccess())
    } else {
      yield put(updateDataFailed())
    }
  } catch (error) {
    yield call(console.log, error)
    yield put(updateDataFailed())
  }
}

export function* reset() {
  yield delay(100)
  yield put(authGetTokenStart())
  const authSuccessAction = yield take(AUTH_GET_TOKEN_SUCCESS)
  const data = yield select(selectHouseholdState)
  try {
    yield call(updateAuthHeader, authSuccessAction?.payload?.token)
    yield call(resetData, data.household.id)
    yield put(resetDataSuccess())
    yield put(push('/'))
    yield put(getDataRequest(data.household.id))
  } catch (error) {
    yield put(resetDataFailed())
  }
}