import { availableYear } from "../../../utils/config"
import { RELATIONSHIP_STATUS_TYPES } from "../../../constants";
import { stateStandardDeductionConfig } from "../types";

const stateStandardDeduction = (stateStandardDeductionData: stateStandardDeductionConfig) =>
  (year: number, relationshipStatus: keyof typeof RELATIONSHIP_STATUS_TYPES, state: string): number => {
    const configYear = availableYear(year, Object.keys(stateStandardDeductionData))
    return stateStandardDeductionData[configYear][relationshipStatus].find(item =>
      item.state === state)?.amount || 0
  }

export default stateStandardDeduction