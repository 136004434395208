import { Relocation } from "../../lib/modules/timeline/types"
import { ActionCreator } from "../../lib/types"

export const SET_RELOCATIONS = "RELOCATIONS/setRelocations"
export const ADD_RELOCATION = "RELOCATIONS/addRelocation"
export const UPDATE_RELOCATION = "RELOCATIONS/updateRelocation"
export const REMOVE_RELOCATION = "RELOCATIONS/removeRelocation"


export const setRelocations: ActionCreator<{
  [K in keyof Relocation]: Relocation[K]
}[]> = (payload) => {
  return {
    type: SET_RELOCATIONS,
    payload
  }
}
export const addRelocation: ActionCreator<{
  [K in keyof Relocation]: Relocation[K]
}> = (payload) => {
  return {
    type: ADD_RELOCATION,
    payload
  }
}

export const updateRelocation: ActionCreator<{
  [K in keyof Relocation]: Relocation[K]
}> = (payload) => {
  return {
    type: UPDATE_RELOCATION,
    payload
  }
}

export const removeRelocation: ActionCreator<{
  id: string
}> = (payload) => {
  return {
    type: REMOVE_RELOCATION,
    payload
  }
}