import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import styles from "./GoalEffect.module.css"

const GoalEffect = (props: {
  title: string
  icon?: IconName
  open?: boolean
  children: React.ReactNode
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(() => setOpen(props.open || false), [props.open])

  return <div className={[styles.container, (open ? styles.open : "")].join(" ")}>
    <div className="d-flex justify-content-between align-items-center clickable" onClick={() => setOpen(!open)}>
      <div className="d-flex gap-2 align-items-center">
        {props.icon && <FontAwesomeIcon icon={["fas", props.icon]} />}
        <div className="fw-bolder">
          {props.title}
        </div>
      </div>
      <FontAwesomeIcon icon={["fas", "chevron-up"]} className={styles.chevron} />
    </div>
    <div className={styles.editor} ref={editorRef} style={{ maxHeight: open ? (editorRef.current?.scrollHeight || 0) + 'px' : '0px' }}>
      <div className="pt-3 px-3">
        {props.children}
      </div>
    </div>
  </div>
}

export default GoalEffect