import {
  GET_DATA_REQUEST,
  GET_DATA_SUCCESS,
  GET_DATA_FAILED,
  UPDATE_DATA_REQUEST,
  UPDATE_DATA_SUCCESS,
  UPDATE_DATA_FAILED,
  DATA_CHANGED, RESET_DATA_REQUEST, RESET_DATA_SUCCESS, RESET_DATA_FAILED, UNLOAD_DATA
} from "../actions/data"

const initial: any = {
  loading: false,
  ready: false,
  syncing: false,
  synced: false,
  error: false,
  data: null
}

const auth = (state = initial, action) => {
  switch (action.type) {
    case GET_DATA_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: false
      })
    case GET_DATA_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        ready: true,
        synced: true,
        data: action.payload
      })
    case GET_DATA_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: true
      })
    case DATA_CHANGED:
      return Object.assign({}, state, {
        loading: false,
        error: false
      })
    case UPDATE_DATA_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: false
      })
    case UPDATE_DATA_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        ready: true,
        synced: true,
        data: action.payload
      })
    case UPDATE_DATA_FAILED:
      return Object.assign({}, state, {
        synced: false,
        loading: false,
        error: true
      })
    case RESET_DATA_REQUEST:
      return Object.assign({}, state, {
        ready: false,
        loading: true,
        error: false
      })
    case RESET_DATA_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        ready: true,
        synced: true,
        data: null
      })
    case RESET_DATA_FAILED:
      return Object.assign({}, state, {
        ready: true,
        synced: false,
        loading: false,
        error: true
      })

    case UNLOAD_DATA:
      return initial

    default:
      return state
  }
}

export default auth