import styles from './ErrorCard.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

const ErrorCard = (
  props:
    {
      title?: string,
      children: React.ReactNode,
      cta?: string,
      action?: any
    }
) => {

  return (
    <div className={styles.container}>
      {props.title && (
        <div className='d-flex align-items-center text-red subtitle-2 mb-3'>
          <div className='px-1'>
            <FontAwesomeIcon icon={['fas', 'triangle-exclamation']} />
          </div>
          <div>{props.title}</div>
        </div>
      )}
      {props.children}
      {(props.cta && props.action) && (
        <div className='mt-3 w-100'>
          <Button
            size='sm'
            variant='light'
            className='w-100 text-black caption-bold'
            onClick={props.action}
          >
            {props.cta}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ErrorCard