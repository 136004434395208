import SidebarPanelTitle from "./SidebarPanelTitle";
import { push } from "connected-react-router";
import { formatAmount } from "../../lib/utils/number";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SidebarPanel from "./SidebarPanel";
import { useMemo } from "react";
import { incomeSum } from "../../lib/utils/income";
import { expenseSum } from "../../lib/utils/expense";
import useTax from "../../hooks/useTax";
import { useDispatch } from "react-redux";
import { Expense, Household, Income, Member } from "../../lib/types";
import useEmptyState from "../../hooks/useEmptyState";

const HouseholdDetails = (
  {
    household,
    members,
    expenses,
    incomes,
    className
  }: {
    household: Household,
    members: Member[],
    expenses: Expense[],
    incomes: Income[],
    className?: string
  }
) => {

  const dispatch = useDispatch()

  const tax = useTax()

  const totalIncome = useMemo(() => {
    return incomeSum(incomes)
  }, [incomes])

  const _expenses = useMemo(() => {
    return expenseSum([...expenses]) + (tax?.total || 0)
  }, [expenses, tax])

  const savings = useMemo(() => {
    return totalIncome - _expenses
  }, [totalIncome, _expenses])

  const emptyState = useEmptyState({ members, household, expenses })

  if (emptyState?.incomes && emptyState?.expenses) return <></>

  return (
    <div className={className || ""}>
      <SidebarPanel>
        <SidebarPanelTitle>
          <div className='d-flex align-items-center justify-content-between body-small-bold'>
            <div>Current income and expenses</div>
            <div className='text-cta clickable' onClick={() => dispatch(push("/household"))}>Edit</div>
          </div>
        </SidebarPanelTitle>
        <div className='p-3'>
          <div className='mb-3'>
            <div className='caption-bold text-gray-60'>Annual Savings Potential</div>
            <div className='body-bold'>
              {formatAmount(savings, { short: true })}
              <FontAwesomeIcon className='ms-1 text-green' icon={["fas", 'up']} size='xs' />
            </div>
          </div>
          <div className='d-flex'>
            <div className='w-50'>
              <div className='caption-bold text-gray-60'>Annual Income</div>
              <div className='body-bold'>
                {formatAmount(totalIncome, { short: true })}
                <FontAwesomeIcon className='ms-1 text-green' icon={["fas", 'up']} size='xs' />
              </div>
            </div>
            <div className='w-50'>
              <div className='caption-bold text-gray-60'>Annual Tax + Expenses</div>
              <div className='body-bold'>
                {formatAmount(_expenses, { short: true })}
                <FontAwesomeIcon className='ms-1 text-red' icon={["fas", 'down']} size='xs' />
              </div>
            </div>
          </div>
        </div>
      </SidebarPanel>
    </div>
  )
}

export default HouseholdDetails