import Root from './pages/Root';
import store, { history } from './store';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
library.add(far, fas)

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Root />
        </ConnectedRouter>
      </Provider>
    </div>
  );
}

export default App;
