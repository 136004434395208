import { ActionCreator, Debt } from "../../lib/types"

export const SET_DEBTS = "DEBTS/setDebts"
export const ADD_DEBT = "DEBTS/addDebt"
export const UPDATE_DEBT = "DEBTS/updateDebt"
export const REMOVE_DEBT = "DEBTS/removeDebt"

export const setDebts: ActionCreator<{
  [K in keyof Debt]: Debt[K]
}[]> = (payload) => {
  return {
    type: SET_DEBTS,
    payload
  }
}
export const addDebt: ActionCreator<{
  [K in keyof Debt]: Debt[K]
}> = (payload) => {
  return {
    type: ADD_DEBT,
    payload
  }
}

export const updateDebt: ActionCreator<{
  [K in keyof Debt]: Debt[K]
}> = (payload) => {
  return {
    type: UPDATE_DEBT,
    payload
  }
}

export const removeDebt: ActionCreator<{
  id: string
}> = (payload) => {
  return {
    type: REMOVE_DEBT,
    payload
  }
}