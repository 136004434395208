// local storage for now
// TODO add apollo graphql
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  gql,
  ApolloQueryResult
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import awsconfig from '../aws-exports';
import { getHousehold } from "../graphql/queries";
import { GetHouseholdQuery } from "../graphql/API";
import { createHousehold, deleteHousehold, updateHousehold, createMember, updateMember, deleteMember, createIncome, updateIncome, deleteIncome, createAsset, updateAsset, deleteAsset, createDebt, updateDebt, deleteDebt, createExpense, updateExpense, deleteExpense, createEffect, createGoal, createLoanEffect, createRelocationEffect, deleteEffect, deleteGoal, deleteLoanEffect, deleteRelocationEffect, updateEffect, updateGoal, updateLoanEffect, updateRelocationEffect } from "../graphql/mutations";
import { MEMBER_TYPES } from "../lib/constants";
import { Asset, Debt, Expense, Household, Income, Member } from "../lib/types";
import { Effect, Goal, Loan, Relocation } from "../lib/modules/timeline/types";

export type Data = {
  household: Household,
  members: Member[],
  expenses: Expense[],
  incomes: Income[],
  debts: Debt[],
  goals: Goal[],
  assets: Asset[],
  effects: Effect[],
  loans: Loan[],
  relocations: Relocation[]
}

const mutations = {
  household: [createHousehold, updateHousehold, deleteHousehold],
  members: [createMember, updateMember, deleteMember],
  incomes: [createIncome, updateIncome, deleteIncome],
  assets: [createAsset, updateAsset, deleteAsset],
  debts: [createDebt, updateDebt, deleteDebt],
  expenses: [createExpense, updateExpense, deleteExpense],
  goals: [createGoal, updateGoal, deleteGoal],
  effects: [createEffect, updateEffect, deleteEffect],
  loans: [createLoanEffect, updateLoanEffect, deleteLoanEffect],
  relocations: [createRelocationEffect, updateRelocationEffect, deleteRelocationEffect],
}

const IGNORE_FIELDS = ["createdAt", "__typename", "updatedAt"]

const httpLink = new HttpLink({ uri: awsconfig.aws_appsync_graphqlEndpoint });


let client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export const updateAuthHeader = (token) => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token || null,
      },
    };
  });
  client.setLink(authLink.concat(httpLink));
}

function shallowEqual(object1: Object, object2: Object, ignore: string[] = []) {
  const keys1 = Object.keys(object1).filter(key => !ignore.includes(key))
  const keys2 = Object.keys(object2).filter(key => !ignore.includes(key))
  if (keys1.length !== keys2.length) return false
  for (let key of keys1) {
    if (object1[key] !== object2[key]) return false
  }
  return true;
}

const compareCollections = (original: { id: string }[], next: { id: string }[]): { created: Object[], deleted: Object[], updated: Object[] } => {
  const _original = original.reduce((result, item) => Object.assign({}, result, { [item.id]: item }), {})
  const _next = next.reduce((result, item) => Object.assign({}, result, { [item.id]: item }), {})
  const created = next.filter(item => !_original[item.id])
  const deleted = original.filter(item => !_next[item.id])
  const updated = next.filter(item => {
    const _item = _original[item.id]
    if (!_item) return false
    return !shallowEqual(item, _item, IGNORE_FIELDS)
  })
  return { created, deleted, updated }
}


const createCollectionMutations = (collection: string, householdId: string, differences: { created: Object[], deleted: Object[], updated: Object[] }) => {
  const _mutations: any = []
  const [createMutation, updateMutation, deleteMutation] = mutations[collection]
  differences?.created?.forEach(item => {
    const input = Object.keys(item)
      .filter(key => !IGNORE_FIELDS.includes(key))
      .reduce((result, key) => Object.assign({}, result, { [key]: item[key] }), collection === "household" ? {} : { householdId })
    _mutations.push({ mutation: gql(createMutation), variables: { input } })
  })
  differences?.updated?.forEach(item => {
    const input = Object.keys(item)
      .filter(key => !IGNORE_FIELDS.includes(key))
      .reduce((result, key) => Object.assign({}, result, { [key]: item[key] }), {})
    _mutations.push({ mutation: gql(updateMutation), variables: { input } })
  })
  differences?.deleted?.forEach(item => {
    const input = Object.keys(item)
      .filter(key => key === "id")
      .reduce((result, key) => Object.assign({}, result, { [key]: item[key] }), {})
    _mutations.push({ mutation: gql(deleteMutation), variables: { input } })
  })
  return _mutations
}

const checkDuplicates = (originalData: Data, nextData: Data): Data => {
  const primaryMembers = [...originalData.members, ...nextData.members].filter(member => member.type === MEMBER_TYPES.PRIMARY)
  if (primaryMembers.length > 2) {
    nextData.members = nextData.members.filter(member => member.type !== MEMBER_TYPES.PRIMARY)
    nextData.members.push(primaryMembers[0])
  }
  return nextData
}

export const syncData = async (nextData: Data): Promise<Boolean> => {
  const householdId = nextData.household.id
  const prevData = await getData(householdId)
  const filteredNextData = checkDuplicates(prevData, nextData)
  const singles = ["household"]
  const collections = ["members", "expenses", "incomes", "debts", "goals", "assets", "effects", "loans", "relocations"]
  const collectionDifferences = collections.reduce((result, key) => {
    result[key] = compareCollections(prevData?.[key] || [], filteredNextData?.[key] || [])
    return result
  }, {})

  const singlesDifferences = singles.reduce((result, key) => {
    result[key] = compareCollections(prevData?.[key] ? [prevData[key]] : [], filteredNextData?.[key] ? [filteredNextData[key]] : [])
    return result
  }, {})

  const differences = Object.assign({}, collectionDifferences, singlesDifferences)
  console.log({ differences })

  const allMutations = Object.keys(differences).reduce((result: any[], key) =>
    [...result, ...createCollectionMutations(key, householdId, differences[key])], [])

  const results = await Promise.all(allMutations.map(async (mutation) => await client.mutate(mutation)))
  return results.filter(result => result.data).length === allMutations.length
}


const sortCollection = (collection: { createdAt: string }[]) => {
  return [...collection].sort((a, b) => a?.createdAt > b?.createdAt ? 1 : -1)
}

export const getData = async (householdId: string): Promise<Data> => {
  const params = { query: gql(getHousehold), variables: { id: householdId } }
  const query = await client.query({ ...params, fetchPolicy: 'no-cache' }) as ApolloQueryResult<GetHouseholdQuery>

  const data = query?.data?.getHousehold

  const household = data?.id ?
    ["id", "state", "city", "year"].reduce((result, field) => Object.assign({}, result, { [field]: data?.[field] || null }), {})
    : null

  const extract: { [K: string]: keyof Data } = {
    "members": "members",
    "incomes": "incomes",
    "expenses": "expenses",
    "debts": "debts",
    "goals": "goals",
    "assets": "assets",
    "effects": "effects",
    "loanEffects": "loans",
    "relocationEffects": "relocations",
  }

  const out = Object.keys(extract).reduce((result, field) =>
    Object.assign({}, result, { [extract[field]]: sortCollection(data?.[field]?.items || []) }), { household })

  return out as Data
}


export const resetData = async (householdId: string) => {
  const nextData: Data = {
    household: { id: householdId, state: "", city: "", year: 0 },
    members: [],
    expenses: [],
    incomes: [],
    debts: [],
    goals: [],
    assets: [],
    effects: [],
    loans: [],
    relocations: []
  }
  console.log('deleting data')
  const result = await syncData(nextData)
  console.log({ result })
  return result
}