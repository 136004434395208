import effectCategories from "../../lib/config/effectCategories"
import { EFFECT_CATEGORIES } from "../../lib/constants"
import Suggestions from "../Suggestions"

const EffectTemplates = (props: {
  onClick: (any) => any
}) => {

  const effectCategoryOrder = [
    EFFECT_CATEGORIES.OTHER_INCOME_CHANGE,
    EFFECT_CATEGORIES.OTHER_EXPENSE_CHANGE,
    EFFECT_CATEGORIES.ONE_TIME_INCOME,
    EFFECT_CATEGORIES.PRIVATE_SCHOOL,
    EFFECT_CATEGORIES.SELLING_PROPERTY,
    EFFECT_CATEGORIES.CHILD_CARE,
    EFFECT_CATEGORIES.PARENT_CARE,
    EFFECT_CATEGORIES.RELOCATION,
  ]

  return <Suggestions onClick={props.onClick} suggestions={effectCategoryOrder.map(category => ({
    title: effectCategories[category].title,
    value: category,
    icon: effectCategories[category].icon,
  }))} />
}

export default EffectTemplates