import { Asset } from "../../lib/types"
import { ADD_ASSET, REMOVE_ASSET, SET_ASSETS, UPDATE_ASSET } from "../actions/assets"
import { UNLOAD_DATA } from "../actions/data"

const initial: Asset[] = []

const assets = (state = initial, action) => {
  switch (action.type) {
    case SET_ASSETS:
      return action.payload || []

    case ADD_ASSET:
      const newState = state.filter(asset => asset.id !== action.payload.id)
      newState.push(action.payload)
      return newState

    case UPDATE_ASSET:
      return state.map(asset => {
        if (asset.id === action.payload.id) asset = Object.assign({}, asset, action.payload)
        return asset
      })

    case REMOVE_ASSET:
      return state.filter(asset => asset.id !== action.payload.id)

    case UNLOAD_DATA: 
      return initial
    
    default:
      return state
  }
}

export default assets