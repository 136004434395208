import { useEffect } from "react"

const useCaptureOutsideClick = (props: {
  listen: boolean
  ref: React.RefObject<HTMLDivElement>,
  onClick: () => any
}) => {
  useEffect(() => {
    const captureClick = (event) => {
      if (props.ref.current && !props.ref.current.contains(event.target)) {
        props.onClick()
      }
    }
    if (props.listen) document.addEventListener("click", captureClick)
    return () => document.removeEventListener("click", captureClick)
  }, [props.onClick, props.ref])
}

export default useCaptureOutsideClick