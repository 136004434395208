import { availableYear } from "../../../utils/config"
import { RELATIONSHIP_STATUS_TYPES } from "../../../constants";
import { federalIncomeTaxConfig } from "../types";

const federalIncomeTax = (federalIncomeTaxData: federalIncomeTaxConfig) =>
  (taxableAmount: number, year: number, relationshipStatus: keyof typeof RELATIONSHIP_STATUS_TYPES): number => {
    const configYear = availableYear(year, Object.keys(federalIncomeTaxData))
    const config = federalIncomeTaxData[configYear][relationshipStatus]
    const tax = config.find(item => item.min <= taxableAmount && (!item.max || item.max >= taxableAmount))
    if (!tax) return 0
    const result = (taxableAmount - tax.min) / 100 * tax.bracket
    return tax.floor ? tax.floor + result : result
  }

export default federalIncomeTax