import { FREQUENCY_TYPES } from "../constants"

type Entity = {
  amount: number
  frequency?: keyof typeof FREQUENCY_TYPES
}

export const expenseSum = (expenses: Entity[]): number => {
  return expenses.reduce((total, entity) => {
    return total + (entity.frequency === FREQUENCY_TYPES.MONTHLY ? entity.amount * 12 : entity.amount)
  }, 0)
}
