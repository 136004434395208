import { ASSET_CATEGORIES, ASSET_GROUPS, CONTRIBUTION_TYPES, INCOME_TYPES, EFFECT_OPERATIONS, FREQUENCY_TYPES, INCOME_INCREASE_RATE, INFLATION_RATE, RETIREMENT } from "../../constants"
import { expenseSum } from "../../utils/expense"
import { findPrincipal } from "../../utils/interest"
import { AllocationDataItem } from "../investment/types"
import { GoalAllocations, GoalYearItem, FinancesYearItem, TimelineArgs, TimelineDependencies, TimelineResult, Effect } from "./types"
import { convertLoansToEffects } from "./utils"

// TODO check if this is correct 
// const retirementAccounts = [
//   CONTRIBUTION_TYPES.traditional401k,
//   CONTRIBUTION_TYPES.roth401k,
//   CONTRIBUTION_TYPES.traditionalIRA,
//   CONTRIBUTION_TYPES.rothIRA,
//   CONTRIBUTION_TYPES.HSA
// ]

const retirementAccounts = [
  ASSET_CATEGORIES.TRADITIONAL_401K,
  ASSET_CATEGORIES.ROTH_401K,
  ASSET_CATEGORIES.TRADITIONAL_IRA,
  ASSET_CATEGORIES.ROTH_IRA,
  ASSET_CATEGORIES.HSA
]

const timeline = (dependencies: TimelineDependencies) => (args: TimelineArgs): TimelineResult => {
  // TODO define limit that makes sense
  const yearLimit = 99
  const yearsArray = Array(yearLimit).fill(0).map((_, index) => args.year + index)
  const sortedGoals = args.goals.sort((a, b) => a.year - b.year)
  const totalIncome = Object.values(args.incomes).reduce((sum, incomeArray) =>
    sum + incomeArray.reduce((_sum, income) => _sum + income, 0), 0)
  if (!totalIncome) return { finances: {}, goals: {} }
  const investmentAssetsTotal = args.assets.filter(asset => ASSET_GROUPS.INVESTMENT.includes(asset.category as any)).reduce((sum, item) => sum + item.value, 0)
  const allEffects: Effect[] = [...convertLoansToEffects(args.loans, INFLATION_RATE), ...args.effects];

  const { projectionForSavings } = yearsArray
    .reduce((result: { projectionForSavings: { [year: string]: FinancesYearItem }, lastIncomes: typeof args.incomes }, year, index) => {
      const prev = result.projectionForSavings[year - 1]
      if (index > 0 && (!prev || prev.savings < 0)) return result
      const inflow = Object.values(result.lastIncomes).reduce((sum, incomeArray) =>
        sum + incomeArray.reduce((_sum, income) => _sum + income, 0), 0)
      const relocation = args.relocations.filter(item => item.year <= year).sort((a, b) => a.year - b.year)?.[0]
      const activeExpenses = args.expenses.filter(expense => !expense.years || (args.year + expense.years >= year))
      const totalExpensesThisYear = expenseSum(activeExpenses)
      const activeEffects = allEffects.filter(effect =>
        year >= effect.year && (!effect.activeYears || (effect.year + (effect.activeYears || 0)) > year))
      const incomeEffectAmount = activeEffects.reduce((sum, effect) =>
        sum + ((effect.subject === "income" ? (effect.amount * (effect.operation === EFFECT_OPERATIONS.increase ? 1 : -1)) : 0)), 0)
      const expenseEffectAmount = activeEffects.reduce((sum, effect) =>
        sum + ((effect.subject === "expense" ? (effect.amount * (effect.operation === EFFECT_OPERATIONS.increase ? 1 : -1)) : 0)), 0)

      const effectIncomes = activeEffects
        .filter(effect => effect.subject === "income" && effect.operation === EFFECT_OPERATIONS.increase && effect.amount > 0)
        .map(effect => effect.amount)
      const allOtherIncomes = [...(result.lastIncomes[INCOME_TYPES.OTHER_ANNUAL_INCOME] || []), ...effectIncomes]
      const taxes = dependencies.taxCalculator({
        year: year,
        relationshipStatus: args.relationshipStatus,
        city: relocation?.city || args.location.city,
        state: relocation?.state || args.location.state,
        incomes: Object.assign({}, result.lastIncomes, { otherAnnualIncomes: allOtherIncomes }),
        contributions: {}
      })
      const savings = inflow + incomeEffectAmount - taxes.total - totalExpensesThisYear - expenseEffectAmount
      result.projectionForSavings[year] = {
        taxes,
        inflow: inflow + incomeEffectAmount,
        outflow: taxes.total + totalExpensesThisYear + expenseEffectAmount,
        savings,
        contributions: {}
      }
      result.lastIncomes = Object.keys(result.lastIncomes).reduce((_incomes, key) => {
        _incomes[key] = result.lastIncomes[key].map(income => income + (income * INCOME_INCREASE_RATE))
        return _incomes
      }, {})
      return result
    }, { projectionForSavings: {}, lastIncomes: args.incomes })

  const projectionForAllocations = Object.keys(projectionForSavings)
    .reduce((result: { [year: string]: { [goalId: string]: AllocationDataItem } }, year) => {
      result[year] = dependencies.investmentSelector({ goals: args.goals, year: parseInt(year) })
      return result
    }, {})

  const projectionForGoals = sortedGoals.reduce((result: {
    [goalId: string]: {
      savingsTarget: number
      reached: boolean
      startingBalance: number
      allocations: { [year: string]: GoalYearItem }
    }
  }, goal) => {
    const savingsTarget = goal.amount * (goal?.downPaymentPercentage || 1) + ((goal?.closingCostsPercentage || 0) * goal.amount)
    const years = goal.year - args.year + 1
    if (years < 1) return result
    const allocationsForGoal = Array(years)
      .fill(0)
      .map((_, index) => args.year + index)
      .reduce((all: GoalAllocations, year) => {
        const remainingYears = goal.year - year + 1
        const allocated: number = Object.values(all).reduce((sum, item) =>
          sum + (sum * item.returnRate) + item.allocate, 0)
        const returnRate = projectionForAllocations?.[year]?.[goal.id]?.avgAnnualReturn || 0
        // const adjustedReturnRate = returnRate
        const adjustedReturnRate = returnRate ? returnRate - INFLATION_RATE : 0
        const allocation = Math.max(0, (savingsTarget - allocated) / remainingYears)
        // TODO subtract tax advantage contributions as well
        const contributions = projectionForSavings?.[year]?.contributions || {}
        const lockedAmount = Object.keys(contributions).reduce((totalLocked, type) => {
          if (goal?.contributionTypes?.includes(type as keyof typeof CONTRIBUTION_TYPES)) return totalLocked
          const totalContribution = contributions[type].reduce((sum, item) => sum + item, 0)
          return totalLocked + totalContribution
        }, 0)
        const available = (projectionForSavings?.[year]?.savings || 0) - lockedAmount - Object.values(result).map(item =>
          item?.allocations?.[year]?.allocate || 0).reduce((sum, item) => sum + item, 0)
        all[year] = {
          allocate: Math.min(allocation, available),
          returnRate: adjustedReturnRate
        }
        return all
      }, {})
    // TODO monthly compound interest for base salary?
    const totalFunded = Object.values(allocationsForGoal).reduce((sum, item) =>
      sum + (sum * item.returnRate) + item.allocate, 0)

    const short = Math.max(0, savingsTarget - totalFunded)
    const requiredStartingBalance = !short ? 0 : findPrincipal(short, goal.year - args.year, projectionForAllocations[args.year][goal.id]?.avgAnnualReturn - INFLATION_RATE, 1)

    const usedStartingBalance = Object.values(result).map(item => item.startingBalance).reduce((sum, item) => sum + item, 0)
    const availableBalance = investmentAssetsTotal - usedStartingBalance
    const startingBalance = Math.min(availableBalance, requiredStartingBalance)

    // TODO define threshold
    const reachableGoal = totalFunded >= savingsTarget || requiredStartingBalance === startingBalance
    result[goal.id] = {
      savingsTarget,
      reached: reachableGoal,
      startingBalance: reachableGoal ? startingBalance : 0,
      allocations: reachableGoal ? allocationsForGoal : {}
    }

    return result
  }, {})

  // TODO define correct retirement savings target 
  const baseExpensesTotal = expenseSum(args.expenses.filter(expense => !expense.years))
  const adjustedExpenses = allEffects.reduce((expenses, effect) => {
    if (effect.subject === "expense" && !effect.activeYears) expenses += (effect.amount * (effect.operation === EFFECT_OPERATIONS.increase ? 1 : -1))
    return expenses
  }, baseExpensesTotal)

  const retirementSavingsTarget = adjustedExpenses * 33.3
  // the idea is that you can safely withdraw 3% of investments per year at retirement. so that 3% should cover your expenses
  const lastGoalYear = sortedGoals?.[sortedGoals.length - 1]?.year || args.year

  const usedStartingBalance = (Object.values(projectionForGoals) || []).map(item => item.startingBalance).reduce((sum, item) => sum + item, 0)
  const availableBalance = investmentAssetsTotal - usedStartingBalance
  const retirementSavingsTotal = args.assets
    .filter(asset => retirementAccounts.includes(asset.category as any))
    .reduce((sum, item) => sum + item.value, 0)

  const surplusFromGoals = Object.keys(projectionForGoals).reduce((result, goalId) => {
    const goal = args.goals.find(item => item.id === goalId)
    if (!goal || !projectionForGoals[goalId]?.reached) return result
    const savingsTarget = goal.amount * (goal?.downPaymentPercentage || 1)
    const totalAllocated = Object.values(projectionForGoals[goalId].allocations).reduce((sum, item, index, all) => {
      const prev = all[index - 1]
      return sum + (sum * ((item?.returnRate || prev?.returnRate) || 0)) + item.allocate
    }, 0)
    // TODO check how this can be negative ????
    result[goal.year + 1] = Math.max(0, (totalAllocated + projectionForGoals[goalId].startingBalance) - savingsTarget)
    return result
  }, {})


  const projectionForRetirement = Object.keys(projectionForSavings)
    .reduce((result: { savingsTarget: number, reached: boolean, startingMix: AllocationDataItem, startingBalance: number, allocations: { [year: string]: GoalYearItem } }, year) => {
      const allocated = Object.values(result.allocations).reduce((sum, item) =>
        sum + (sum * item.returnRate) + item.allocate, result.startingBalance)
      const remainingEffectAmount = allEffects
        .filter(effect => effect.activeYears && effect.year + effect.activeYears > parseInt(year))
        .reduce((sum, effect) => {
          return sum + effect.amount * (effect.year + (effect?.activeYears || 0) - parseInt(year))
        }, 0)
      const remainingExpenseAmount = args.expenses
        .filter(expense => expense.years && (args.year + expense.years >= parseInt(year)))
        .reduce((sum, expense) => {
          const annualAmount = expense.amount * (expense.frequency === FREQUENCY_TYPES.MONTHLY ? 12 : 1)
          const remainingAmount = annualAmount * (args.year + (expense?.years || 0) - parseInt(year))
          return sum + remainingAmount
        }, 0)
      const adjustedRetirementSavingsTarget = retirementSavingsTarget + remainingEffectAmount + remainingExpenseAmount
      if (allocated > adjustedRetirementSavingsTarget && lastGoalYear < parseInt(year)) {
        result.reached = true
        return result
      }
      const available = projectionForSavings[year].savings - Object.values(projectionForGoals).reduce((sum, item) =>
        item?.reached && item?.allocations?.[year] ? sum + item?.allocations?.[year].allocate : sum, 0)
      const surplus = surplusFromGoals[year] || 0
      const returnRate = result.startingMix.avgAnnualReturn
      const adjustedReturnRate = returnRate ? returnRate - INFLATION_RATE : 0
      result.allocations[year] = {
        allocate: available + surplus,
        returnRate: adjustedReturnRate
      }
      return result
    }, {
      savingsTarget: retirementSavingsTarget,
      reached: false,
      startingMix: projectionForAllocations[args.year][RETIREMENT],
      startingBalance: availableBalance + retirementSavingsTotal,
      allocations: {}
    })
  const goalsWithMixes = Object.keys(projectionForGoals).reduce((result, goalId) => {
    result[goalId] = {
      startingMix: projectionForAllocations[args.year][goalId],
      ...projectionForGoals[goalId]
    }
    return result
  }, {})

  const goalsAndRetirement = { [RETIREMENT]: projectionForRetirement, ...goalsWithMixes }
  const withSavings = Object.keys(goalsAndRetirement).reduce((result, goalId) => {
    result[goalId] = { ...goalsAndRetirement[goalId], savings: {} }
    Object.keys(goalsAndRetirement[goalId].allocations).forEach(year => {
      const prev = (result[goalId].savings[parseInt(year) - 1] || result[goalId].startingBalance)
      const prevReturnRate = result[goalId].allocations?.[parseInt(year) - 1]?.returnRate || 0
      const returnRate = result[goalId].allocations[year].returnRate || prevReturnRate
      const savings = prev + (prev * returnRate) + result[goalId].allocations[year].allocate
      result[goalId].savings[year] = savings
    })
    return result
  }, {})

  return {
    finances: projectionForSavings,
    goals: withSavings
  }
}

export default timeline