import { _529DeductionConfig } from "../types"

const _529DeductionData: _529DeductionConfig = {
  "2021": [
    { state: "Alabama", single: 5000, married: 10000, perBeneficiary: false, fullyDeductible: false },
    { state: "Alaska", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Arizona", single: 2000, married: 4000, perBeneficiary: false, fullyDeductible: false },
    { state: "Arkansas", single: 5000, married: 10000, perBeneficiary: false, fullyDeductible: false },
    { state: "California", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Colorado", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Connecticut", single: 5000, married: 10000, perBeneficiary: false, fullyDeductible: false },
    { state: "Delaware", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Florida", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Georgia", single: 4000, married: 8000, perBeneficiary: true, fullyDeductible: false },
    { state: "Hawaii", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Idaho", single: 6000, married: 12000, perBeneficiary: false, fullyDeductible: false },
    { state: "Illinois", single: 10000, married: 20000, perBeneficiary: false, fullyDeductible: false },
    { state: "Indiana", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Iowa", single: 3474, married: 6948, perBeneficiary: true, fullyDeductible: false },
    { state: "Kansas", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Kentucky", single: 3000, married: 6000, perBeneficiary: true, fullyDeductible: false },
    { state: "Louisiana", single: 2400, married: 4800, perBeneficiary: true, fullyDeductible: false },
    { state: "Maine", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Maryland", single: 2500, married: 5000, perBeneficiary: true, fullyDeductible: false },
    { state: "Massachusetts", single: 1000, married: 2000, perBeneficiary: false, fullyDeductible: false },
    { state: "Michigan", single: 5000, married: 1000, perBeneficiary: false, fullyDeductible: false },
    { state: "Minnesota", single: 1500, married: 3000, perBeneficiary: false, fullyDeductible: false },
    { state: "Mississippi", single: 10000, married: 20000, perBeneficiary: false, fullyDeductible: false },
    { state: "Missouri", single: 8000, married: 16000, perBeneficiary: false, fullyDeductible: false },
    { state: "Montana", single: 3000, married: 6000, perBeneficiary: false, fullyDeductible: false },
    { state: "Nebraska", single: 10000, married: 10000, perBeneficiary: false, fullyDeductible: false },
    { state: "Nevada", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "New Hampshire", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "New Jersey", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "New Mexico", single: 29000, married: 29000, perBeneficiary: false, fullyDeductible: true },
    { state: "New York", single: 5000, married: 10000, perBeneficiary: false, fullyDeductible: false },
    { state: "North Carolina", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "North Dakota", single: 5000, married: 10000, perBeneficiary: false, fullyDeductible: false },
    { state: "Ohio", single: 4000, married: 4000, perBeneficiary: true, fullyDeductible: false },
    { state: "Oklahoma", single: 10000, married: 20000, perBeneficiary: false, fullyDeductible: false },
    { state: "Oregon", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Pennsylvania", single: 15000, married: 30000, perBeneficiary: true, fullyDeductible: false },
    { state: "Rhode Island", single: 500, married: 1000, perBeneficiary: false, fullyDeductible: false },
    { state: "South Carolina", single: 29000, married: 0, perBeneficiary: false, fullyDeductible: true },
    { state: "South Dakota", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Tennessee", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Texas", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Utah", single: 2040, married: 4080, perBeneficiary: true, fullyDeductible: false },
    { state: "Vermont", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "Virginia", single: 4000, married: 8000, perBeneficiary: true, fullyDeductible: false },
    { state: "Washington", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "West Virginia", single: 29000, married: 0, perBeneficiary: false, fullyDeductible: true },
    { state: "Wisconsin", single: 3380, married: 6760, perBeneficiary: true, fullyDeductible: false },
    { state: "Wyoming", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "District of Columbia", single: 4000, married: 8000, perBeneficiary: false, fullyDeductible: false },
    { state: "U.S. overseas territories", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
    { state: "U.S. citizen living abroad", single: 0, married: 0, perBeneficiary: false, fullyDeductible: false },
  ]
}

export default _529DeductionData