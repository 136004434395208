import { RETIREMENT } from "../../constants"
import { InvestmentArgs, InvestmentDependencies } from "./types"
import { selectAllocation } from "./utils"

const investment = (dependencies: InvestmentDependencies) => (args: InvestmentArgs) => {
  const allocations = args.goals.reduce((result: { [goalId: string]: any }, goal) => {
    result[goal.id] = null
    const recommended = selectAllocation(dependencies.allocationData, args.year, goal.year)
    if (recommended) {
      result[goal.id] = recommended
    }
    return result
  }, {})
  allocations[RETIREMENT] = selectAllocation(dependencies.allocationData, args.year, args.year + 25)
  return allocations
}

export default investment