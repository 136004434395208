import { useEffect, useMemo, useRef, useState } from "react"
import styles from "./Timeline.module.css"
import { Col, Row } from "react-bootstrap"
import EventItem from "../../components/Timeline/EventItem"
import Group from "../../components/Timeline/Group"
import Main from "../../components/Timeline/Main"
import Sidebar from "../../components/Timeline/Sidebar"
import TimelineChart from "../../components/TimelineChart"
import useTimeline from "../../hooks/useTimeline"
import { Effect, Goal, Relocation } from "../../lib/modules/timeline/types"
import { useAppSelector } from "../../store"
import { v4 } from "uuid";
import SidebarPanel from "../../components/Timeline/SidebarPanel"
import SidebarPanelTitle from "../../components/Timeline/SidebarPanelTitle"
import GoalTemplates from "../../components/Timeline/GoalTemplates"
import EffectTemplates from "../../components/Timeline/EffectTemplates"
import { formatAmount } from "../../lib/utils/number"
import { EFFECT, EFFECT_CATEGORIES, EFFECT_OPERATIONS, GOAL, GOAL_CATEGORIES, INFLATION_RATE, RELOCATION } from "../../lib/constants"
import GoalEditor from "../../components/Timeline/GoalEditor"
import RetirementForecast from "../../components/Timeline/RetirementForecast";
import HouseholdDetails from "../../components/Timeline/HouseholdDetails";
import SavingsTargets from "../../components/Timeline/SavingsTargets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { convertLoansToEffects } from "../../lib/modules/timeline/utils"
import goalCategories from "../../lib/config/goalCategories"
import { useDispatch } from "react-redux"
import { setGoals, updateGoal } from "../../store/actions/goals"
import { setEffects } from "../../store/actions/effects"
import { setLoans } from "../../store/actions/loans"
import effectCategories from "../../lib/config/effectCategories"
import EffectEditor from "../../components/Timeline/EffectEditor"
import GoalEffects from "../../components/Timeline/GoalEffects"
import { setRelocations } from "../../store/actions/relocations"
import RelocationEditor from "../../components/Timeline/RelocationEditor"
import CTACard from "../../components/CTACard";
import { capitalize } from "../../lib/utils/text";
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll"
import { debounce } from "throttle-debounce";
import useEmptyState from "../../hooks/useEmptyState";
import { useParams } from "react-router-dom";
import { push } from "connected-react-router";
import { confirmMessages } from "../../lib/config/copy";

const Timeline = () => {
  const { data, household, members, expenses, incomes, assets, effects, loans, goals, relocations } = useAppSelector(state => ({
    data: state.data,
    household: state.household,
    members: state.members,
    expenses: state.expenses,
    incomes: state.incomes,
    assets: state.assets,
    effects: state.effects,
    relocations: state.relocations,
    loans: state.loans,
    goals: state.goals,
  }))

  const breakPoint = 768;

  const params = useParams<{ id?: string }>()

  const [selectedGoalId, setSelectedGoalId] = useState<string | null>(null)
  const [selectedEffectId, setSelectedEffectId] = useState<string | null>(null)
  const [selectedRelocationId, setSelectedRelocationId] = useState<string | null>(null)
  const [placeholder, setPlaceholder] = useState<{ year: number } | null>(null)
  const [editMode] = useState<boolean>(true)
  const [emptyItem, setEmptyItem] = useState<boolean>(false)
  const [smallScreen, setSmallScreen] = useState<boolean>(window.innerWidth < breakPoint)

  const timeline = useTimeline({ household, members, expenses, incomes, assets, effects, goals, loans, relocations })
  const dispatch = useDispatch()

  const selectedGoal = useMemo(() => {
    if (!selectedGoalId) return
    return goals.find(goal => goal.id === selectedGoalId)
  }, [selectedGoalId, goals])

  const selectedEffect = useMemo(() => {
    if (!selectedEffectId) return
    return effects.find(effect => effect.id === selectedEffectId)
  }, [selectedEffectId, effects])

  const selectedRelocation = useMemo(() => {
    if (!selectedRelocationId) return
    return relocations.find(relocation => relocation.id === selectedRelocationId)
  }, [selectedRelocationId, relocations])

  const sortedGroupItems: any = useMemo(() => {
    return [
      ...(goals.map(goal => Object.assign({}, goal, { type: GOAL }))),
      ...(effects.filter(effect => !effect.goalId || !goals.find(goal => goal.id === effect.goalId)).map(effect => Object.assign({}, effect, { type: EFFECT }))),
      ...(relocations.filter(relocation => !relocation.goalId || !goals.find(goal => goal.id === relocation.goalId)).map(relocation => Object.assign({}, relocation, { type: RELOCATION })))
    ].sort((a, b) => a.year - b.year)
  }, [goals, effects, relocations])

  const showEditor = useMemo(() => {
    return (placeholder || selectedGoalId || selectedEffectId || selectedRelocationId) ? true : false
  }, [placeholder, selectedGoalId, selectedEffectId, selectedRelocationId])

  const emptyState = useEmptyState({ members, household, expenses })

  const groups = useMemo(() => {
    if (!household) return []
    return [{
      type: 'short',
      icons: ['car', 'graduation-cap', 'house'],
      subtitle: "(0-2 years)",
      minYear: household.year,
      maxYear: household.year + 2
    }, {
      type: 'mid',
      icons: ['baby', 'house', 'car'],
      subtitle: "(2-5 years)",
      minYear: household.year + 2,
      maxYear: household.year + 5
    }, {
      type: 'long',
      icons: ['building-columns'],
      subtitle: "(5+ years)",
      minYear: household.year + 5,
      maxYear: 0,
    }]
  }, [household])

  useEffect(() => {
    const updateScreenSize = debounce(100, () => setSmallScreen(window.innerWidth < breakPoint))
    window.addEventListener("resize", updateScreenSize)
    return () => window.removeEventListener("resize", updateScreenSize)
  }, [])

  useEffect(() => {
    const goalIds = goals.map(goal => goal.id)
    const relocationIds = relocations.map(relocation => relocation.id)
    const effectIds = effects.map(effect => effect.id)
    const nextSelectedGoalId = goalIds.find(id => id === params.id) || null
    const nextSelectedEffectId = effectIds.find(id => id === params.id) || null
    const nextSelectedRelocationId = relocationIds.find(id => id === params.id) || null
    setSelectedGoalId(nextSelectedGoalId)
    setSelectedEffectId(nextSelectedEffectId)
    setSelectedRelocationId(nextSelectedRelocationId)
  }, [params.id, goals, relocations, effects])

  useDisableBodyScroll(showEditor && smallScreen)

  useEffect(() => {
    if (selectedEffectId || selectedGoalId || selectedRelocationId) {
      setPlaceholder(null)
    } else {
      setEmptyItem(false)
    }
  }, [selectedEffectId, selectedGoalId, selectedRelocationId])

  useEffect(() => {
    if (placeholder) dispatch(push('/timeline'))
  }, [placeholder, dispatch])

  useEffect(() => {
    // if goalEffect is added then goal is not empty anymore
    if (selectedGoal && emptyItem) setEmptyItem(false)
    // eslint-disable-next-line
  }, [effects, relocations, loans])

  const sidebarRef = useRef<any>(null)

  useEffect(() => {
    if (!placeholder && !selectedEffectId && !selectedGoalId) return
    sidebarRef?.current?.scrollTo({ top: 0, behavior: 'smooth' })
  }, [placeholder, selectedEffectId, selectedGoalId])

  const handleGoalChange = (updatedGoal: Goal) => {
    dispatch(updateGoal(updatedGoal))
    setEmptyItem(false)
  }

  const handleGoalRemove = (goalId: string) => {
    if (!emptyItem && !window.confirm(confirmMessages.goalRemove)) return
    const goal = goals.find(_goal => _goal.id === goalId)
    const nextGoals = goals.filter(_goal => _goal.id !== goalId)
    if (emptyItem) setPlaceholder({ year: goal?.year || household.year })
    dispatch(setGoals(nextGoals))
    const nextEffects = effects.filter(effect => effect.goalId !== goalId)
    dispatch(setEffects(nextEffects))
    const nextLoans = loans.filter(loan => loan.goalId !== goalId)
    dispatch(setLoans(nextLoans))
    const nextRelocations = relocations.filter(relocation => relocation.goalId !== goalId)
    dispatch(setRelocations(nextRelocations))
    dispatch(push('/timeline'))
  }

  const handleGoalCreate = (category: keyof typeof GOAL_CATEGORIES, year: number = 0) => {
    const goal: Goal = {
      id: v4(),
      name: "",
      amount: 0,
      year,
      category
    }
    const nextGoals = [...goals, goal].sort((a, b) => a.year - b.year)
    dispatch(setGoals(nextGoals))
    dispatch(push(`/timeline/${goal.id}`))
    setEmptyItem(true)
  }

  const handleEffectChange = (updatedEffect: Effect) => {
    const nextEffects = effects.map(effect => effect.id === updatedEffect.id ? updatedEffect : effect)
    dispatch(setEffects(nextEffects))
    setEmptyItem(false)
  }

  const handleEffectCreate = (category: keyof typeof EFFECT_CATEGORIES, year: number = 0) => {
    if (category === EFFECT_CATEGORIES.RELOCATION) {
      const relocation: Relocation = {
        id: v4(),
        name: "Tax location change",
        year,
        state: "",
        city: ""
      }
      const nextRelocations = [...relocations, relocation].sort((a, b) => a.year - b.year)
      dispatch(setRelocations(nextRelocations))
      dispatch(push(`/timeline/${relocation.id}`))
    } else {
      const effect: Effect = {
        id: v4(),
        name: effectCategories[category].title,
        amount: 0,
        activeYears: 0,
        year,
        category,
        operation: EFFECT_OPERATIONS.increase,
        subject: effectCategories[category].subject
      }
      const nextEffects = [...effects, effect].sort((a, b) => a.year - b.year)
      dispatch(setEffects(nextEffects))
      dispatch(push(`/timeline/${effect.id}`))
    }
    setEmptyItem(true)
  }

  const handleEffectRemove = (effectId: string) => {
    if (!emptyItem && !window.confirm(confirmMessages.effectRemove)) return
    const effect = effects.find(_effect => _effect.id === effectId)
    const nextEffects = effects.filter(_effect => _effect.id !== effectId)
    if (emptyItem) setPlaceholder({ year: effect?.year || household.year })
    dispatch(setEffects(nextEffects))
    dispatch(push('/timeline'))
  }

  const handleRelocationChange = (updatedRelocation: Relocation) => {
    const nextRelocations = relocations.map(relocation => relocation.id === updatedRelocation.id ? updatedRelocation : relocation)
    dispatch(setRelocations(nextRelocations))
    setEmptyItem(false)
  }

  const handleRelocationRemove = (relocationId: string) => {
    if (!emptyItem && !window.confirm(confirmMessages.relocationRemove)) return
    const relocation = relocations.find(_relocation => _relocation.id === relocationId)
    const nextRelocations = relocations.filter(_relocation => _relocation.id !== relocationId)
    if (emptyItem) setPlaceholder({ year: relocation?.year || household.year })
    dispatch(setRelocations(nextRelocations))
    dispatch(push('/timeline'))
  }

  return (
    <div>
      <Sidebar ref={sidebarRef} open={showEditor}>
        {showEditor && <>
          <SidebarPanel active={true}>
            {(placeholder && !selectedGoalId) && (<>
              <SidebarPanelTitle>
                <div className="d-flex justify-content-between align-items-center fw-bold">
                  <div className="">Create event</div>
                  <div className="text-red clickable caption" onClick={() => setPlaceholder(null)}>Cancel</div>
                </div>
              </SidebarPanelTitle>
              <div className="px-3 pt-3">
                <div className="caption text-gray-60 mb-3 fw-bolder">Savings targets</div>
                <GoalTemplates onClick={category => handleGoalCreate(category, placeholder.year)} />
              </div>
              <hr />
              <div className="px-3 pb-3">
                <div className="caption text-gray-60 mb-3 fw-bolder">Financial changes</div>
                <EffectTemplates onClick={category => handleEffectCreate(category, placeholder.year)} />
              </div>
            </>)}
            {(selectedGoalId && !placeholder) && (<>
              <SidebarPanelTitle>
                <div className="d-flex justify-content-between align-items-center fw-bold">
                  <div className="d-flex gap-3 align-items-center">
                    {selectedGoal?.category &&
                      <FontAwesomeIcon icon={["far", goalCategories[selectedGoal.category || ""].icon]} />
                    }
                    <div className="lh-sm">
                      {selectedGoal?.name || goalCategories?.[selectedGoal?.category || '']?.title || "New event"}
                    </div>
                  </div>
                  <div className="d-flex gap-3 caption">
                    <div className="text-red clickable" onClick={() => selectedGoal && handleGoalRemove(selectedGoal?.id)}>
                      {emptyItem ? "Cancel" : "Delete"}
                    </div>
                    <div className="text-cta clickable" onClick={() => dispatch(push('/timeline'))}>
                      Close
                      {/* {editMode ? "Done" : "Edit"} */}
                    </div>
                  </div>
                </div>
              </SidebarPanelTitle>
              <div className="p-3">
                <GoalEditor
                  goal={selectedGoal}
                  readOnly={!editMode}
                  onChange={handleGoalChange}
                  context={{ members, household, expenses, incomes }}
                />
                <GoalEffects
                  goal={selectedGoal}
                  readOnly={!editMode}
                  effects={effects.filter(effect => effect.goalId === selectedGoalId)}
                  loans={loans.filter(loan => loan.goalId === selectedGoalId)}
                  relocations={relocations.filter(relocation => relocation.goalId === selectedGoalId)}
                  context={{ household }}
                />
              </div>
            </>)}
            {(selectedEffectId && !placeholder) && (<>
              <SidebarPanelTitle>
                <div className="d-flex justify-content-between align-items-center fw-bold">
                  <div className="d-flex gap-3 align-items-center">
                    {selectedEffect?.category &&
                      <FontAwesomeIcon icon={["far", effectCategories[selectedEffect.category || ""].icon]} />
                    }
                    <div className="lh-sm">
                      {selectedEffect?.name || effectCategories?.[selectedEffect?.category || '']?.title || "New event"}
                    </div>
                  </div>
                  <div className="d-flex gap-3 caption">
                    <div className="text-red clickable" onClick={() => selectedEffect && handleEffectRemove(selectedEffect?.id)}>
                      {emptyItem ? "Cancel" : "Delete"}
                    </div>
                    <div className="text-cta clickable" onClick={() => dispatch(push('/timeline'))}>
                      Close
                    </div>
                  </div>
                </div>
              </SidebarPanelTitle>
              <div className="p-3">
                <EffectEditor
                  effect={selectedEffect}
                  readOnly={!editMode}
                  onChange={handleEffectChange}
                  context={{ household }}
                />
              </div>
            </>)}
            {(selectedRelocationId && !placeholder) && (<>
              <SidebarPanelTitle>
                <div className="d-flex justify-content-between align-items-center fw-bold">
                  <div className="d-flex gap-3 align-items-center">
                    <FontAwesomeIcon icon={["far", effectCategories[EFFECT_CATEGORIES.RELOCATION].icon]} />
                    <div className="lh-sm">
                      {selectedRelocation?.name || effectCategories[EFFECT_CATEGORIES.RELOCATION].title}
                    </div>
                  </div>
                  <div className="d-flex gap-3 caption">
                    <div className="text-red clickable" onClick={() => selectedRelocation && handleRelocationRemove(selectedRelocation?.id)}>
                      {emptyItem ? "Cancel" : "Delete"}
                    </div>
                    <div className="text-cta clickable" onClick={() => dispatch(push('/timeline'))}>
                      Close
                    </div>
                  </div>
                </div>
              </SidebarPanelTitle>
              <div className="p-3">
                <RelocationEditor
                  relocation={selectedRelocation}
                  readOnly={!editMode}
                  onChange={handleRelocationChange}
                  context={{ household }}
                />
              </div>
            </>)}
          </SidebarPanel>
        </>}
        <RetirementForecast
          members={members}
          timeline={timeline}
          empty={emptyState?.incomes || emptyState?.expenses}
          className="d-none d-md-block"
        />
        <HouseholdDetails
          household={household}
          members={members}
          expenses={expenses}
          incomes={incomes}
          className="d-none d-md-block"
        />
        <SavingsTargets
          timeline={timeline}
          goals={goals}
          className="d-none d-md-block"
        />
      </Sidebar>

      <Main>
        <div className="d-block d-md-none mb-3">
          <RetirementForecast
            members={members}
            timeline={timeline}
            empty={emptyState?.incomes || emptyState?.expenses}
          />
        </div>
        <div className="mb-3">
          <div className={styles.timelineWrapper}>
            <TimelineChart
              timeline={timeline}
              members={members}
              goals={goals}
              effects={[...convertLoansToEffects(loans, INFLATION_RATE), ...effects]}
              relocations={relocations.filter(relocation => !relocation.goalId)}
              loading={!data.ready}
              onClick={(id) => dispatch(push(`/timeline${id ? '/' + id : ''}`))}
            />
          </div>
        </div>
        <div className="mb-3 flex-fill d-flex flex-column">
          <Row className="gx-3 flex-fill">
            {groups.map(group => (
              <Col lg="4" className="p-2" key={group.type}>
                <Group
                  title={capitalize(group.type) + ' term'}
                  subtitle={group.subtitle}
                  onAdd={() => setPlaceholder({ year: group.minYear })}
                >
                  {sortedGroupItems.filter(item => (!group.maxYear || item.year < group.maxYear) && item.year >= group.minYear).length === 0 &&
                  (!placeholder || (placeholder && !((!group.maxYear || placeholder.year < group.maxYear) && placeholder.year >= group.minYear)))
                    ? (
                      <CTACard
                        cta={'Add ' + group.type + ' term goal'}
                        icons={{ prefix: 'fas', items: group.icons as any }}
                        action={() => setPlaceholder({ year: group.minYear })}
                      >
                        <div className='gray-60'>
                          {group.type === 'short' && (
                            <div>Something you want to achieve in less than two years like saving for your emergency fund or buying a new car</div>
                          )}
                          {group.type === 'mid' && (
                            <div>Something you want to achieve in <span className='fw-bold'>2-5 years</span> like buying a house or starting a family</div>
                          )}
                          {group.type === 'long' && (
                            <div>Something you want to achieve in <span className='fw-bold'>more than five years</span>, like funding college education. We've already included the option not to work so you don't need to add that again!</div>
                          )}
                        </div>
                      </CTACard>
                    ) : (
                      <>
                        {(placeholder?.year || 0) === group.minYear && <EventItem placeholder={true} icon="flag-pennant" name="New event" />}
                        {sortedGroupItems.filter(item => (!group.maxYear || item.year < group.maxYear) && item.year >= group.minYear).map(item =>
                          <EventItem
                            fade={(selectedGoalId || selectedEffectId || placeholder) ? true : false}
                            key={item.id}
                            focus={selectedGoalId === item.id || selectedEffectId === item.id || selectedRelocationId === item.id}
                            warning={item.type === GOAL && !timeline?.goals?.[item.id]?.reached ? true : false}
                            type={item.type}
                            name={item.name || (item.type === GOAL && goalCategories[item.category]?.title) || (item.type === EFFECT && effectCategories[item.category]?.title)}
                            year={item.year}
                            icon={item.type === GOAL && item.category && goalCategories[item.category].icon}
                            description={item.type !== RELOCATION ? [
                              (item?.downPaymentPercentage ? " " + (item.downPaymentPercentage * 100) + "% down payment of" : ""),
                              formatAmount(item.amount, { short: true }),
                            ].join(" ") : "-"}
                            onClick={() => dispatch(push(`/timeline/${item.id}`))}
                          />)}
                      </>
                    )}
                </Group>
              </Col>
            ))}
          </Row>
        </div>

        <div className="d-block d-md-none mb-3">
          <HouseholdDetails
            household={household}
            members={members}
            expenses={expenses}
            incomes={incomes}
          />
        </div>
        <div className="d-block d-md-none mb-3">
          <SavingsTargets
            timeline={timeline}
            goals={goals}
          />
        </div>
      </Main>
    </div>
  )
}

export default Timeline
