import { CHILD_CARE_COST, EFFECT_CATEGORIES, EFFECT_OPERATIONS, FREQUENCY_TYPES, GOAL_CATEGORIES, HOA_COST } from "../../lib/constants"
import { Effect, Goal } from "../../lib/modules/timeline/types"
import { formatAmount } from "../../lib/utils/number"
import SuggestedEffect from "./SuggestedEffect"

const SuggestedEffects = (props: {
  goal?: Goal
  effects?: Effect[]
  onAdd: (...any) => any
}) => {
  const suggestions = (category) => {
    switch (category) {
      case GOAL_CATEGORIES.HOUSE:
        const maintenance = Math.ceil(((props.goal?.amount || 0) * 0.01) / 1000) * 1000
        const HOA = HOA_COST
        return <>
          {!props?.effects?.find(effect => effect.category === EFFECT_CATEGORIES.HOUSEHOLD_EXPENSES) &&
            <SuggestedEffect
              title="Maintenance + HOA"
              subtitle={formatAmount(maintenance + HOA, { short: true, frequency: FREQUENCY_TYPES.YEARLY })}
              onClick={() => props.onAdd(EFFECT_CATEGORIES.HOUSEHOLD_EXPENSES, { amount: maintenance + HOA, activeYears: 0 })}
            />
          }
          <SuggestedEffect
            title="Remove rent or current mortgage"
            onClick={() => props.onAdd(EFFECT_CATEGORIES.OTHER_EXPENSE_CHANGE, { activeYears: 0, name: "Remove rent or current mortgage", operation: EFFECT_OPERATIONS.decrease })}
          />
        </>
      case GOAL_CATEGORIES.NEW_FAMILY_MEMBER:
        return <>
          {!props?.effects?.length &&
            <SuggestedEffect
              title="Child care cost"
              subtitle={formatAmount(CHILD_CARE_COST, { short: true, frequency: FREQUENCY_TYPES.YEARLY })}
              onClick={() => props.onAdd(EFFECT_CATEGORIES.CHILD_CARE, { amount: CHILD_CARE_COST, activeYears: 18 })}
            />
          }
        </>
      default:
        return <></>
    }
  }
  return <>
    {(props?.effects?.length || 0) < 2 &&
      <>
        {props.goal && suggestions(props.goal.category)}
      </>
    }
  </>
}

export default SuggestedEffects