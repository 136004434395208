import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./EventItem.module.css"
import Pill from "../Pill";
import { GOAL, EFFECT } from "../../lib/constants";

const EventItem = (props: {
  name: string,
  placeholder?: boolean
  year?: number,
  description?: string,
  warning?: boolean
  focus?: boolean
  fade?: boolean
  icon?: IconName
  onClick?: (any) => any
  type?: typeof GOAL | typeof EFFECT
}) => {
  return (
    <div
      className={[
        styles.container,
        (props.focus || props.placeholder ? styles.selected : ""),
        (props.fade && !props.focus ? styles.fade : ""),
        "d-flex gap-3 align-items-center"
      ].join(" ")}
      onClick={props.onClick}
    >
      {props.icon &&
        <div className={[
          styles.icon, (!props.warning ? styles.reached : ""),
          (props.placeholder ? styles.placeholder : "")
        ].join(" ")}>
          <div className={styles.iconInner}>
            <FontAwesomeIcon icon={["fas", props.icon]} />
          </div>
        </div>
      }
      <div className="d-flex flex-column flex-fill">
        <div className="d-flex justify-content-between align-items-center flex-fill ">
          <div className="body-small-bold">{props.name || "New event"}</div>
          {!props.placeholder && <div className="caption fw-semi-bold">{props.year}</div>}
        </div>
        {!props.placeholder &&
          <div className="d-flex justify-content-between align-items-center flex-fill ">
            <div className="caption">{props.description}</div>
            {props.type === GOAL && (
              <div className='ms-2'>
                <Pill variant='goals' content={props.warning ? 'Needs Attention' : 'On Track'} negative={props.warning} />
              </div>
            )}
          </div>
        }
      </div>
    </div>
  )
}

export default EventItem