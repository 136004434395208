import { RELATIONSHIP_STATUS_TYPES } from "../../../constants"
import { availableYear } from "../../../utils/config"

const _529Credits = (_529CreditsData) => (
  year: number,
  state: string,
  relationshipStatus: keyof typeof RELATIONSHIP_STATUS_TYPES,
  _529Contributions: number[]
): number => {
  const configYear = availableYear(year, Object.keys(_529CreditsData))
  const config = _529CreditsData[configYear].find(item => item.state === state)
  if (!config) return 0
  const totalContribution = _529Contributions.reduce((sum, item) => sum + item, 0)
  if (!totalContribution) return 0
  return Math.min((totalContribution / 100 * config.percentage), config.max[relationshipStatus])

}

export default _529Credits