import { ActionCreator, Expense } from "../../lib/types"

export const SET_EXPENSES = "EXPENSES/setExpenses"
export const ADD_EXPENSE = "EXPENSES/addExpense"
export const UPDATE_EXPENSE = "EXPENSES/updateExpense"
export const REMOVE_EXPENSE = "EXPENSES/removeExpense"

export const setExpenses: ActionCreator<{
  [K in keyof Expense]: Expense[K]
}[]> = (payload) => {
  return {
    type: SET_EXPENSES,
    payload
  }
}
export const addExpense: ActionCreator<{
  [K in keyof Expense]: Expense[K]
}> = (payload) => {
  return {
    type: ADD_EXPENSE,
    payload
  }
}

export const updateExpense: ActionCreator<{
  [K in keyof Expense]: Expense[K]
}> = (payload) => {
  return {
    type: UPDATE_EXPENSE,
    payload
  }
}

export const removeExpense: ActionCreator<{
  id: string
}> = (payload) => {
  return {
    type: REMOVE_EXPENSE,
    payload
  }
}