import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap"
import { Effect, Goal, Loan, Relocation } from "../../lib/modules/timeline/types"
import GoalEffect from "./GoalEffect"
import { v4 } from "uuid";
import { EFFECT_CATEGORIES, EFFECT_OPERATIONS, GOAL_CATEGORIES } from "../../lib/constants"
import EffectEditor from "./EffectEditor"
import { useEffect, useMemo, useRef, useState } from "react"
import LoanEditor from "./LoanEditor";
import SuggestedEffect from "./SuggestedEffect";
import { formatAmount } from "../../lib/utils/number";
import styles from "./GoalEffects.module.css"
import EffectTemplates from "./EffectTemplates";
import effectCategories from "../../lib/config/effectCategories";
import RelocationEditor from "./RelocationEditor";
import { useDispatch } from "react-redux";
import { addEffect, removeEffect, updateEffect } from "../../store/actions/effects";
import { addRelocation, removeRelocation, updateRelocation } from "../../store/actions/relocations";
import { addLoan, removeLoan, updateLoan } from "../../store/actions/loans";
import defaultAPRs from "../../lib/config/defaultAPRs";
import SuggestedEffects from "./SuggestedEffects";
import { Household } from "../../lib/types";

const GoalEffects = (props: {
  goal?: Goal
  effects?: Effect[]
  loans?: Loan[]
  relocations?: Relocation[]
  readOnly?: boolean
  context: { household: Household }
}) => {

  const suggestionsRef = useRef<HTMLDivElement>(null)
  const [suggestionsOpen, setSuggestionsOpen] = useState<boolean>(false)

  const [fresh, setFresh] = useState<string | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    setSuggestionsOpen(false)
  }, [fresh])

  const year = useMemo(() => {
    return props.goal?.year
  }, [props.goal])

  const { amount, downPaymentPercentage } = useMemo(() => {
    return props.goal || {} as Goal
  }, [props.goal])

  const loanAmount = useMemo(() => {
    return amount - (amount * (downPaymentPercentage || 1))
  }, [amount, downPaymentPercentage])

  const effectYear = (category?: keyof typeof EFFECT_CATEGORIES) => {
    if (!props.goal) return 0
    switch (category) {
      case EFFECT_CATEGORIES.SELLING_PROPERTY:
        return props.goal.year
      default:
        return props.goal.year + 1
    }
  }

  const handleCreate = (category: keyof typeof EFFECT_CATEGORIES) => {
    if (category === EFFECT_CATEGORIES.RELOCATION) {
      handleCreateRelocation()
    } else {
      handleCreateEffect(category)
    }
  }

  const handleCreateRelocation = () => {
    if (!props.goal) return
    const relocation: Relocation = {
      id: v4(),
      name: "Tax location change",
      year: effectYear(EFFECT_CATEGORIES.RELOCATION),
      goalId: props.goal.id,
      state: "",
      city: "",
    }
    setFresh(relocation.id)
    dispatch(addRelocation(relocation))
  }

  const handleCreateEffect = (category: keyof typeof EFFECT_CATEGORIES, defaults?: {
    amount?: number,
    activeYears?: number,
    name?: string,
    operation?: keyof typeof EFFECT_OPERATIONS
  }) => {
    if (!props.goal) return
    let effect: Effect = {
      id: v4(),
      name: effectCategories[category].title,
      year: effectYear(category),
      activeYears: 1,
      amount: 0,
      category,
      goalId: props.goal.id,
      subject: effectCategories[category].subject,
      operation: EFFECT_OPERATIONS.increase
    }
    if (defaults) effect = Object.assign({}, effect, defaults)
    setFresh(effect.id)
    dispatch(addEffect(effect))
  }

  const handleCreateLoan = () => {
    if (!props.goal) return
    const defaultYears = props.goal.category === GOAL_CATEGORIES.CAR ? 5 : 30
    const name = props.goal.category === GOAL_CATEGORIES.CAR ? "loan" : "mortgage"
    const loan: Loan = {
      id: v4(),
      name: formatAmount(loanAmount, { short: true }) + " " + name,
      startYear: props.goal.year + 1,
      activeYears: defaultYears,
      total: loanAmount,
      goalId: props.goal.id,
      annualPercentageRate: defaultAPRs[defaultYears]
    }
    setFresh(loan.id)
    dispatch(addLoan(loan))
  }

  useEffect(() => {
    if (!year) return
    props?.effects
      ?.filter(effect => effect.year !== effectYear(effect.category))
      ?.map(effect => Object.assign({}, effect, { year: effectYear(effect.category) }))
      ?.forEach(effect => dispatch(updateEffect(effect)))
    props?.loans
      ?.filter(loan => loan.startYear !== effectYear(EFFECT_CATEGORIES.LOAN))
      ?.map(loan => Object.assign({}, loan, { startYear: effectYear(EFFECT_CATEGORIES.LOAN) }))
      ?.forEach(loan => dispatch(updateLoan(loan)))
    props?.relocations
      ?.filter(relocation => relocation.year !== effectYear(EFFECT_CATEGORIES.RELOCATION))
      ?.map(relocation => Object.assign({}, relocation, { year: effectYear(EFFECT_CATEGORIES.RELOCATION) }))
      ?.forEach(relocation => dispatch(updateRelocation(relocation)))
    // eslint-disable-next-line
  }, [year])

  useEffect(() => {
    if (!amount || !downPaymentPercentage) return
    props?.loans
      ?.filter(loan => loan.total !== loanAmount)
      ?.map(loan => Object.assign({}, loan, { total: loanAmount, name: formatAmount(loanAmount, { short: true }) + " mortgage" }))
      ?.forEach(loan => dispatch(updateLoan(loan)))
    // eslint-disable-next-line
  }, [amount, downPaymentPercentage])
  return <div>

    <div className="caption text-muted fw-bolder pt-3 pb-1">Effects</div>

    <div className="d-flex flex-column gap-2 mb-2">
      {!props.loans?.length && loanAmount > 0 &&
        <div>
          <SuggestedEffect
            icon={effectCategories[EFFECT_CATEGORIES.LOAN].icon}
            title={`Take ${formatAmount(loanAmount)} ${props.goal?.category === GOAL_CATEGORIES.CAR ? "loan" : "mortgage"}`}
            onClick={() => handleCreateLoan()}
          />
        </div>
      }
      {props.loans?.map(loan => (
        <div key={loan.id}>
          <GoalEffect title={loan.name} icon={effectCategories[EFFECT_CATEGORIES.LOAN].icon} open={fresh === loan.id}>
            <LoanEditor
              loan={loan}
              readOnly={props.readOnly ? true : false}
              onChange={(nextLoan) => dispatch(updateLoan(nextLoan))}
            />
            <div className="text-red text-center">
              <span className="clickable caption fw-bold" onClick={() => dispatch(removeLoan(loan))}>
                Remove loan
              </span>
            </div>
          </GoalEffect>
        </div>
      ))}

      {props.relocations?.map(relocation => (
        <div key={relocation.id}>
          <GoalEffect title={relocation.name} icon={effectCategories[EFFECT_CATEGORIES.RELOCATION].icon} open={fresh === relocation.id}>
            <RelocationEditor
              hiddenFields={["year"]}
              relocation={relocation}
              readOnly={props.readOnly ? true : false}
              onChange={(nextRelocation) => dispatch(updateRelocation(nextRelocation))}
            />
            <div className="text-red text-center">
              <span className="clickable caption fw-bold" onClick={() => dispatch(removeRelocation(relocation))}>
                Remove effect
              </span>
            </div>
          </GoalEffect>
        </div>
      ))}
      {props.effects?.map(effect => (
        <div key={effect.id}>
          <GoalEffect title={effect.name} icon={effectCategories[EFFECT_CATEGORIES[effect.category || ""]].icon} open={fresh === effect.id}>
            <EffectEditor
              hiddenFields={["year"]}
              effect={effect}
              readOnly={props.readOnly ? true : false}
              onChange={(nextEffect) => dispatch(updateEffect(nextEffect))}
            />
            <div className="text-red text-center">
              <span className="clickable caption fw-bold" onClick={() => dispatch(removeEffect(effect))}>
                Remove effect
              </span>
            </div>
          </GoalEffect>
        </div>
      ))}

      <SuggestedEffects goal={props.goal} effects={props.effects} onAdd={(category, defaults) => handleCreateEffect(category, defaults)} />

    </div>

    <div className="text-center">
      <Button variant="light" size="sm" disabled={props.readOnly ? true : false} onClick={() => setSuggestionsOpen(!suggestionsOpen)}>
        <FontAwesomeIcon icon={["fas", "plus"]} /> Add financial effect
      </Button>
    </div>

    <div className={styles.suggestions} ref={suggestionsRef} style={{ maxHeight: suggestionsOpen ? (suggestionsRef.current?.scrollHeight || 0) + 'px' : '0px' }}>
      <div className="pt-2">
        <EffectTemplates onClick={(category) => handleCreate(category)} />
      </div>
    </div>


  </div>
}

export default GoalEffects