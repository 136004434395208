import React, { createRef } from 'react';
import styles from "./PropertyList.module.css"
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

const PropertyList = (props: {
  children: React.ReactNode
}) => {
  return <>
    <TransitionGroup className={"property-list"}>
      {React.Children.map(props.children, (child) => {
        const itemRef = createRef<HTMLDivElement>();
        if (!React.isValidElement(child)) return child;
        return (
          <CSSTransition
            key={child.props.id}
            timeout={200}
            classNames={{ ...styles }}
            nodeRef={itemRef}
          >
            <div ref={itemRef}>
              {child}
            </div>
          </CSSTransition>
        )
      })}
    </TransitionGroup>
  </>
}

export default PropertyList