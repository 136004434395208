import { call, put, select } from "redux-saga/effects"
import { INCOME_TYPES, MEMBER_TYPES } from "../lib/constants"
import * as incomes from "../services/incomes"
import { addIncome, removeIncome } from "../store/actions/incomes"
import { addMember, removeMember } from "../store/actions/members"

export const selectRelevantState = (state) => ({ members: state.members, incomes: state.incomes })

export function* createDefaultIncomes(action: ReturnType<typeof addMember>) {
  if ([MEMBER_TYPES.PRIMARY, MEMBER_TYPES.PARTNER].includes(action.payload.type as any)) {
    const baseSalary = yield call(incomes.createIncome, action.payload.id, INCOME_TYPES.ANNUAL_BASE_SALARY)
    const bonus = yield call(incomes.createIncome, action.payload.id, INCOME_TYPES.ANNUAL_BONUS)
    yield put(addIncome(baseSalary))
    yield put(addIncome(bonus))
  }
}

export function* removeMemberIncomes(action: ReturnType<typeof removeMember>) {
  const { incomes } = yield select(selectRelevantState)
  const memberIncomes = incomes.filter(income => income.memberId === action.payload.id)
  for (const income of memberIncomes) {
    yield put(removeIncome(income.id))
  }
}