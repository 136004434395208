import styles from "./CircleIcon.module.css"
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CircleIcon = (props: {
  color?: string,
  footerIcon?: IconName | null,
  children: React.ReactNode
}) => {
  return <>
    <div className={[styles.container, 'text-uppercase'].join(' ')} style={props.color ? { backgroundColor: props.color } : {}}>
      <div className={styles.icon}>
        {props.children}
      </div>
      {props.footerIcon &&
        <div className={styles.footerIcon}>
          <FontAwesomeIcon icon={["fas", props.footerIcon]} />
        </div>
      }
    </div>
  </>
}

export default CircleIcon