import { statePersonalExemptionsConfig } from "../types";

const statePersonalExemptionsData: statePersonalExemptionsConfig = {
  "2021": {
    single: [
      { state: "Alabama", amount: 1500 },
      { state: "Alaska", amount: 0 },
      { state: "Arizona", amount: 0 },
      { state: "Arkansas", amount: 29 },
      { state: "California", amount: 124 },
      { state: "Colorado", amount: 0 },
      { state: "Connecticut", amount: 15000 },
      { state: "Delaware", amount: 110 },
      { state: "District of Columbia", amount: 0 },
      { state: "Florida", amount: 0 },
      { state: "Georgia", amount: 2700 },
      { state: "Hawaii", amount: 1144 },
      { state: "Idaho", amount: 0 },
      { state: "Illinois", amount: 2325 },
      { state: "Indiana", amount: 1000 },
      { state: "Iowa", amount: 40 },
      { state: "Kansas", amount: 2250 },
      { state: "Kentucky", amount: 0 },
      { state: "Louisiana", amount: 4500 },
      { state: "Maine", amount: 4300 },
      { state: "Maryland", amount: 3200 },
      { state: "Massachusetts", amount: 4400 },
      { state: "Michigan", amount: 4750 },
      { state: "Minnesota", amount: 0 },
      { state: "Mississippi", amount: 6000 },
      { state: "Missouri", amount: 0 },
      { state: "Montana", amount: 2580 },
      { state: "Nebraska", amount: 142 },
      { state: "Nevada", amount: 0 },
      { state: "New Hampshire", amount: 0 },
      { state: "New Jersey", amount: 1000 },
      { state: "New Mexico", amount: 0 },
      { state: "New York", amount: 0 },
      { state: "North Carolina", amount: 0 },
      { state: "North Dakota", amount: 0 },
      { state: "Ohio", amount: 2400 },
      { state: "Oklahoma", amount: 1000 },
      { state: "Oregon", amount: 213 },
      { state: "Pennsylvania", amount: 0 },
      { state: "Rhode Island", amount: 4250 },
      { state: "South Carolina", amount: 0 },
      { state: "South Dakota", amount: 0 },
      { state: "Tennessee", amount: 0 },
      { state: "Texas", amount: 0 },
      { state: "Utah", amount: 0 },
      { state: "Vermont", amount: 4250 },
      { state: "Virginia", amount: 930 },
      { state: "Washington", amount: 0 },
      { state: "West Virginia", amount: 2000 },
      { state: "Wisconsin", amount: 700 },
      { state: "Wyoming", amount: 0 },
    ],
    married: [
      { state: "Alabama", amount: 3000 },
      { state: "Alaska", amount: 0 },
      { state: "Arizona", amount: 0 },
      { state: "Arkansas", amount: 58 },
      { state: "California", amount: 248 },
      { state: "Colorado", amount: 0 },
      { state: "Connecticut", amount: 24000 },
      { state: "Delaware", amount: 220 },
      { state: "District of Columbia", amount: 0 },
      { state: "Florida", amount: 0 },
      { state: "Georgia", amount: 7400 },
      { state: "Hawaii", amount: 2288 },
      { state: "Idaho", amount: 0 },
      { state: "Illinois", amount: 4650 },
      { state: "Indiana", amount: 2000 },
      { state: "Iowa", amount: 80 },
      { state: "Kansas", amount: 4500 },
      { state: "Kentucky", amount: 0 },
      { state: "Louisiana", amount: 9000 },
      { state: "Maine", amount: 8600 },
      { state: "Maryland", amount: 6400 },
      { state: "Massachusetts", amount: 8800 },
      { state: "Michigan", amount: 9500 },
      { state: "Minnesota", amount: 0 },
      { state: "Mississippi", amount: 12000 },
      { state: "Missouri", amount: 0 },
      { state: "Montana", amount: 5160 },
      { state: "Nebraska", amount: 284 },
      { state: "Nevada", amount: 0 },
      { state: "New Hampshire", amount: 0 },
      { state: "New Jersey", amount: 2000 },
      { state: "New Mexico", amount: 0 },
      { state: "New York", amount: 0 },
      { state: "North Carolina", amount: 0 },
      { state: "North Dakota", amount: 0 },
      { state: "Ohio", amount: 4800 },
      { state: "Oklahoma", amount: 2000 },
      { state: "Oregon", amount: 426 },
      { state: "Pennsylvania", amount: 0 },
      { state: "Rhode Island", amount: 8500 },
      { state: "South Carolina", amount: 0 },
      { state: "South Dakota", amount: 0 },
      { state: "Tennessee", amount: 0 },
      { state: "Texas", amount: 0 },
      { state: "Utah", amount: 0 },
      { state: "Vermont", amount: 8500 },
      { state: "Virginia", amount: 1860 },
      { state: "Washington", amount: 0 },
      { state: "West Virginia", amount: 4000 },
      { state: "Wisconsin", amount: 1400 },
      { state: "Wyoming", amount: 0 },
    ]
  }
}

export default statePersonalExemptionsData