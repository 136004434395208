import styles from "./Suggestions.module.css"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GOAL_CATEGORIES } from "../../lib/constants"
import goalCategories from "../../lib/config/goalCategories";

const Suggestions = (props: {
  suggestions: {
    title: string
    value: any
    icon?: IconName | string,
  }[]
  variant?: 'centered'
  onClick: (any) => any
}) => {
  return <div className={[styles.container, (props.variant === 'centered' ? 'justify-content-center' : '')].join(' ')}>
    {props.suggestions.map(suggestion => (
      <div key={suggestion.value} className={styles.suggestionWrapper}>
        <div className={[styles.suggestion, "d-flex flex-column align-items-center justify-content-center"].join(" ")} onClick={() => props.onClick(suggestion.value)}>
          <FontAwesomeIcon icon={["fas", suggestion.icon || goalCategories[GOAL_CATEGORIES.OTHER].icon]} />
          <div className="mt-1 caption">{suggestion.title}</div>
        </div>
      </div>
    ))}
  </div>
}

export default Suggestions