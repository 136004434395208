import './Layout.css';

import Footer from "../components/Footer"
import Navbar from "../components/Navbar"

const Layout = props => {
  return <>
    <div className="main">
      <Navbar />
      {props.children}
    </div>
    <Footer />
  </>
}

export default Layout