import { HSADeductionConfig } from "../types"

const HSADeductionData: HSADeductionConfig = {
  "2021": {
    nonDeductibleStates: [
      "California",
      "New Jersey"
    ]
  }
}

export default HSADeductionData