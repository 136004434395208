import { EXPENSE_CATEGORIES, EXPENSE_TYPES, INFO_CATEGORIES } from "../constants"

const expenseCategories: {} = {
  [EXPENSE_CATEGORIES.RENT]: {
    title: "Rent",
    icon: "coins",
    type: EXPENSE_TYPES.EXPENSE,
  },
  [EXPENSE_CATEGORIES.TUITION_OR_EDUCATION]: {
    title: "Tuition or education",
    icon: "coins",
    type: EXPENSE_TYPES.EXPENSE,
    tooltip: INFO_CATEGORIES.TUITION_OR_EDUCATION_AMOUNT
  },
  [EXPENSE_CATEGORIES.CREDIT_CARD_BILLS]: {
    title: "Credit card bills",
    icon: "coins",
    type: EXPENSE_TYPES.EXPENSE,
    tooltip: INFO_CATEGORIES.CREDIT_CARD_BILLS_AMOUNT
  },
  [EXPENSE_CATEGORIES.CHILD_CARE]: {
    title: "Child care",
    icon: "coins",
    type: EXPENSE_TYPES.EXPENSE,
  },
  [EXPENSE_CATEGORIES.MORTGAGE_PAYMENT]: {
    title: "Mortgage payment",
    icon: "building-columns",
    type: EXPENSE_TYPES.LIABILITY,
  },
  [EXPENSE_CATEGORIES.CAR_LOAN_PAYMENT]: {
    title: "Car loan payment",
    icon: "building-columns",
    type: EXPENSE_TYPES.LIABILITY,
  },
  [EXPENSE_CATEGORIES.STUDENT_LOAN_PAYMENT]: {
    title: "Student loan payment",
    type: EXPENSE_TYPES.LIABILITY,
    icon: "building-columns",
  },
  [EXPENSE_CATEGORIES.OTHER]: {
    title: "Other",
    icon: "coins",
    type: EXPENSE_TYPES.EXPENSE,
    tooltip: INFO_CATEGORIES.OTHER_EXPENSE_AMOUNT
  },
}

export default expenseCategories