import { DEBT_CATEGORIES } from "../constants"

const debtCategories: {} = {
  [DEBT_CATEGORIES.MORTGAGE]: {
    title: "Mortgage",
    icon: 'coins'
  },
  [DEBT_CATEGORIES.CAR_LOAN]: {
    title: "Car loan",
    icon: 'coins'
  },
  [DEBT_CATEGORIES.STUDENT_LOAN]: {
    title: "Student loan",
    icon: 'coins'
  },
  [DEBT_CATEGORIES.OTHER]: {
    title: "Other",
    icon: 'coins'
  },
}

export default debtCategories