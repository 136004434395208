import { stateIncomeTaxConfig } from "../types";

const stateIncomeTaxData: stateIncomeTaxConfig = {
  "2021": [
    {
      state: "California",
      low: 1,
      high: 12.30
    },
    {
      state: "New Jersey",
      low: 1.40,
      high: 10.75
    },
    {
      state: "District of Columbia",
      low: 4,
      high: 8.95
    },
    {
      state: "New York",
      low: 4,
      high: 8.82
    },
    {
      state: "Arizona",
      low: 2.59,
      high: 8
    },
    {
      state: "Maine",
      low: 7.15,
      high: 7.15
    },
    {
      state: "Georgia",
      low: 1.00,
      high: 5.75
    },
    {
      state: "Maryland",
      low: 5.75,
      high: 5.75
    },
    {
      state: "Virginia",
      low: 2,
      high: 5.75
    },
    {
      state: "North Carolina",
      low: 5.25,
      high: 5.25
    },
    {
      state: "Kentucky",
      low: 5,
      high: 5
    },
    {
      state: "Massachusetts",
      low: 5,
      high: 5
    },
    {
      state: "Illinois",
      low: 4.95,
      high: 4.95
    },
    {
      state: "Utah",
      low: 4.95,
      high: 4.95
    },
    {
      state: "Ohio",
      low: 0,
      high: 4.80
    },
    {
      state: "Colorado",
      low: 4.55,
      high: 4.55
    },
    {
      state: "Michigan",
      low: 4.25,
      high: 4.25
    },
    {
      state: "Indiana",
      low: 3.23,
      high: 3.23
    },
    {
      state: "Pennsylvania",
      low: 3.07,
      high: 3.07
    },
    {
      state: "Alaska",
      low: 0,
      high: 0
    },
    {
      state: "Florida",
      low: 0.00,
      high: 0.00
    },
    {
      state: "Nevada",
      low: 0,
      high: 0
    },
    {
      state: "New Hampshire",
      low: 0,
      high: 0
    },
    {
      state: "South Dakota",
      low: 0,
      high: 0
    },
    {
      state: "Tennessee",
      low: 0,
      high: 0
    },
    {
      state: "Texas",
      low: 0,
      high: 0
    },
    {
      state: "Washington",
      low: 0,
      high: 0
    },
    {
      state: "Wyoming",
      low: 0,
      high: 0
    },
    {
      state: "Hawaii",
      low: 1.40,
      high: 11.00
    },
    {
      state: "Oregon",
      low: 4.75,
      high: 9.90
    },
    {
      state: "Minnesota",
      low: 5.35,
      high: 9.85
    },
    {
      state: "Vermont",
      low: 3.35,
      high: 8.75
    },
    {
      state: "Iowa",
      low: 0.33,
      high: 8.53
    },
    {
      state: "Wisconsin",
      low: 3.50,
      high: 7.65
    },
    {
      state: "South Carolina",
      low: 0,
      high: 7
    },
    {
      state: "Connecticut",
      low: 3,
      high: 6.99
    },
    {
      state: "Idaho",
      low: 1.13,
      high: 6.93
    },
    {
      state: "Montana",
      low: 1,
      high: 6.90
    },
    {
      state: "Nebraska",
      low: 2.46,
      high: 6.84
    },
    {
      state: "Delaware",
      low: 0,
      high: 6.60
    },
    {
      state: "West Virginia",
      low: 3,
      high: 6.50
    },
    {
      state: "Louisiana",
      low: 2,
      high: 6
    },
    {
      state: "Rhode Island",
      low: 3.75,
      high: 5.99
    },
    {
      state: "Arkansas",
      low: 2.00,
      high: 5.90
    },
    {
      state: "New Mexico",
      low: 1.40,
      high: 5.90
    },
    {
      state: "Kansas",
      low: 3.10,
      high: 5.70
    },
    {
      state: "Missouri",
      low: 1.50,
      high: 5.40
    },
    {
      state: "Alabama",
      low: 2,
      high: 5
    },
    {
      state: "Mississippi",
      low: 3,
      high: 5
    },
    {
      state: "Oklahoma",
      low: 0.50,
      high: 5.00
    },
    {
      state: "North Dakota",
      low: 1.10,
      high: 2.90
    }]
}

export default stateIncomeTaxData