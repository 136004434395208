export const EXPENSE_TYPES = {
  EXPENSE: "EXPENSE",
  LIABILITY: "LIABILITY"
} as const;

export const EXPENSE_CATEGORIES = {
  RENT: "RENT",
  CHILD_CARE: "CHILD_CARE",
  TUITION_OR_EDUCATION: "TUITION_OR_EDUCATION",
  CREDIT_CARD_BILLS: "CREDIT_CARD_BILLS",
  MORTGAGE_PAYMENT: "MORTGAGE_PAYMENT",
  CAR_LOAN_PAYMENT: "CAR_LOAN_PAYMENT",
  STUDENT_LOAN_PAYMENT: "STUDENT_LOAN_PAYMENT",
  OTHER: "OTHER",
} as const;

export const EXPENSE_GROUPS = {
  [EXPENSE_TYPES.EXPENSE]: [
    EXPENSE_CATEGORIES.RENT,
    EXPENSE_CATEGORIES.CHILD_CARE,
    EXPENSE_CATEGORIES.TUITION_OR_EDUCATION,
    EXPENSE_CATEGORIES.CREDIT_CARD_BILLS,
    EXPENSE_CATEGORIES.OTHER
  ],
  [EXPENSE_TYPES.LIABILITY]: [
    EXPENSE_CATEGORIES.MORTGAGE_PAYMENT,
    EXPENSE_CATEGORIES.CAR_LOAN_PAYMENT,
    EXPENSE_CATEGORIES.STUDENT_LOAN_PAYMENT
  ]
}

export const DEBT_CATEGORIES = {
  MORTGAGE: "MORTGAGE",
  CAR_LOAN: "CAR_LOAN",
  STUDENT_LOAN: "STUDENT_LOAN",
  OTHER: "OTHER",
} as const;

export const MEMBER_TYPES = {
  PRIMARY: "PRIMARY",
  PARTNER: "PARTNER",
  OTHER: "OTHER"
} as const;

export const ASSET_TYPES = {
  INVESTMENT: "INVESTMENT",
  PROPERTY: "PROPERTY",
  CASH_AND_EQUIVALENTS: "CASH_AND_EQUIVALENTS",
} as const;

export const ASSET_CATEGORIES = {
  HOUSE: "HOUSE",
  OTHER: "OTHER",
  CASH_AND_EQUIVALENTS: "CASH_AND_EQUIVALENTS",
  PERSONAL_INVESTMENT: "PERSONAL_INVESTMENT",
  TRADITIONAL_401K: "TRADITIONAL_401K",
  ROTH_401K: "ROTH_401K",
  TRADITIONAL_IRA: "TRADITIONAL_IRA",
  ROTH_IRA: "ROTH_IRA",
  _529: "_529",
  HSA: "HSA",
} as const;

export const ASSET_GROUPS = {
  [ASSET_TYPES.PROPERTY]: [ASSET_CATEGORIES.HOUSE, ASSET_CATEGORIES.OTHER],
  [ASSET_TYPES.CASH_AND_EQUIVALENTS]: [ASSET_CATEGORIES.CASH_AND_EQUIVALENTS],
  [ASSET_TYPES.INVESTMENT]: [
    ASSET_CATEGORIES.PERSONAL_INVESTMENT,
    ASSET_CATEGORIES.TRADITIONAL_401K,
    ASSET_CATEGORIES.ROTH_401K,
    ASSET_CATEGORIES.TRADITIONAL_IRA,
    ASSET_CATEGORIES.ROTH_IRA,
    ASSET_CATEGORIES._529,
    ASSET_CATEGORIES.HSA
  ],
}

export const ALLOCATION_TYPES = {
  EQUITIES: "EQUITIES",
  FIXED_INCOME: "FIXED_INCOME",
} as const

export const INVESTMENT_TYPES = {
  TOTAL_US_STOCK_MARKET: "TOTAL_US_STOCK_MARKET",
  TOTAL_INTL_STOCK_MARKET: "TOTAL_INTL_STOCK_MARKET",
  TOTAL_US_BOND_MARKET: "TOTAL_US_BOND_MARKET",
  TOTAL_INTL_BOND_MARKET: "TOTAL_INTL_BOND_MARKET",
  SHORT_TERM_INFLATION_PROTECTED_SECURITIES: "SHORT_TERM_INFLATION_PROTECTED_SECURITIES",
} as const

export const FREQUENCY_TYPES = {
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
} as const;

// export const INCOME_TYPES = {
//   annualBaseSalary: "annualBaseSalary",
//   annualBonus: "annualBonus",
//   RSU: "RSU",
// } as const;

export const INCOME_TYPES = {
  ANNUAL_BASE_SALARY: "ANNUAL_BASE_SALARY",
  ANNUAL_BONUS: "ANNUAL_BONUS",
  OTHER_ANNUAL_INCOME: "OTHER_ANNUAL_INCOME"
} as const;

export const CONTRIBUTION_TYPES = {
  rothIRA: "rothIRA",
  traditionalIRA: "traditionalIRA",
  HSA: "HSA",
  FSA: "FSA",
  DCFSA: "DCFSA",
  HCFSA: "HCFSA",
  traditional401k: "traditional401k", // 401k, 403b, 457
  roth401k: "roth401k", // 401k, 403b, 457
  _529: "_529",
} as const;

export const TAX_TYPES = {
  federal: 'federal',
  state: 'state',
  local: 'local',
  socialSecurity: 'socialSecurity',
  medicare: 'medicare',
  fica: 'fica'
}

export const RELATIONSHIP_STATUS_TYPES = {
  single: "single",
  married: "married"
} as const;

export const GOAL_CATEGORIES = {
  EMERGENCY_FUND: "EMERGENCY_FUND",
  RETIREMENT: "RETIREMENT",
  HOUSE: "HOUSE",
  EDUCATION: "EDUCATION",
  CAR: "CAR",
  TRAVEL: "TRAVEL",
  HOME_REMODEL: "HOME_REMODEL",
  BUSINESS: "BUSINESS",
  HEALTH: "HEALTH",
  NEW_FAMILY_MEMBER: "NEW_FAMILY_MEMBER",
  OTHER: "OTHER"
} as const;

export const EFFECT_CATEGORIES = {
  OTHER_INCOME_CHANGE: "OTHER_INCOME_CHANGE",
  OTHER_EXPENSE_CHANGE: "OTHER_EXPENSE_CHANGE",
  ONE_TIME_INCOME: "ONE_TIME_INCOME",
  SELLING_PROPERTY: "SELLING_PROPERTY",
  CHILD_CARE: "CHILD_CARE",
  PARENT_CARE: "PARENT_CARE",
  PRIVATE_SCHOOL: "PRIVATE_SCHOOL",
  HOUSEHOLD_EXPENSES: "HOUSEHOLD_EXPENSES",
  RELOCATION: "RELOCATION",
  LOAN: "LOAN",
} as const;

export const EFFECT_OPERATIONS = {
  increase: "increase",
  decrease: "decrease",
} as const;

export const EFFECT_SUBJECTS = {
  income: "income",
  expense: "expense",
} as const;

export const DEBT = "DEBT"
export const PERSONAL_INVESTMENT = "PERSONAL_INVESTMENT"
export const OTHER_ANNUAL_INCOME = "OTHER_ANNUAL_INCOME"
export const LOCATION = "LOCATION"
export const NAME = "NAME"
export const FIRST_NAME = "FIRST_NAME"
export const LAST_NAME = "LAST_NAME"
export const YEAR_OF_BIRTH = "YEAR_OF_BIRTH"
export const ACTIVITY = "ACTIVITY"
export const RETIREMENT = "RETIREMENT"
export const SAVING = 'SAVING'
export const GOAL = 'GOAL'
export const EFFECT = 'EFFECT'
export const RELOCATION = 'RELOCATION'

export const BABY_COST = 25000
export const CHILD_CARE_COST = 24000
export const HOA_COST = 250 * 12
export const CAPITAL_GAIN_TAX = 0.15
export const INFLATION_RATE = 0.02
export const INCOME_INCREASE_RATE = 0.03

export const INFO_CATEGORIES = {
  RETIREMENT_TARGET_INFO: "RETIREMENT_TARGET_INFO",
  NEW_FAMILY_MEMBER_AMOUNT: "NEW_FAMILY_MEMBER_AMOUNT",
  HOME_PURCHASE_VALUE: "HOME_PURCHASE_VALUE",
  HOME_ASSET_VALUE: 'HOME_ASSET_VALUE',
  AVERAGE_APR: "AVERAGE_APR",
  EDUCATION_AMOUNT: "EDUCATION_AMOUNT",
  PROPERTY_VALUE: "PROPERTY_VALUE",
  TUITION_OR_EDUCATION_AMOUNT: "TUITION_OR_EDUCATION_AMOUNT",
  CREDIT_CARD_BILLS_AMOUNT: "CREDIT_CARD_BILLS_AMOUNT",
  OTHER_EXPENSE_AMOUNT: "OTHER_EXPENSE_AMOUNT",
} as const;

export const MEMBER_AGE_CATEGORIES = {
  BABY: 'BABY',
  CHILD: 'CHILD',
  ADULT: 'ADULT'
} as const;

