import styles from "./Card.module.css"

const Card = (props: {
  children: React.ReactNode,
  onClick?
  variant?: "CTA"
}) => {
  return (
    <div onClick={props.onClick}
      className={[
        styles.container,
        (props.variant ? styles[props.variant] : "")
      ].join(" ")}
    >
      {props.children}
    </div>
  )
}

export default Card