import effectCategories from "../../lib/config/effectCategories"
import { EFFECT_CATEGORIES } from "../../lib/constants"
import { Effect } from "../../lib/modules/timeline/types"
import Info from "../Info"
import Input from "../Input"
import Pill from "../Pill"
import { Household } from "../../lib/types";

const EffectEditor = (props: {
  effect?: Effect
  readOnly?: boolean
  hiddenFields?: string[]
  context?: { household: Household }
  onChange: (any) => any
}) => {
  const update = (updates) => {
    const nextEffect = Object.assign({ ...(props.effect || {}) }, updates)
    props.onChange(nextEffect)
  }

  const suggestions = {
    [EFFECT_CATEGORIES.PRIVATE_SCHOOL]: {
      activeYears: () => {
        return (<>
          <Pill content={"13 years"} className="clickable mb-1" onClick={() => update({
            activeYears: 13,
          })} />
        </>)
      },
    }
  }

  return <div>
    {props.effect?.category && effectCategories[props.effect?.category] && effectCategories[props.effect?.category].fields
      .filter(field => !props.hiddenFields || !props.hiddenFields.includes(field.name))
      .map(field => (
        <div key={field.name}>
          <Input
            type={field.type}
            name={field.name}
            value={props.effect?.[field.name]}
            label={field.label}
            placeholder={field.placeholder}
            readOnly={props.readOnly}
            min={props?.context?.household.year}
            tooltip={field.tooltip && <Info category={field.tooltip} />}
            handleChange={(val, field) => update({ [field]: val })} />
          {props.effect?.category && !props.effect[field.name] && suggestions[props.effect?.category]?.[field.name] && (
            <div className="pb-3 d-flex gap-1 flex-wrap">{suggestions[props.effect?.category]?.[field.name]?.()}</div>
          )}
        </div>
      ))}
  </div>
}

export default EffectEditor