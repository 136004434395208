import { useEffect, useRef } from 'react'

const Canvas = (props: {
  line
  width: number
  height: number
  colors: {
    up
    down
    base
  }
}) => {
  const canvasRef = useRef<any>(null)
  useEffect(() => {

    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    const canvasWidth = props.width;
    const canvasHeight = props.height;
    if (window.devicePixelRatio > 1) {
      canvas.width = canvasWidth * window.devicePixelRatio;
      canvas.height = canvasHeight * window.devicePixelRatio;
      canvas.style.width = canvasWidth + "px";
      canvas.style.height = canvasHeight + "px";
      context.scale(window.devicePixelRatio, window.devicePixelRatio);
    } else {
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      canvas.style.width = canvasWidth + "px";
      canvas.style.height = canvasHeight + "px";
    }

    context.beginPath();
    context.clearRect(0, 0, props.width, props.height);
    context.closePath();
    context.lineWidth = 1
    context.beginPath();
    context.strokeStyle = props.colors.base
    context.moveTo(0, props.height - 1)
    context.lineTo(props.width, props.height - 1)
    context.stroke()
    context.closePath();
    context.lineWidth = 2
    props.line.forEach((point, index) => {
      context.beginPath();
      context.strokeStyle = props.colors.up
      context.moveTo(point.x, props.height - point.y)
      const next = props.line[index + 1]
      if (next) {
        if (next.x === point.x || (point.y === 0 && next.y === 0)) {
          context.strokeStyle = props.colors.down
        }
        context.lineTo(next.x, props.height - next.y)
        context.stroke()
        context.closePath();
      }
    })

  }, [props])

  return <canvas ref={canvasRef} />
}

export default Canvas