import styles from './EmptyStateImage.module.css'

const EmptyStateImage = (props: { children?: React.ReactNode, image: string }) => {
  return (
    <div className={[styles.container, 'd-flex h-100', (props.children ? 'flex-column justify-content-between' : 'align-items-center justify-content-center')].join(' ')}>
      <img src={props.image} className='img-fluid mh-100' alt={props.image} />
      {props.children}
    </div>
  )
}

export default EmptyStateImage