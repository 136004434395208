import { RELATIONSHIP_STATUS_TYPES } from "../../../constants"
import { _529DeductionConfig } from "../types"
import { availableYear } from "../../../utils/config"

const _529Deduction = (_529DeductionData: _529DeductionConfig) => (
  year: number,
  state: string,
  relationshipStatus: keyof typeof RELATIONSHIP_STATUS_TYPES,
  _529Contributions: number[]
): number => {
  const configYear = availableYear(year, Object.keys(_529DeductionData))
  const deductibility = _529DeductionData[configYear].find(item => item.state === state)
  const totalPretaxLimit = deductibility ? deductibility[relationshipStatus] * (deductibility.perBeneficiary ? _529Contributions.length : 1) : 0
  const totalContribution = _529Contributions.reduce((sum, item) => sum + item, 0)
  return Math.min(totalPretaxLimit, totalContribution)
}

export default _529Deduction