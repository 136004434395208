import { v4 } from "uuid";
import Card from "../../components/Household/Card"
import PageTitle from "../../components/Household/PageTitle"
import CardTitle from "../../components/Household/CardTitle"
import Cards from "../../components/Household/Cards"
import Pill from "../../components/Pill"
import CardFooter from "../../components/Household/CardFooter"
import { DEBT, DEBT_CATEGORIES } from "../../lib/constants"
import Property from "../../components/Household/Property"
import { Debt } from "../../lib/types"
import { useDispatch } from "react-redux"
import { addDebt, updateDebt, removeDebt } from "../../store/actions/debts"
import { debtsSum } from "../../lib/utils/debts";
import PropertyList from "../../components/Household/PropertyList";
import { formatAmount } from "../../lib/utils/number";
import { confirmMessages } from "../../lib/config/copy";
import debtCategories from "../../lib/config/debtCategories";
import { useState } from "react";

const Debts = ({ debts }: { debts: Debt[] }) => {
  const dispatch = useDispatch()
  const [lastAdded, setLastAdded] = useState<string | null>(null)

  const debtsRequiredFields = ['amount', 'name']

  const handleDebtChange = (value: Debt) => {
    dispatch(updateDebt(value))
  }


  const handleDebtCreate = (category?: keyof typeof DEBT_CATEGORIES) => {
    // const lastAdded = [...debts].pop()
    // if (lastAdded && debtsRequiredFields.every(key => !lastAdded[key])) return
    const debt: Debt = {
      id: v4(),
      name: "",
      category: category || DEBT_CATEGORIES.OTHER,
      outstandingLoanBalance: 0
    }
    setLastAdded(debt.id)
    dispatch(addDebt(debt))
  }


  const handleDebtRemove = (debt: Debt) => {
    if (debtsRequiredFields.every(field => !debt[field])) return dispatch(removeDebt({ id: debt.id }))
    if (!window.confirm(confirmMessages['debtRemove'])) return
    dispatch(removeDebt({ id: debt.id }))
  }

  const debtOptions = [
    DEBT_CATEGORIES.MORTGAGE,
    DEBT_CATEGORIES.CAR_LOAN,
    DEBT_CATEGORIES.STUDENT_LOAN,
    DEBT_CATEGORIES.OTHER
  ]

  return (
    <div>
      <PageTitle>
        <div className="headline-5 d-flex align-items-center">
          <div className='me-2'>Debts</div>
          <Pill variant="debts" content={formatAmount(debtsSum(debts))} />
        </div>
      </PageTitle>
      <Cards>
        <Card>
          <CardTitle
            variant="collection"
            title="Debts"
            icon={"coins"}
            sum={formatAmount(debtsSum(debts), { short: true })}
            type="debts"
          />
          <PropertyList>
            {debts.map(debt => (
              <Property
                type={DEBT}
                icon={debtCategories[debt.category].icon || 'coins'}
                id={debt.id}
                key={debt.id}
                data={debt}
                open={lastAdded === debt.id}
                onChange={(value) => handleDebtChange(value)}
                onRemove={() => handleDebtRemove(debt)}
              />
            ))}
          </PropertyList>
          <CardFooter
            options={debtOptions.map(category => ({
              title: debtCategories[category].title,
              icon: debtCategories[category].icon,
              value: category
            }))}
            onAdd={(category) => handleDebtCreate(category)}
          />
        </Card>
      </Cards>
    </div>
  )
}

export default Debts