import { INFO_CATEGORIES } from "../../lib/constants";
import { expenseSum } from "../../lib/utils/expense";
import { formatAmount } from "../../lib/utils/number";
import { useAppSelector } from "../../store";

const Info = (props: { category: keyof typeof INFO_CATEGORIES }) => {
  const { expenses, effects } = useAppSelector((state) => ({
    expenses: state.expenses,
    effects: state.effects
  }))

  switch (props.category) {
    case INFO_CATEGORIES.RETIREMENT_TARGET_INFO: {
      const adjustedExpenses = effects.reduce((sum, effect) => sum + (!effect.activeYears ? effect.amount : 0), expenseSum(expenses))
      return <>
        The amount of money you need for work to be optional (financial independence) depends on how much you plan to spend after stepping back from work. The lower your spending, the easier it is to reach financial freedom.<br />
        You can spend down about 2-5% of your investment portfolio each year over 30 to 50 years without running out of money. Steward recommends 3%, just to be safe, in case you live longer or market returns aren't as good. (All of this is based on historical market performance. While that's not a guarantee, it's a reasonable starting point.)<br />
        To estimate how much money you need to achieve financial freedom, we take your planned annual spending (a starting predictor of the lifestyle you'll have post-retirement) - {formatAmount(adjustedExpenses)} - and divide by 3%.<br />
        That gives us a reasonable idea of when you can have the option not to work
        Check out [this study] if you want to go deeper.
      </>
    }
    case INFO_CATEGORIES.NEW_FAMILY_MEMBER_AMOUNT: {
      return <>
        Steward recommends setting aside a $25,000 "have-a-baby-fund" for the one-off costs of adding a child to your family.<br />
        It includes one-off costs like extra maternity/paternity leave, initial nursery set-up, extra newborn child care (e.g., doula, night nurse, lactation nurse), or can be repurposed for fertility or adoption costs if needed.<br />
        It excludes ongoing costs like nanny or daycare, additional housing, food and supplies, since these are unfortunately there to stay and will come out of your monthly budget.
      </>
    }
    case INFO_CATEGORIES.HOME_PURCHASE_VALUE: {
      return <>
        Unsure? Check out the median home values by geography from Zillow. The US median home price is 285000, but in cities like SF and NYC, the median home price is 1300000.
      </>
    }
    case INFO_CATEGORIES.HOME_ASSET_VALUE: {
      return <>
        Include either your best estimate of the current value of your house, or to be conservative the price of your house when you purchased it.
      </>
    }
    case INFO_CATEGORIES.AVERAGE_APR: {
      return <>
        Average 4.88% APR is calculated.
      </>
    }
    case INFO_CATEGORIES.EDUCATION_AMOUNT: {
      return <>
        Private 4-year school (expensive) - 4x $61,000 <br />
        Private 4-year school (average) - 4x $51,000 <br />
        Public 4-year school (out of state) - 4x $39,000 <br />
        Public 4-year school (in-state) - 4x $22,000 <br />
        <br />
        <a href='https://vanguard.wealthmsi.com/csp.php' target="_blank" rel="noreferrer">https://vanguard.wealthmsi.com/csp.php</a>
      </>
    }
    case INFO_CATEGORIES.PROPERTY_VALUE: {
      return <>
        Include either your best estimate of the current value of your house, or to be conservative the price of your house when you purchased it.
      </>
    }
    case INFO_CATEGORIES.TUITION_OR_EDUCATION_AMOUNT: {
      return <>
        Unsure? The average price of private school is $10,000-$15,000, but this is pretty location dependent. Check out: <a href="https://www.privateschoolreview.com/tuition-stats/private-school-cost-by-state">www.privateschoolreview.com/tuition-stats/private-school-cost-by-state</a> for more details.
      </>
    }
    case INFO_CATEGORIES.CREDIT_CARD_BILLS_AMOUNT: {
      return <>
        Include: all you household's credit cards (including your spouse or partner's cards if applicable). If your recent spending was less than usual due to COVID then you may want to compensate for that by rounding up.<br />
        Exclude: mortgage or rent, childcare costs, education costs, student or auto loans
      </>
    }
    case INFO_CATEGORIES.OTHER_EXPENSE_AMOUNT: {
      return <>
        Any annual expenses that you pay for directly from a bank account or via check (e.g., life/disability/liability/car insurance, travel, charitable gifts, supporting family or friends financially). Unsure on travel? You can use ~$4,000 per international trips and $~2,000 per domestic trips as a rough proxy.<br />
        Exclude: Any annual expenses that you pay for via credit card. You've already counted those!
      </>
    }
    default: {
      return <>...</>
    }
  }
}

export default Info