import { Household } from "../../lib/types"
import { UNLOAD_DATA } from "../actions/data"
import { SET_HOUSEHOLD } from "../actions/household"

const initial: Household | null = null

const household = (state = initial, action) => {
  switch (action.type) {
    case SET_HOUSEHOLD:
      if (!state) return action.payload || null
      return Object.assign({}, state, action.payload)
    
    case UNLOAD_DATA:
      return initial
    
    default:
      return state
  }
}

export default household