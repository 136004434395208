const incomeData = {
  '2021': [
    0,
    800,
    4165,
    7200,
    9372,
    10735,
    12000,
    13200,
    14401,
    15600,
    16776,
    18000,
    19010,
    20000,
    21035,
    22300,
    23600,
    24800,
    25750,
    27012,
    28241,
    29700,
    30398,
    31800,
    33003,
    34510,
    35470,
    36712,
    38002,
    39506,
    40324,
    41781,
    43002,
    44517,
    45750,
    47120,
    48572,
    50000,
    50787,
    52145,
    53820,
    55010,
    56516,
    58014,
    59896,
    60701,
    62340,
    64182,
    65712,
    67463,
    69420,
    70738,
    72616,
    74315,
    75710,
    77504,
    79543,
    81010,
    83015,
    85051,
    87262,
    89868,
    91701,
    93901,
    96081,
    98557,
    100236,
    102625,
    105097,
    107964,
    110263,
    113052,
    116000,
    119834,
    122500,
    125823,
    129800,
    133202,
    137332,
    141100,
    145946,
    150275,
    155030,
    160010,
    165412,
    171277,
    177664,
    184696,
    192817,
    201052,
    211350,
    223895,
    238006,
    253500,
    273850,
    299005,
    334009,
    386915,
    504420
  ]
}

export default incomeData