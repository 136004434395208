import styles from './GoalCard.module.css'
import { formatAmount } from "../../lib/utils/number";
import GoalIcon from "../GoalIcon";
import { GOAL_CATEGORIES } from "../../lib/constants";
import EmptyText from "../EmptyText";

const GoalCard = ({ category, amount }: { category: keyof typeof GOAL_CATEGORIES, amount?: number }) => {

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer + ' mb-2'}>
        <GoalIcon category={category} outlined={true} />
      </div>
      <div className='headline-5'>{amount ? formatAmount(amount, { short: true }) : <EmptyText textLength={8} />}</div>
    </div>
  )
}

export default GoalCard