import { Col, Container, Row } from "react-bootstrap"

const AuthCallback = () => {
  return <Container fluid>
    <Row>
      <Col>
        Auth callback
      </Col>
    </Row>
  </Container>
}

export default AuthCallback