import { stateIncomeTaxProgressiveConfig } from "../types";

const stateIncomeTaxProgressiveData: stateIncomeTaxProgressiveConfig = {
  "2021": {
    single: [
      { state: "California", min: 0, max: 8932, bracket: 1.00, floor: 0 },
      { state: "California", min: 8933, max: 21175, bracket: 2.00, floor: 90 },
      { state: "California", min: 21175, max: 33421, bracket: 4.00, floor: 335 },
      { state: "California", min: 33421, max: 46394, bracket: 6.00, floor: 825 },
      { state: "California", min: 46394, max: 58634, bracket: 8.00, floor: 1603 },
      { state: "California", min: 58634, max: 299508, bracket: 9.30, floor: 2583 },
      { state: "California", min: 299508, max: 359407, bracket: 10.3, floor: 24984 },
      { state: "California", min: 359407, max: 599012, bracket: 11.3, floor: 31154 },
      { state: "California", min: 599012, max: 1000000, bracket: 12.3, floor: 58229 },
      { state: "California", min: 1000000, max: 0, bracket: 13.3, floor: 107551 },
      { state: "New Jersey", min: 0, max: 20000, bracket: 1.40, floor: 0 },
      { state: "New Jersey", min: 20000, max: 35000, bracket: 1.75, floor: 280 },
      { state: "New Jersey", min: 35000, max: 40000, bracket: 3.50, floor: 543 },
      { state: "New Jersey", min: 40000, max: 75000, bracket: 5.53, floor: 718 },
      { state: "New Jersey", min: 75000, max: 500000, bracket: 6.37, floor: 2652 },
      { state: "New Jersey", min: 500000, max: 5000000, bracket: 8.97, floor: 29724 },
      { state: "New Jersey", min: 5000000, max: 0, bracket: 10.7, floor: 29724 },
      { state: "Arizona", min: 0, max: 27272, bracket: 2.59, floor: 0 },
      { state: "Arizona", min: 27272, max: 54544, bracket: 3.34, floor: 707 },
      { state: "Arizona", min: 54544, max: 163632, bracket: 4.17, floor: 1618 },
      { state: "Arizona", min: 163632, max: 250000, bracket: 4.50, floor: 6167 },
      { state: "Arizona", min: 250000, max: 0, bracket: 8.00, floor: 10054 },
      { state: "Washington, D.C.", min: 0, max: 10000, bracket: 4.00, floor: 0 },
      { state: "Washington, D.C.", min: 10000, max: 40000, bracket: 6.00, floor: 400 },
      { state: "Washington, D.C.", min: 40000, max: 60000, bracket: 6.50, floor: 2200 },
      { state: "Washington, D.C.", min: 60000, max: 350000, bracket: 8.50, floor: 3500 },
      { state: "Washington, D.C.", min: 350000, max: 1000000, bracket: 8.75, floor: 28150 },
      { state: "Washington, D.C.", min: 1000000, max: 0, bracket: 8.95, floor: 85025 },
      { state: "New York", min: 0, max: 8500, bracket: 4.00, floor: 0 },
      { state: "New York", min: 8500, max: 11700, bracket: 4.50, floor: 340 },
      { state: "New York", min: 11700, max: 13900, bracket: 5.25, floor: 484 },
      { state: "New York", min: 13900, max: 21400, bracket: 5.90, floor: 600 },
      { state: "New York", min: 21400, max: 80650, bracket: 5.97, floor: 1042 },
      { state: "New York", min: 80650, max: 215400, bracket: 6.33, floor: 4580 },
      { state: "New York", min: 215400, max: 1077550, bracket: 6.85, floor: 13110 },
      { state: "New York", min: 1077550, max: 0, bracket: 8.82, floor: 72167 },
      { state: "Ohio", min: 0, max: 22150, bracket: 0.00, floor: 0 },
      { state: "Ohio", min: 22150, max: 44250, bracket: 2.85, floor: 0 },
      { state: "Ohio", min: 44250, max: 88450, bracket: 3.33, floor: 630 },
      { state: "Ohio", min: 88450, max: 110650, bracket: 3.80, floor: 2101 },
      { state: "Ohio", min: 110650, max: 221300, bracket: 4.41, floor: 2945 },
      { state: "Ohio", min: 221300, max: 0, bracket: 4.80, floor: 7828 },
      { state: "Georgia", min: 0, max: 750, bracket: 1.00, floor: 0 },
      { state: "Georgia", min: 750, max: 2250, bracket: 2.00, floor: 11 },
      { state: "Georgia", min: 2250, max: 3750, bracket: 3.00, floor: 37 },
      { state: "Georgia", min: 3750, max: 5250, bracket: 4.00, floor: 90 },
      { state: "Georgia", min: 5250, max: 7000, bracket: 5.00, floor: 173 },
      { state: "Georgia", min: 7000, max: 0, bracket: 5.75, floor: 284 },
      { state: "Virginia", min: 0, max: 3000, bracket: 2.00, floor: 0 },
      { state: "Virginia", min: 3000, max: 5000, bracket: 3.00, floor: 60 },
      { state: "Virginia", min: 5000, max: 17000, bracket: 5.00, floor: 120 },
      { state: "Virginia", min: 17000, max: 0, bracket: 5.75, floor: 720 },
    ],
    married: [
      { state: "California", min: 0, max: 17864, bracket: 1.00, floor: 0 },
      { state: "California", min: 17864, max: 42350, bracket: 2.00, floor: 179 },
      { state: "California", min: 42350, max: 66842, bracket: 4.00, floor: 669 },
      { state: "California", min: 66843, max: 92788, bracket: 6.00, floor: 1649 },
      { state: "California", min: 92788, max: 117268, bracket: 8.00, floor: 3206 },
      { state: "California", min: 117268, max: 599016, bracket: 9.30, floor: 5165 },
      { state: "California", min: 599016, max: 718814, bracket: 10.30, floor: 49967 },
      { state: "California", min: 718814, max: 1000000, bracket: 11.30, floor: 62307 },
      { state: "California", min: 1000000, max: 1198024, bracket: 12.30, floor: 94081 },
      { state: "California", min: 1198024, max: 0, bracket: 13.30, floor: 118438 },
      { state: "New Jersey", min: 0, max: 20000, bracket: 1.40, floor: 0 },
      { state: "New Jersey", min: 20000, max: 50000, bracket: 1.75, floor: 280 },
      { state: "New Jersey", min: 50000, max: 70000, bracket: 3.50, floor: 805 },
      { state: "New Jersey", min: 70000, max: 80000, bracket: 5.53, floor: 1505 },
      { state: "New Jersey", min: 80000, max: 150000, bracket: 6.37, floor: 2058 },
      { state: "New Jersey", min: 150000, max: 500000, bracket: 8.97, floor: 6517 },
      { state: "New Jersey", min: 500000, max: 0, bracket: 10.75, floor: 37912 },
      { state: "Arizona", min: 0, max: 54544, bracket: 2.59, floor: 0 },
      { state: "Arizona", min: 54544, max: 109088, bracket: 3.34, floor: 1413 },
      { state: "Arizona", min: 109088, max: 327263, bracket: 4.17, floor: 3235 },
      { state: "Arizona", min: 327263, max: 500000, bracket: 4.50, floor: 12333 },
      { state: "Arizona", min: 500000, max: 0, bracket: 8.00, floor: 20107 },
      { state: "Washington D.C.", min: 0, max: 10000, bracket: 4.00, floor: 0 },
      { state: "Washington D.C.", min: 10000, max: 40000, bracket: 6.00, floor: 400 },
      { state: "Washington D.C.", min: 40000, max: 60000, bracket: 6.50, floor: 2200 },
      { state: "Washington D.C.", min: 60000, max: 350000, bracket: 8.50, floor: 3500 },
      { state: "Washington D.C.", min: 350000, max: 1000000, bracket: 8.75, floor: 28150 },
      { state: "Washington D.C.", min: 1000000, max: 0, bracket: 8.95, floor: 85025 },
      { state: "New York", min: 0, max: 17150, bracket: 4.00, floor: 0 },
      { state: "New York", min: 17150, max: 23600, bracket: 4.50, floor: 686 },
      { state: "New York", min: 23600, max: 27900, bracket: 5.25, floor: 977 },
      { state: "New York", min: 27900, max: 43000, bracket: 5.90, floor: 1203 },
      { state: "New York", min: 43000, max: 161550, bracket: 5.97, floor: 2094 },
      { state: "New York", min: 161550, max: 323200, bracket: 6.33, floor: 9171 },
      { state: "New York", min: 323200, max: 2155350, bracket: 6.85, floor: 19404 },
      { state: "New York", min: 2155350, max: 0, bracket: 8.82, floor: 144906 },
      { state: "Ohio", min: 0, max: 22150, bracket: 0.00, floor: 0 },
      { state: "Ohio", min: 22150, max: 44250, bracket: 2.85, floor: 0 },
      { state: "Ohio", min: 44250, max: 88450, bracket: 3.33, floor: 630 },
      { state: "Ohio", min: 88450, max: 110650, bracket: 3.80, floor: 2101 },
      { state: "Ohio", min: 110650, max: 221300, bracket: 4.41, floor: 2945 },
      { state: "Ohio", min: 221300, max: 0, bracket: 4.80, floor: 7828 },
      { state: "Georgia", min: 0, max: 1000, bracket: 1.00, floor: 0 },
      { state: "Georgia", min: 1000, max: 3000, bracket: 2.00, floor: 10 },
      { state: "Georgia", min: 3000, max: 5000, bracket: 3.00, floor: 50 },
      { state: "Georgia", min: 5000, max: 7000, bracket: 4.00, floor: 110 },
      { state: "Georgia", min: 7000, max: 10000, bracket: 5.00, floor: 190 },
      { state: "Georgia", min: 10000, max: 0, bracket: 5.75, floor: 340 },
      { state: "Virginia", min: 0, max: 3000, bracket: 2.00, floor: 0 },
      { state: "Virginia", min: 3000, max: 5000, bracket: 3.00, floor: 60 },
      { state: "Virginia", min: 5000, max: 17000, bracket: 5.00, floor: 120 },
      { state: "Virginia", min: 17000, max: 0, bracket: 5.75, floor: 720 },
    ],
  }
}

export default stateIncomeTaxProgressiveData