import { Button, Col, Container, Row } from "react-bootstrap"
import Elevator from "../../components/Elevator";
import { useAppSelector } from "../../store";
import DashboardCard from "../../components/DashboardCard";
import { incomeSum } from "../../lib/utils/income";
import { formatAmount } from "../../lib/utils/number";
import { expenseSum } from "../../lib/utils/expense";
import FlowChart from "../../components/FlowChart";
import styles from './Finances.module.css'
import CircleIcon from "../../components/CircleIcon";
import { ASSET_GROUPS, ASSET_TYPES, MEMBER_TYPES, TAX_TYPES } from "../../lib/constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { push } from "connected-react-router";
import { useDispatch } from "react-redux"
import { emptyStates, incomeNames, taxNames } from "../../lib/config/copy";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { capitalize } from "../../lib/utils/text";
import Benchmark from "../../components/Benchmark";
import useTax from "../../hooks/useTax";
import useBenchmarks from "../../hooks/useBenchmarks";
import { useMemo } from "react";
import { assetsSum } from "../../lib/utils/assets";
import Stacks from "../../components/Stacks";
import { cssValue } from "../../lib/utils/ui";
import { Asset } from "../../lib/types";
import useNetWorth from "../../hooks/useNetWorth";
import EmptyText from "../../components/EmptyText";
import EmptyState from "../../components/CTACard";
import ErrorCard from "../../components/ErrorCard";
import FluidWrapper from "../../components/FluidWrapper";
import EmptyStateImage from "../../components/EmptyStateImage";
import useScrollToAnchor from "../../hooks/useScrollToAnchor";
import useEmptyState from "../../hooks/useEmptyState";
import memberIcons from "../../lib/config/memberIcons";
import { memberAgeCategory } from "../../lib/utils/member";
import debtCategories from "../../lib/config/debtCategories";


const Finances = () => {
  const members = useAppSelector(state => state.members)
  const household = useAppSelector(state => state.household)
  const expenses = useAppSelector(state => state.expenses)
  const incomes = useAppSelector(state => state.incomes)
  const assets = useAppSelector((state) => state.assets)
  const debts = useAppSelector((state) => state.debts)

  const dispatch = useDispatch()
  const tax = useTax()
  const benchmarks = useBenchmarks()
  const emptyState = useEmptyState({ members, household, expenses, assets, debts })

  useScrollToAnchor()

  const activeMembers = useMemo(() => members.filter(member => [MEMBER_TYPES.PRIMARY, MEMBER_TYPES.PARTNER].includes(member.type)), [members])

  const savings = useMemo(() => incomeSum(incomes) - (expenseSum(expenses) + (tax?.total || 0)), [household, activeMembers, expenses, tax])

  const filteredTaxes = useMemo(() => {
    return tax?.details && Object.keys(tax.details).filter(key => tax.details[key] && key !== TAX_TYPES.fica)
  }, [tax])

  const incomeChartData = useMemo(() => {
    const { labels, data } = incomes.reduce((result, income) => {
      const member = income.memberId && members.find(member => member.id === income.memberId)
      result.labels.push((income.name && capitalize(income.name)) || member.firstName + '\'s ' + (incomeNames[income.type] || "income"))
      result.data.push(income.amount)
      return result
    }, { labels: [], data: [] })

    return {
      labels,
      datasets: [{
        label: '',
        data,
        backgroundColor: '#95C8AD',
      }]
    }
  }, [activeMembers, household])

  const taxChartData = useMemo(() => ({
    labels: Object.keys(tax?.details || [])
      .filter(_tax => _tax !== TAX_TYPES.fica)
      .map(_tax => capitalize(taxNames[_tax]) || 'Tax'),
    datasets: [{
      label: '',
      data: Object.entries(tax?.details || {})
        .filter(item => item[0] !== TAX_TYPES.fica)
        .map(item => Math.floor(item[1])),
      backgroundColor: '#EB8D99',
    }]
  }), [tax])

  const expenseChartData = useMemo(() => ({
    labels: expenses.filter(expense => expense.amount).map(expense => expense.name),
    datasets: [{
      label: '',
      data: expenses.filter(expense => expense.amount).map(expense => expense.amount),
      backgroundColor: '#EB8D99'
    }]
  }), [expenses])

  const netWorthData = useNetWorth({ assets, debts })

  const assetsSortedByValue = useMemo(() => {
    return assets.filter(asset => asset.value).slice().sort((a: Asset, b: Asset) =>
      a.value > b.value ? -1 : 1)
  }, [assets])

  const assetsSortedByType = useMemo(() => {
    return assets.filter(asset => asset.value).slice().sort((a: Asset, b: Asset) =>
      a.category < b.category ? -1 : 1)
  }, [assets])

  const assetChartData = useMemo(() => {
    const assetChartColors = {
      [ASSET_TYPES.CASH_AND_EQUIVALENTS]: cssValue('--sky'),
      [ASSET_TYPES.PROPERTY]: cssValue('--sky-dark'),
      [ASSET_TYPES.INVESTMENT]: cssValue('--sky-light')
    }
    return {
      labels: assetsSortedByType.map(asset => asset.name),
      datasets: [{
        label: '',
        data: assetsSortedByType.map(asset => asset.value),
        backgroundColor: assetsSortedByType.map(asset => {
          const assetType = Object.keys(ASSET_GROUPS).find(group => ASSET_GROUPS[group].includes(asset.category)) || ''
          return assetChartColors[assetType]
        })
      }]
    }
  }, [assetsSortedByType])

  const debtChartData = useMemo(() => ({
    labels: debts.filter(debt => debt.outstandingLoanBalance).map(debt => debt.name || debtCategories?.[debt.category]?.title),
    datasets: [{
      label: '',
      data: debts.filter(debt => debt.outstandingLoanBalance).map(debt => debt.outstandingLoanBalance),
      backgroundColor: cssValue('--red-light'),
    }]
  }), [debts])


  if (!activeMembers || !household || !expenses || !tax) return <></>

  return <Container fluid>
    <Row>
      <Col>
        <Elevator>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center'>
              <div className='fw-bold subtitle-1'>My Household</div>
              {members && (
                <div className='d-flex gap-2 ms-3'>
                  {members.map(member => (
                    <CircleIcon
                      key={member.id}
                      color={member.color}
                      footerIcon={memberIcons[memberAgeCategory(new Date().getFullYear(), member.yearOfBirth)]}
                    >
                      {member.firstName?.[0] || ""}
                    </CircleIcon>
                  ))}
                </div>
              )}
            </div>
            <Button onClick={() => dispatch(push("/household"))} size='sm' className='d-none d-sm-block fw-semi-bold'>Edit household and details</Button>
            <FontAwesomeIcon icon={["fas", "pencil"]} className='d-sm-none clickable' onClick={() => dispatch(push('/household'))} />

          </div>
        </Elevator>
        <Elevator>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='fw-bold subtitle-1'>My cash flow</div>
            <FontAwesomeIcon icon={["fas", "pencil"]} className='clickable' onClick={() => dispatch(push('/household#income'))} />
          </div>
          <DashboardCard
            title='Annual savings potential'
            description={(emptyState?.incomes || emptyState?.expenses) ? (
              <EmptyText textLength={16} />
            ) : (
              <>{formatAmount(savings)}</>
            )}
          >
            <Row>
              <Col md='6'>
                {emptyState?.incomes || emptyState?.expenses ? (
                  <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <EmptyStateImage image='/savingsEmptystate.png' />
                  </div>
                ) : (
                  <div className="p-3 p-sm-5">
                    <FluidWrapper>
                      <FlowChart inflow={incomeSum(incomes)} outflow={expenseSum(expenses) + (tax?.total || 0)} />
                    </FluidWrapper>
                  </div>
                )}
              </Col>
              <Col md='6'>
                {emptyState?.incomes || emptyState?.expenses ? (
                  <div className='d-flex h-100 align-items-center justify-content-center'>
                    <EmptyState
                      title={emptyStates.savings.title}
                      cta={emptyStates.savings.cta}
                      action={() => dispatch(push(emptyState?.incomes ? '/household#income' : '/household#expenses'))}
                      primary={emptyState?.incomes && emptyState?.expenses}
                    >
                      <div>You will be able to see your savings here once you fill out all the details in <span onClick={() => dispatch(push('/household'))} className='text-decoration-underline clickable'>My Household</span></div>
                    </EmptyState>
                  </div>
                ) : (
                  <div className="p-3">
                    <div className='d-flex justify-content-between align-items-end mb-4'>
                      <div className={styles.breakdownTitle}>Savings breakdown</div>
                      <div className={styles.breakdownItem}>/year</div>
                    </div>
                    <div className={`${styles.breakdownItem} d-flex justify-content-between mb-2`}>
                      <div>Income</div>
                      <div className="text-income">{formatAmount(incomeSum(incomes))}</div>
                    </div>
                    <div className={`${styles.breakdownItem} d-flex justify-content-between mb-2`}>
                      <div>Taxes</div>
                      <div className="text-expense">{'-' + formatAmount(tax?.total || 0)}</div>
                    </div>
                    <div className={`${styles.breakdownItem} d-flex justify-content-between mb-2`}>
                      <div>Expenses</div>
                      <div className="text-expense">{'-' + formatAmount(expenseSum(expenses))}</div>
                    </div>
                    <hr className='mb-2' />
                    <div className={`${styles.breakdownItem} d-flex justify-content-between mb-2`}>
                      <div>Savings</div>
                      <div className="text-saving">{formatAmount(savings)}</div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </DashboardCard>
          {emptyState?.incomes && emptyState?.expenses ? (
            <></>
          ) : (
            <div className={styles.chartContainer}>
              <div className="d-flex" style={{ flex: "33.3333%", alignItems: "stretch" }}>
                <DashboardCard title='Inflow' description='Incomes'>
                  <div className='d-flex flex-column justify-content-between h-100 p-3'>
                    <div className="mb-3">
                      <div className='d-flex justify-content-between align-items-end'>
                        <div className={styles.breakdownTitle}>Incomes breakdown</div>
                        <div className={styles.breakdownItem}>/year</div>
                      </div>
                      <div className='d-flex justify-content-center align-items-center p-5'>
                        <div className={styles.chart}>
                          <div className={[styles.chartSum, 'text-income headline-4'].join(" ")}>
                            {formatAmount(incomeSum(incomes), { short: true })}
                          </div>
                          <Chart
                            type='doughnut'
                            data={incomeChartData}
                            options={{
                              plugins:
                                { legend: { display: false }, tooltip: { enabled: true } },
                              cutout: "65%",
                              aspectRatio: 1,
                              responsive: true,
                            }}
                          />
                        </div>
                      </div>
                      {activeMembers.map((member) => (
                        <div key={"incomes_" + member.id}>
                          {incomes.filter(income => income.memberId === member.id).map(income => (
                            income.amount !== 0 && (
                              <div key={income.type + member.id} className='d-flex justify-content-between align-items-end small fw-bold mt-3'>
                                <div className={styles.breakdownItem}>
                                  {(income.name && capitalize(income.name)) || member.firstName + '\'s ' + (incomeNames[income.type] || "income")}
                                </div>
                                <div>{formatAmount(income.amount)}</div>
                              </div>
                            )
                          ))}
                        </div>
                      ))}
                      {incomes.filter(income => !income.memberId).filter(income => income.amount).map(income => (
                        <div key={income.id} className='d-flex justify-content-between align-items-end small fw-bold mt-3'>
                          <div className={styles.breakdownItem}>
                            {capitalize(income.name)}
                          </div>
                          <div>{formatAmount(income.amount)}</div>
                        </div>
                      ))}
                      <Button
                        variant='outline-secondary'
                        className='w-100 mt-3 caption-bold text-black'
                        onClick={() => dispatch(push("/household#income"))}
                      >
                        Add missing income
                      </Button>
                    </div>
                    <div className="py-3">
                      <Benchmark feedback={benchmarks?.income} />
                    </div>
                  </div>
                </DashboardCard>
              </div>
              <div className="d-flex" style={{ flexBasis: "66.6666%" }}>
                <DashboardCard title='Outflow' description='Taxes and expenses'>
                  <Row className="py-3 h-100">
                    <Col md='6' className={styles.divider}>
                      <div className='d-flex flex-column justify-content-between h-100 px-3'>
                        <div className="mb-0 mb-md-3">
                          <div className='d-flex justify-content-between align-items-end'>
                            <div className={styles.breakdownTitle}>Taxes breakdown</div>
                            <div className={styles.breakdownItem}>/year</div>
                          </div>
                          <div className='p-5 d-flex justify-content-center align-items-center'>
                            <div className={styles.chart}>
                              <div className={[styles.chartSum, 'text-expense headline-4'].join(" ")}>
                                {formatAmount(tax.total || 0, { short: true })}
                              </div>
                              <Chart
                                type='doughnut'
                                data={taxChartData}
                                options={{
                                  plugins:
                                    { legend: { display: false }, tooltip: { enabled: true } },
                                  cutout: "65%",
                                  aspectRatio: 1,
                                  responsive: true
                                }}
                              />
                            </div>
                          </div>
                          {filteredTaxes?.map((taxType, index) => (
                            <div key={taxType + '_' + index} className='d-flex justify-content-between align-items-end small fw-bold mt-3'>
                              <div className={styles.breakdownItem}>
                                {household.state && taxType === TAX_TYPES.state && (
                                  household.state + ' ' + taxNames[taxType]
                                )}
                                {household.city && taxType === TAX_TYPES.local && household.city !== 'Other' && (
                                  household.city + ' ' + taxNames[taxType]
                                )}
                                {!([TAX_TYPES.state, TAX_TYPES.local].includes(taxType)) && capitalize(taxNames[taxType])}
                              </div>
                              <div>{tax.details[taxType] ? formatAmount(tax.details[taxType]) : "-"}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Col>
                    <Col className='d-block d-md-none'>
                      <div className='p-3'>
                        <hr />
                      </div>
                    </Col>
                    <Col md='6'>
                      <div className='d-flex flex-column justify-content-between h-100 px-3'>
                        <div className="mb-3">
                          <div className='d-flex justify-content-between align-items-end'>
                            <div className={styles.breakdownTitle}>Expenses breakdown</div>
                            <div className={styles.breakdownItem}>/year</div>
                          </div>
                          <div className='p-5 d-flex justify-content-center align-items-center'>
                            <div className={styles.chart}>
                              <div className={[styles.chartSum, 'text-expense headline-4'].join(" ")}>
                                {formatAmount(expenseSum(expenses), { short: true })}
                              </div>
                              <Chart
                                type='doughnut'
                                data={expenseChartData}
                                options={{
                                  plugins:
                                    { legend: { display: false }, tooltip: { enabled: true } },
                                  cutout: "65%",
                                  aspectRatio: 1,
                                  responsive: true
                                }}
                              />
                            </div>
                          </div>
                          {expenses && expenses.filter(expense => expense.amount).map((expense, index) => (
                            <div key={expense + '_' + index} className='d-flex justify-content-between align-items-end small fw-bold mt-3'>
                              <div className={styles.breakdownItem}>{capitalize(expense.name)}</div>
                              <div>{formatAmount(expense.amount)}</div>
                            </div>
                          ))}
                          <Button
                            variant='outline-secondary'
                            className='w-100 mt-3 caption-bold text-black'
                            onClick={() => dispatch(push("/household#expenses"))}
                          >
                            Add missing expense
                          </Button>
                        </div>
                        {/*<div className='py-3'>*/}
                        {/*  <Benchmark feedback={benchmarks?.expense} />*/}
                        {/*</div>*/}
                      </div>
                    </Col>
                  </Row>
                </DashboardCard>
              </div>
            </div>
          )}
          <DashboardCard id='net-worth' title='Assets and debt' description='Your Net Worth'>
            <Row className="py-0 py-sm-3">
              {emptyState?.assets && emptyState?.debts ? (
                <>
                  <Col md='6'>
                    <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                      <EmptyStateImage image='/networthEmptystate.png' />
                    </div>
                  </Col>
                  <Col md='6'>
                    <div className='d-flex h-100 align-items-center justify-content-center'>
                      <EmptyState
                        title={emptyStates.netWorth.title}
                        cta={emptyStates.netWorth.cta}
                        action={() => dispatch(push('/household#assets'))}
                        primary={(!emptyState?.incomes && !emptyState?.expenses) && (emptyState?.assets && emptyState?.debts)}
                      >
                        <div>We will display what you own compared to what you owe here.</div>
                      </EmptyState>
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Col md='4' className={styles.divider}>
                    <div className='h-100 py-3 px-3 px-lg-5'>
                      <div className='subtitle-1 text-gray-60 fw-semi-bold'>Net Worth</div>
                      <div className={`${netWorthData && netWorthData.netWorth < 0 ? 'text-red' : ''} headline-4`}>{formatAmount(netWorthData?.netWorth || 0)}</div>
                      {(assets.length > 0 || debts.length > 0) && (
                        <>
                          {netWorthData && netWorthData.netWorth < 0 ? (
                            <div className='py-4'>
                              <ErrorCard
                                title='Your Net Worth is negative'
                                cta='Check data'
                                action={() => dispatch(push('/household'))}
                              >
                                <div className='mb-1'>
                                  According to your inputs your current Net Worth is negative, this means you owe more than you own.
                                </div>
                                <div>
                                  Please check in <span className='text-decoration-underline clickable' onClick={() => dispatch(push('/household'))}>My household</span> if your Asset and Liabilities details are correct.
                                </div>
                              </ErrorCard>
                            </div>
                          ) : (
                            <div className='d-flex justify-content-center d-lg-block'>
                              <div className={styles.stackContainer}>
                                <FluidWrapper>
                                  <Stacks cols={netWorthData?.stacks} />
                                </FluidWrapper>
                              </div>
                            </div>
                          )}
                          <div className='d-flex flex-column gap-3'>
                            <div className='d-flex justify-content-between subtitle-2'>
                              <div className='text-gray-60'>Total assets</div>
                              <div>{formatAmount(assetsSum(assets), { short: true })}</div>
                            </div>
                            <div className='d-flex justify-content-between subtitle-2'>
                              <div className='text-gray-60 position-relative'>
                                <div className={styles.minusSign + ' text-gray-60'}>-</div>
                                Total debt
                              </div>
                              <div>{formatAmount(netWorthData?.totalDebts || 0, { short: true })}</div>
                            </div>
                            <hr className='m-0' />
                            <div className='d-flex justify-content-between subtitle-2 fw-bold'>
                              <div className='text-gray-80'>Net worth</div>
                              <div>{formatAmount(netWorthData?.netWorth || 0, { short: true })}</div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col className='d-block d-md-none'>
                    <div className='p-3'>
                      <hr />
                    </div>
                  </Col>
                  <Col md='4' className={styles.divider}>
                    <div className='h-100 py-3 px-3 px-lg-5'>
                      <div className='subtitle-1 text-gray-60 fw-semi-bold'>Total assets</div>
                      <div className='px-5 px-md-3 py-5 d-flex justify-content-center align-items-center'>
                        <div className={styles.chart}>
                          <div className={[styles.chartSum, 'text-saving headline-4'].join(" ")}>
                            {formatAmount(assetsSum(assets), { short: true })}
                          </div>
                          <Chart
                            type='doughnut'
                            data={assetChartData}
                            options={{
                              plugins:
                                { legend: { display: false }, tooltip: { enabled: true } },
                              cutout: "65%",
                              aspectRatio: 1,
                              responsive: true
                            }}
                          />
                        </div>
                      </div>
                      <div className='d-flex flex-column gap-3 subtitle-2'>
                        {assetsSortedByValue.map((asset, index) => (
                          <div key={index} className='d-flex justify-content-between subtitle-2'>
                            <div className='text-gray-60'>{asset.name}</div>
                            <div>{formatAmount(asset.value, { short: true })}</div>
                          </div>
                        ))}
                      </div>
                      <Button
                        variant='outline-secondary'
                        className='w-100 mt-5 caption-bold text-black'
                        onClick={() => dispatch(push("/household#assets"))}
                      >
                        Add missing asset
                      </Button>
                    </div>
                  </Col>
                  <Col className='d-block d-md-none'>
                    <div className='p-3'>
                      <hr />
                    </div>
                  </Col>
                  <Col md='4'>
                    <div className='h-100 py-3 px-3 px-lg-5'>
                      <div className='subtitle-1 text-gray-60 fw-semi-bold'>Total debt</div>
                      <div className='px-5 px-md-3 py-5 d-flex justify-content-center align-items-center'>
                        <div className={styles.chart}>
                          <div className={[styles.chartSum, 'text-expense headline-4'].join(" ")}>
                            {formatAmount(netWorthData?.totalDebts || 0, { short: true })}
                          </div>
                          <Chart
                            type='doughnut'
                            data={debtChartData}
                            options={{
                              plugins:
                                { legend: { display: false }, tooltip: { enabled: true } },
                              cutout: "65%",
                              aspectRatio: 1,
                              responsive: true
                            }}
                          />
                        </div>
                      </div>
                      <div className='d-flex flex-column gap-3 subtitle-2'>
                        {debts.filter(debt => debt.outstandingLoanBalance).map((debt, index) => (
                          <div key={index} className='d-flex justify-content-between subtitle-2'>
                            <div className='text-gray-60'>{debt.name}</div>
                            <div>{formatAmount(debt.outstandingLoanBalance, { short: true })}</div>
                          </div>
                        ))}
                      </div>
                      <Button
                        variant='outline-secondary'
                        className='w-100 mt-5 caption-bold text-black'
                        onClick={() => dispatch(push("/household#debts"))}
                      >
                        Add missing debt
                      </Button>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </DashboardCard>
        </Elevator>
      </Col>
    </Row>
  </Container>
}

export default Finances