import Assets from "./Assets"
import Debts from "./Debts"
import Expenses from "./Expenses"
import Incomes from "./Incomes"
import HouseholdAndMembers from "./HouseholdAndMembers"
import styles from "./Household.module.css"
import Sidebar from "../../components/Household/Sidebar"
import { useState, useEffect, useRef } from "react"
import { useAppSelector } from "../../store"
import { throttle } from "throttle-debounce"
import useScrollToAnchor from "../../hooks/useScrollToAnchor"
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const Household = () => {

  const { household, members, expenses, incomes, assets, debts, router } = useAppSelector((state) => ({
    household: state.household,
    members: state.members,
    expenses: state.expenses,
    incomes: state.incomes,
    assets: state.assets,
    debts: state.debts,
    router: state.router
  }))

  const [active, setActive] = useState<string>("")

  const householdRef = useRef<HTMLDivElement>(null)
  const incomeRef = useRef<HTMLDivElement>(null)
  const expensesRef = useRef<HTMLDivElement>(null)
  const assetsRef = useRef<HTMLDivElement>(null)
  const debtsRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const refs = [householdRef, incomeRef, expensesRef, assetsRef, debtsRef]
    const updateActive = throttle(100, () => {
      const nextActive = refs
        .sort((a, b) =>
          Math.abs(a.current?.getBoundingClientRect().top || 0) - Math.abs(b.current?.getBoundingClientRect().top || 0))[0]
      const hash = nextActive?.current?.getAttribute("id") || ""
      if (hash !== active) setActive(hash)
    })
    document.addEventListener("scroll", updateActive, true)
    return () => document.removeEventListener("scroll", updateActive, true)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const hash = router?.location?.hash?.replace('#', '')
    if (hash && active && hash !== active) dispatch(push('/household'))
    // eslint-disable-next-line
  }, [active])


  useScrollToAnchor()

  return (
    <div>
      <div className={styles.sidebarContainer}>
        <Sidebar active={active} />
      </div>
      <div className={styles.main}>
        <div className="mb-5" id="household" ref={householdRef}>
          <HouseholdAndMembers household={household} members={members} />
        </div>
        <hr className="mb-0" />
        <div className={["mb-5", styles.sectionContainer].join(' ')} id="income" ref={incomeRef}>
          <Incomes members={members} incomes={incomes} />
        </div>
        <hr className="mb-0" />
        <div className={["mb-5", styles.sectionContainer].join(' ')} id="expenses" ref={expensesRef}>
          <Expenses expenses={expenses} />
        </div>
        <hr className="mb-0" />
        <div className={["mb-5", styles.sectionContainer].join(' ')} id="assets" ref={assetsRef}>
          <Assets assets={assets} />
        </div>
        <hr className="mb-0" />
        <div className={["min-vh-100", styles.sectionContainer].join(' ')} id="debts" ref={debtsRef}>
          <Debts debts={debts} />
        </div>
      </div>
    </div>
  )
}

export default Household
