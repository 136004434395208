import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./Group.module.css"

const Group = (props: {
  title: string
  subtitle: string
  children: React.ReactNode
  onAdd?: (any) => any
}) => {
  return <div className={styles.container}>
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="headline-6">{props.title}</div>
        <FontAwesomeIcon icon={["fas", "plus"]} className="text-cta clickable" onClick={props.onAdd} />
      </div>
      <div className="caption-small mb-2">{props.subtitle}</div>
    </div>
    <div className="d-flex flex-column gap-2">
      {props.children}
    </div>
  </div>
}

export default Group