/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createHousehold = /* GraphQL */ `
  mutation CreateHousehold(
    $input: CreateHouseholdInput!
    $condition: ModelHouseholdConditionInput
  ) {
    createHousehold(input: $input, condition: $condition) {
      id
      year
      city
      state
      members {
        items {
          id
          householdId
          type
          color
          firstName
          lastName
          yearOfBirth
          createdAt
          updatedAt
        }
        nextToken
      }
      incomes {
        items {
          id
          householdId
          memberId
          type
          name
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
      expenses {
        items {
          id
          category
          amount
          frequency
          years
          name
          householdId
          createdAt
          updatedAt
        }
        nextToken
      }
      debts {
        items {
          id
          householdId
          category
          outstandingLoanBalance
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          householdId
          category
          value
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      goals {
        items {
          id
          householdId
          category
          name
          year
          amount
          downPaymentPercentage
          closingCostsPercentage
          createdAt
          updatedAt
        }
        nextToken
      }
      effects {
        items {
          id
          householdId
          goalId
          name
          year
          activeYears
          category
          subject
          operation
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
      loanEffects {
        items {
          id
          householdId
          goalId
          name
          startYear
          activeYears
          total
          annualPercentageRate
          createdAt
          updatedAt
        }
        nextToken
      }
      relocationEffects {
        items {
          id
          householdId
          goalId
          name
          year
          state
          city
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateHousehold = /* GraphQL */ `
  mutation UpdateHousehold(
    $input: UpdateHouseholdInput!
    $condition: ModelHouseholdConditionInput
  ) {
    updateHousehold(input: $input, condition: $condition) {
      id
      year
      city
      state
      members {
        items {
          id
          householdId
          type
          color
          firstName
          lastName
          yearOfBirth
          createdAt
          updatedAt
        }
        nextToken
      }
      incomes {
        items {
          id
          householdId
          memberId
          type
          name
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
      expenses {
        items {
          id
          category
          amount
          frequency
          years
          name
          householdId
          createdAt
          updatedAt
        }
        nextToken
      }
      debts {
        items {
          id
          householdId
          category
          outstandingLoanBalance
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          householdId
          category
          value
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      goals {
        items {
          id
          householdId
          category
          name
          year
          amount
          downPaymentPercentage
          closingCostsPercentage
          createdAt
          updatedAt
        }
        nextToken
      }
      effects {
        items {
          id
          householdId
          goalId
          name
          year
          activeYears
          category
          subject
          operation
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
      loanEffects {
        items {
          id
          householdId
          goalId
          name
          startYear
          activeYears
          total
          annualPercentageRate
          createdAt
          updatedAt
        }
        nextToken
      }
      relocationEffects {
        items {
          id
          householdId
          goalId
          name
          year
          state
          city
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteHousehold = /* GraphQL */ `
  mutation DeleteHousehold(
    $input: DeleteHouseholdInput!
    $condition: ModelHouseholdConditionInput
  ) {
    deleteHousehold(input: $input, condition: $condition) {
      id
      year
      city
      state
      members {
        items {
          id
          householdId
          type
          color
          firstName
          lastName
          yearOfBirth
          createdAt
          updatedAt
        }
        nextToken
      }
      incomes {
        items {
          id
          householdId
          memberId
          type
          name
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
      expenses {
        items {
          id
          category
          amount
          frequency
          years
          name
          householdId
          createdAt
          updatedAt
        }
        nextToken
      }
      debts {
        items {
          id
          householdId
          category
          outstandingLoanBalance
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          householdId
          category
          value
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      goals {
        items {
          id
          householdId
          category
          name
          year
          amount
          downPaymentPercentage
          closingCostsPercentage
          createdAt
          updatedAt
        }
        nextToken
      }
      effects {
        items {
          id
          householdId
          goalId
          name
          year
          activeYears
          category
          subject
          operation
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
      loanEffects {
        items {
          id
          householdId
          goalId
          name
          startYear
          activeYears
          total
          annualPercentageRate
          createdAt
          updatedAt
        }
        nextToken
      }
      relocationEffects {
        items {
          id
          householdId
          goalId
          name
          year
          state
          city
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createIncome = /* GraphQL */ `
  mutation CreateIncome(
    $input: CreateIncomeInput!
    $condition: ModelIncomeConditionInput
  ) {
    createIncome(input: $input, condition: $condition) {
      id
      householdId
      memberId
      type
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const updateIncome = /* GraphQL */ `
  mutation UpdateIncome(
    $input: UpdateIncomeInput!
    $condition: ModelIncomeConditionInput
  ) {
    updateIncome(input: $input, condition: $condition) {
      id
      householdId
      memberId
      type
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const deleteIncome = /* GraphQL */ `
  mutation DeleteIncome(
    $input: DeleteIncomeInput!
    $condition: ModelIncomeConditionInput
  ) {
    deleteIncome(input: $input, condition: $condition) {
      id
      householdId
      memberId
      type
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      householdId
      type
      color
      firstName
      lastName
      yearOfBirth
      createdAt
      updatedAt
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      householdId
      type
      color
      firstName
      lastName
      yearOfBirth
      createdAt
      updatedAt
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      householdId
      type
      color
      firstName
      lastName
      yearOfBirth
      createdAt
      updatedAt
    }
  }
`;
export const createExpense = /* GraphQL */ `
  mutation CreateExpense(
    $input: CreateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    createExpense(input: $input, condition: $condition) {
      id
      category
      amount
      frequency
      years
      name
      householdId
      createdAt
      updatedAt
    }
  }
`;
export const updateExpense = /* GraphQL */ `
  mutation UpdateExpense(
    $input: UpdateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    updateExpense(input: $input, condition: $condition) {
      id
      category
      amount
      frequency
      years
      name
      householdId
      createdAt
      updatedAt
    }
  }
`;
export const deleteExpense = /* GraphQL */ `
  mutation DeleteExpense(
    $input: DeleteExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    deleteExpense(input: $input, condition: $condition) {
      id
      category
      amount
      frequency
      years
      name
      householdId
      createdAt
      updatedAt
    }
  }
`;
export const createDebt = /* GraphQL */ `
  mutation CreateDebt(
    $input: CreateDebtInput!
    $condition: ModelDebtConditionInput
  ) {
    createDebt(input: $input, condition: $condition) {
      id
      householdId
      category
      outstandingLoanBalance
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateDebt = /* GraphQL */ `
  mutation UpdateDebt(
    $input: UpdateDebtInput!
    $condition: ModelDebtConditionInput
  ) {
    updateDebt(input: $input, condition: $condition) {
      id
      householdId
      category
      outstandingLoanBalance
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteDebt = /* GraphQL */ `
  mutation DeleteDebt(
    $input: DeleteDebtInput!
    $condition: ModelDebtConditionInput
  ) {
    deleteDebt(input: $input, condition: $condition) {
      id
      householdId
      category
      outstandingLoanBalance
      name
      createdAt
      updatedAt
    }
  }
`;
export const createAsset = /* GraphQL */ `
  mutation CreateAsset(
    $input: CreateAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    createAsset(input: $input, condition: $condition) {
      id
      householdId
      category
      value
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateAsset = /* GraphQL */ `
  mutation UpdateAsset(
    $input: UpdateAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    updateAsset(input: $input, condition: $condition) {
      id
      householdId
      category
      value
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteAsset = /* GraphQL */ `
  mutation DeleteAsset(
    $input: DeleteAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    deleteAsset(input: $input, condition: $condition) {
      id
      householdId
      category
      value
      name
      createdAt
      updatedAt
    }
  }
`;
export const createGoal = /* GraphQL */ `
  mutation CreateGoal(
    $input: CreateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    createGoal(input: $input, condition: $condition) {
      id
      householdId
      category
      name
      year
      amount
      downPaymentPercentage
      closingCostsPercentage
      createdAt
      updatedAt
    }
  }
`;
export const updateGoal = /* GraphQL */ `
  mutation UpdateGoal(
    $input: UpdateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    updateGoal(input: $input, condition: $condition) {
      id
      householdId
      category
      name
      year
      amount
      downPaymentPercentage
      closingCostsPercentage
      createdAt
      updatedAt
    }
  }
`;
export const deleteGoal = /* GraphQL */ `
  mutation DeleteGoal(
    $input: DeleteGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    deleteGoal(input: $input, condition: $condition) {
      id
      householdId
      category
      name
      year
      amount
      downPaymentPercentage
      closingCostsPercentage
      createdAt
      updatedAt
    }
  }
`;
export const createEffect = /* GraphQL */ `
  mutation CreateEffect(
    $input: CreateEffectInput!
    $condition: ModelEffectConditionInput
  ) {
    createEffect(input: $input, condition: $condition) {
      id
      householdId
      goalId
      name
      year
      activeYears
      category
      subject
      operation
      amount
      createdAt
      updatedAt
    }
  }
`;
export const updateEffect = /* GraphQL */ `
  mutation UpdateEffect(
    $input: UpdateEffectInput!
    $condition: ModelEffectConditionInput
  ) {
    updateEffect(input: $input, condition: $condition) {
      id
      householdId
      goalId
      name
      year
      activeYears
      category
      subject
      operation
      amount
      createdAt
      updatedAt
    }
  }
`;
export const deleteEffect = /* GraphQL */ `
  mutation DeleteEffect(
    $input: DeleteEffectInput!
    $condition: ModelEffectConditionInput
  ) {
    deleteEffect(input: $input, condition: $condition) {
      id
      householdId
      goalId
      name
      year
      activeYears
      category
      subject
      operation
      amount
      createdAt
      updatedAt
    }
  }
`;
export const createLoanEffect = /* GraphQL */ `
  mutation CreateLoanEffect(
    $input: CreateLoanEffectInput!
    $condition: ModelLoanEffectConditionInput
  ) {
    createLoanEffect(input: $input, condition: $condition) {
      id
      householdId
      goalId
      name
      startYear
      activeYears
      total
      annualPercentageRate
      createdAt
      updatedAt
    }
  }
`;
export const updateLoanEffect = /* GraphQL */ `
  mutation UpdateLoanEffect(
    $input: UpdateLoanEffectInput!
    $condition: ModelLoanEffectConditionInput
  ) {
    updateLoanEffect(input: $input, condition: $condition) {
      id
      householdId
      goalId
      name
      startYear
      activeYears
      total
      annualPercentageRate
      createdAt
      updatedAt
    }
  }
`;
export const deleteLoanEffect = /* GraphQL */ `
  mutation DeleteLoanEffect(
    $input: DeleteLoanEffectInput!
    $condition: ModelLoanEffectConditionInput
  ) {
    deleteLoanEffect(input: $input, condition: $condition) {
      id
      householdId
      goalId
      name
      startYear
      activeYears
      total
      annualPercentageRate
      createdAt
      updatedAt
    }
  }
`;
export const createRelocationEffect = /* GraphQL */ `
  mutation CreateRelocationEffect(
    $input: CreateRelocationEffectInput!
    $condition: ModelRelocationEffectConditionInput
  ) {
    createRelocationEffect(input: $input, condition: $condition) {
      id
      householdId
      goalId
      name
      year
      state
      city
      createdAt
      updatedAt
    }
  }
`;
export const updateRelocationEffect = /* GraphQL */ `
  mutation UpdateRelocationEffect(
    $input: UpdateRelocationEffectInput!
    $condition: ModelRelocationEffectConditionInput
  ) {
    updateRelocationEffect(input: $input, condition: $condition) {
      id
      householdId
      goalId
      name
      year
      state
      city
      createdAt
      updatedAt
    }
  }
`;
export const deleteRelocationEffect = /* GraphQL */ `
  mutation DeleteRelocationEffect(
    $input: DeleteRelocationEffectInput!
    $condition: ModelRelocationEffectConditionInput
  ) {
    deleteRelocationEffect(input: $input, condition: $condition) {
      id
      householdId
      goalId
      name
      year
      state
      city
      createdAt
      updatedAt
    }
  }
`;
