import SidebarPanelTitle from "./SidebarPanelTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SidebarPanel from "./SidebarPanel";
import { Goal, TimelineResult } from "../../lib/modules/timeline/types";
import Tooltip from "../Tooltip";

const SavingsTargets = ({ timeline, goals, className }: { timeline: TimelineResult, goals: Goal[], className?: string }) => {

  if (goals.length === 0) return <></>

  return (
    <div className={className || ""}>
      <SidebarPanel>
        <SidebarPanelTitle>
          <div className='d-flex align-items-center justify-content-between body-small-bold'>
            <div>Your savings targets</div>
            <FontAwesomeIcon icon={['fas', 'pennant']} />
          </div>
        </SidebarPanelTitle>
        <div className='p-3'>
          <div className='d-flex mb-2'>
            <div className='w-50'>
              <div className='caption-bold text-gray-60'>On track</div>
              <div className='body-bold'>{goals.filter(goal => timeline.goals?.[goal.id]?.reached).length}</div>
            </div>
            {goals.filter(goal => !timeline.goals?.[goal.id]?.reached).length > 0 && (
              <div className='w-50'>
                <div className='caption-bold text-gray-60'>Needs attention</div>
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon className='text-red me-1' icon={['fas', 'triangle-exclamation']} />
                  <div className='body-bold'>{goals.filter(goal => !timeline.goals?.[goal.id]?.reached).length}</div>
                </div>
              </div>
            )}
          </div>
          <div className='d-flex justify-content-between align-items-center bg-elevation-1 rounded p-2 caption-bold text-gray-60'>
            <div>Assumed return rate on investments <span className='text-black'>6-9%</span></div>
            <Tooltip>
              Based on historical market performance. While that’s not a guarantee, it’s a reasonable starting point.
            </Tooltip>
          </div>
        </div>
      </SidebarPanel>
    </div>
  )
}

export default SavingsTargets