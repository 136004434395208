import { useAppSelector } from "../store";
import { useEffect, useState } from "react";
import { Benchmarks } from "../lib/modules/benchmarks/types";
import { Expense, Household, Income, Member } from "../lib/types";
import { incomeSum } from "../lib/utils/income";
import { expenseSum } from "../lib/utils/expense";
import useTax from "./useTax";
import { benchmarks } from "../lib/modules/benchmarks";

const useBenchmarks = () => {
  const [result, setResult] = useState<Benchmarks | null>(null)
  const {
    household,
    members,
    incomes,
    expenses
  }: { household: Household, members: Member[], expenses: Expense[], incomes: Income[] } = useAppSelector(state => {
    const { household, members, expenses, incomes } = state
    return { household, members, expenses, incomes }
  })
  const tax = useTax()

  useEffect(() => {
    const totalIncome = incomeSum(incomes)
    const _expenses = expenseSum(expenses)
    const _saving = totalIncome - (_expenses + (tax?.total || 0))
    const _result = {
      income: benchmarks.income(totalIncome, new Date().getFullYear()),
      expense: benchmarks.expense(totalIncome, _expenses),
      saving: benchmarks.saving(totalIncome, _saving)
    }
    setResult(_result)
  }, [household, members, expenses, incomes, tax])

  return result
}

export default useBenchmarks