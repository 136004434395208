import { Benchmark } from "../types";
import { comparisonText } from "../utils";
import { EXPENSE_TYPES } from "../../../constants";

const expense = (income: number, expenses: number): Benchmark => {
  if (!income || !expenses) return
  const recommendedPercentage: number = 30
  const actualPercentage: number = Math.round((expenses / income) * 100)
  const difference = recommendedPercentage - actualPercentage
  const positive: boolean = actualPercentage <= recommendedPercentage
  return { text: comparisonText(EXPENSE_TYPES.EXPENSE, difference, positive), positive }
}

export default expense