/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHousehold = /* GraphQL */ `
  query GetHousehold($id: ID!) {
    getHousehold(id: $id) {
      id
      year
      city
      state
      members {
        items {
          id
          householdId
          type
          color
          firstName
          lastName
          yearOfBirth
          createdAt
          updatedAt
        }
        nextToken
      }
      incomes {
        items {
          id
          householdId
          memberId
          type
          name
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
      expenses {
        items {
          id
          category
          amount
          frequency
          years
          name
          householdId
          createdAt
          updatedAt
        }
        nextToken
      }
      debts {
        items {
          id
          householdId
          category
          outstandingLoanBalance
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          householdId
          category
          value
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      goals {
        items {
          id
          householdId
          category
          name
          year
          amount
          downPaymentPercentage
          closingCostsPercentage
          createdAt
          updatedAt
        }
        nextToken
      }
      effects {
        items {
          id
          householdId
          goalId
          name
          year
          activeYears
          category
          subject
          operation
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
      loanEffects {
        items {
          id
          householdId
          goalId
          name
          startYear
          activeYears
          total
          annualPercentageRate
          createdAt
          updatedAt
        }
        nextToken
      }
      relocationEffects {
        items {
          id
          householdId
          goalId
          name
          year
          state
          city
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listHouseholds = /* GraphQL */ `
  query ListHouseholds(
    $filter: ModelHouseholdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHouseholds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        year
        city
        state
        members {
          nextToken
        }
        incomes {
          nextToken
        }
        expenses {
          nextToken
        }
        debts {
          nextToken
        }
        assets {
          nextToken
        }
        goals {
          nextToken
        }
        effects {
          nextToken
        }
        loanEffects {
          nextToken
        }
        relocationEffects {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIncome = /* GraphQL */ `
  query GetIncome($id: ID!) {
    getIncome(id: $id) {
      id
      householdId
      memberId
      type
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const listIncomes = /* GraphQL */ `
  query ListIncomes(
    $filter: ModelIncomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncomes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        householdId
        memberId
        type
        name
        amount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      householdId
      type
      color
      firstName
      lastName
      yearOfBirth
      createdAt
      updatedAt
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        householdId
        type
        color
        firstName
        lastName
        yearOfBirth
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExpense = /* GraphQL */ `
  query GetExpense($id: ID!) {
    getExpense(id: $id) {
      id
      category
      amount
      frequency
      years
      name
      householdId
      createdAt
      updatedAt
    }
  }
`;
export const listExpenses = /* GraphQL */ `
  query ListExpenses(
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        amount
        frequency
        years
        name
        householdId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDebt = /* GraphQL */ `
  query GetDebt($id: ID!) {
    getDebt(id: $id) {
      id
      householdId
      category
      outstandingLoanBalance
      name
      createdAt
      updatedAt
    }
  }
`;
export const listDebts = /* GraphQL */ `
  query ListDebts(
    $filter: ModelDebtFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDebts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        householdId
        category
        outstandingLoanBalance
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAsset = /* GraphQL */ `
  query GetAsset($id: ID!) {
    getAsset(id: $id) {
      id
      householdId
      category
      value
      name
      createdAt
      updatedAt
    }
  }
`;
export const listAssets = /* GraphQL */ `
  query ListAssets(
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        householdId
        category
        value
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGoal = /* GraphQL */ `
  query GetGoal($id: ID!) {
    getGoal(id: $id) {
      id
      householdId
      category
      name
      year
      amount
      downPaymentPercentage
      closingCostsPercentage
      createdAt
      updatedAt
    }
  }
`;
export const listGoals = /* GraphQL */ `
  query ListGoals(
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        householdId
        category
        name
        year
        amount
        downPaymentPercentage
        closingCostsPercentage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEffect = /* GraphQL */ `
  query GetEffect($id: ID!) {
    getEffect(id: $id) {
      id
      householdId
      goalId
      name
      year
      activeYears
      category
      subject
      operation
      amount
      createdAt
      updatedAt
    }
  }
`;
export const listEffects = /* GraphQL */ `
  query ListEffects(
    $filter: ModelEffectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEffects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        householdId
        goalId
        name
        year
        activeYears
        category
        subject
        operation
        amount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLoanEffect = /* GraphQL */ `
  query GetLoanEffect($id: ID!) {
    getLoanEffect(id: $id) {
      id
      householdId
      goalId
      name
      startYear
      activeYears
      total
      annualPercentageRate
      createdAt
      updatedAt
    }
  }
`;
export const listLoanEffects = /* GraphQL */ `
  query ListLoanEffects(
    $filter: ModelLoanEffectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoanEffects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        householdId
        goalId
        name
        startYear
        activeYears
        total
        annualPercentageRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRelocationEffect = /* GraphQL */ `
  query GetRelocationEffect($id: ID!) {
    getRelocationEffect(id: $id) {
      id
      householdId
      goalId
      name
      year
      state
      city
      createdAt
      updatedAt
    }
  }
`;
export const listRelocationEffects = /* GraphQL */ `
  query ListRelocationEffects(
    $filter: ModelRelocationEffectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelocationEffects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        householdId
        goalId
        name
        year
        state
        city
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
