import { HSADeductionConfig } from "../types"
import { availableYear } from "../../../utils/config"

const HSADeduction = (HSADeductionData: HSADeductionConfig) => (
  year: number,
  state: string,
  HSAContributions: number[]
): number => {
  const configYear = availableYear(year, Object.keys(HSADeductionData))
  const config = HSADeductionData[configYear]
  if (config.nonDeductibleStates.includes(state)) return 0
  return HSAContributions.reduce((sum, item) => sum + item, 0)
}

export default HSADeduction