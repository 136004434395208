import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap"
import styles from "./SuggestedEffect.module.css"

const SuggestedEffect = (props: {
  title: string
  icon?: IconName
  subtitle?: string
  className?: string
  onClick: (any?) => any
}) => {
  return <div className={[styles.container, (props.className || ""), "d-flex align-items-center justify-content-between"].join(" ")}>
    <div className={[styles.body, "d-flex align-items-center gap-2"].join(" ")}>
      {props.icon && <FontAwesomeIcon icon={["fas", props.icon]} />}
      <div>
        <div className="fw-bolder">{props.title}</div>
        {props.subtitle && <div className="caption">{props.subtitle}</div>}
      </div>
    </div>
    <div>
      <Button variant="primary" size="sm" onClick={() => props.onClick()}>Add</Button>
    </div>
  </div>
}

export default SuggestedEffect