import styles from "./Navbar.module.css"
import { useAppSelector } from "../../store";
import { Container } from "react-bootstrap"
import Avatar from "../Avatar"
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdminPanel from "./AdminPanel";

const Navbar = () => {

  const router = useAppSelector((state) => state.router)
  const dispatch = useDispatch()
  const [path, setPath] = useState<any>(null)


  useEffect(() => {
    const titledPaths = [
      {
        path: "/household",
        title: "My household",
      },
      {
        path: "/finances",
        title: "My finances",
      },
      {
        path: "/timeline",
        title: "Timeline",
      },
      {
        path: "/investment",
        title: "Investment",
      },
    ]
    const newPath = titledPaths.find(path => router?.location?.pathname?.startsWith(path.path))
    setPath(newPath || null)
  }, [router, setPath])

  return (
    <div className={styles.container + " sticky-top"}>
      <Container fluid>
        <div className={["d-flex align-items-center justify-content-between", styles.inner].join(" ")}>
          {
            !path && (
              <div className="fw-bold d-flex align-items-center fs-5 py-2">
                <img src="/steward_logo.png" className={styles.logo} alt="Steward logo" />
                Steward
              </div>
            )
          }
          {
            path && (
              <div className="d-flex align-items-center">
                <div className={styles.back} onClick={() => dispatch(push("/"))}>
                  <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                  {/* arrow placeholder */}
                </div>
                <div className=" d-flex flex-column align-items-center h-100">
                  <div>
                    <p className="headline-6 mb-0">{path.title}</p>
                  </div>
                </div>
              </div>
            )
          }
          <div className="d-flex gap-5 align-items-center justify-content-end py-2">
            <AdminPanel />
            <Avatar />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Navbar