import { availableYear } from "../../../utils/config"
import { RELATIONSHIP_STATUS_TYPES } from "../../../constants";
import { statePersonalExemptionsConfig } from "../types";

const statePersonalExemptions = (statePersonalExemptionsData: statePersonalExemptionsConfig) =>
  (year: number, relationshipStatus: keyof typeof RELATIONSHIP_STATUS_TYPES, state: string): number => {
    const configYear = availableYear(year, Object.keys(statePersonalExemptionsData))
    return statePersonalExemptionsData[configYear][relationshipStatus].find(item =>
      item.state === state)?.amount || 0
  }

export default statePersonalExemptions