import { availableYear } from "../../../utils/config"
import { RELATIONSHIP_STATUS_TYPES } from "../../../constants";
import { stateIncomeTaxConfig, stateIncomeTaxProgressiveConfig } from "../types";

const stateIncomeTax = (stateIncomeTaxProgressiveData: stateIncomeTaxProgressiveConfig, stateIncomeTaxData: stateIncomeTaxConfig) =>
  (taxableAmount: number, year: number, state: string, relationshipStatus: keyof typeof RELATIONSHIP_STATUS_TYPES): number => {
    const progressiveConfigYear = availableYear(year, Object.keys(stateIncomeTaxProgressiveData))
    const progressiveTax = stateIncomeTaxProgressiveData[progressiveConfigYear][relationshipStatus].find(item => {
      return item.min <= taxableAmount
        && (!item.max || item.max >= taxableAmount)
        && item.state === state
    })
    if (progressiveTax) {
      const result = (taxableAmount - progressiveTax.min) / 100 * progressiveTax.bracket
      return progressiveTax.floor ? progressiveTax.floor + result : result
    }
    const configYear = availableYear(year, Object.keys(stateIncomeTaxData))
    const config = stateIncomeTaxData[configYear]
    const tax = config.find(item =>
      item.state === state)
    if (!tax) return 0
    return taxableAmount / 100 * tax.high
  }

export default stateIncomeTax