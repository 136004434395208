import { incomeText } from "../utils";
import { Benchmark, IncomeConfig } from "../types";
import { availableYear } from "../../../utils/config"

const income = (incomeData: IncomeConfig) => (totalIncome: number, year: number): Benchmark => {
  if (!totalIncome) return
  const configYear = availableYear(year, Object.keys(incomeData))
  if (totalIncome > incomeData[configYear][-1]) return { text: incomeText(1), positive: true }
  const compared = incomeData[configYear].reduce((result, _income, index) => {
    if (totalIncome >= _income) return index + 1
    return result
  }, 0)
  const percentage = 100 - compared
  return { text: incomeText(percentage), positive: true }
}

export default income