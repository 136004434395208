import { GOAL_CATEGORIES, INFO_CATEGORIES } from "../constants"

const goalCategories: {} = {
  [GOAL_CATEGORIES.EDUCATION]: {
    title: "Education",
    icon: "graduation-cap",
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text"
      },
      {
        name: "amount",
        label: "Savings target (tuition fee)",
        placeholder: 'Fill amount of savings target',
        type: "amount",
        tooltip: INFO_CATEGORIES.EDUCATION_AMOUNT
      },
      {
        name: "year",
        label: "Year",
        placeholder: 'Add the target year for your goal',
        type: "number"
      },
    ]
  },
  [GOAL_CATEGORIES.NEW_FAMILY_MEMBER]: {
    title: "New family member",
    icon: "family",
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text"
      },
      {
        name: "amount",
        label: "Savings target",
        placeholder: 'Fill amount of savings target',
        type: "amount",
        tooltip: INFO_CATEGORIES.NEW_FAMILY_MEMBER_AMOUNT
      },
      {
        name: "year",
        label: "Year",
        placeholder: 'Add the target year for your goal',
        type: "number"
      },
    ]
  },
  [GOAL_CATEGORIES.HOUSE]: {
    title: "Property purchase",
    icon: "house",
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text"
      },
      {
        name: "amount",
        label: "Property value",
        placeholder: 'Fill value of property',
        type: "amount",
        tooltip: INFO_CATEGORIES.HOME_PURCHASE_VALUE
      },
      {
        name: "downPaymentPercentage",
        label: "Down payment (%)",
        placeholder: "Fill down payment percentage",
        type: "percentage"
      },
      {
        name: "closingCostsPercentage",
        label: "Closing costs (% of total value)",
        placeholder: "Fill closing costs percentage",
        type: "percentage"
      },
      {
        name: "year",
        label: "Year",
        placeholder: 'Add the target year for your goal',
        type: "number"
      },
    ]
  },
  [GOAL_CATEGORIES.HOME_REMODEL]: {
    title: "Home remodel",
    icon: "house",
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text"
      },
      {
        name: "amount",
        label: "Savings target",
        placeholder: 'Fill amount of savings target',
        type: "amount"
      },
      // {
      //   name: "downPaymentPercentage",
      //   label: "Down payment (%)",
      //   type: "percentage"
      // },
      {
        name: "year",
        label: "Year",
        placeholder: 'Add the target year for your goal',
        type: "number"
      },
    ]
  },
  [GOAL_CATEGORIES.OTHER]: {
    title: "Other",
    icon: "flag-pennant",
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text"
      },
      {
        name: "amount",
        label: "Savings target",
        placeholder: 'Fill amount of savings target',
        type: "amount"
      },
      {
        name: "year",
        label: "Year",
        placeholder: 'Add the target year for your goal',
        type: "number"
      },
    ]
  },
  [GOAL_CATEGORIES.CAR]: {
    title: "Car",
    icon: "car",
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text"
      },
      {
        name: "amount",
        label: "Car value",
        placeholder: 'Fill value of car',
        type: "amount"
      },
      {
        name: "downPaymentPercentage",
        label: "Down payment (%)",
        placeholder: "Fill down payment percentage",
        type: "percentage"
      },
      {
        name: "year",
        label: "Year",
        placeholder: 'Add the target year for your goal',
        type: "number"
      },
    ]
  },
  [GOAL_CATEGORIES.HEALTH]: {
    title: "Health",
    icon: "stethoscope",
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text"
      },
      {
        name: "amount",
        label: "Savings target",
        placeholder: 'Fill amount of savings target',
        type: "amount"
      },
      {
        name: "year",
        label: "Year",
        placeholder: 'Add the target year for your goal',
        type: "number"
      },
    ]
  },
  [GOAL_CATEGORIES.BUSINESS]: {
    title: "Business",
    icon: "briefcase",
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text"
      },
      {
        name: "amount",
        label: "Savings target",
        placeholder: 'Fill amount of savings target',
        type: "amount"
      },
      {
        name: "year",
        label: "Year",
        placeholder: 'Add the target year for your goal',
        type: "number"
      },
    ]
  },
  [GOAL_CATEGORIES.TRAVEL]: {
    title: "Travel",
    icon: "plane-engines",
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text"
      },
      {
        name: "amount",
        label: "Savings target",
        placeholder: 'Fill amount of savings target',
        type: "amount"
      },
      {
        name: "year",
        label: "Year",
        placeholder: 'Add the target year for your goal',
        type: "number"
      },
    ]
  },
  [GOAL_CATEGORIES.EMERGENCY_FUND]: {
    title: "Emergency fund",
    icon: "rocket",
    fields: [
      {
        name: "amount",
        label: "Savings target",
        placeholder: 'Fill amount of savings target',
        type: "amount"
      },
      {
        name: "year",
        label: "Year",
        placeholder: 'Add the target year for your goal',
        type: "number"
      },
    ]
  },
  [GOAL_CATEGORIES.RETIREMENT]: {
    icon: "rocket",
  }
}

export default goalCategories