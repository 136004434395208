import { useEffect, useState } from "react";
import { Asset, Debt, StackData } from "../lib/types";
import { assetsSum } from "../lib/utils/assets";
import { cssValue } from "../lib/utils/ui";

const useNetWorth = ({ assets, debts }: { assets: Asset[], debts: Debt[] }) => {
  const [result, setResult] = useState<{ totalDebts: number, netWorth: number, stacks: StackData[][] } | null>(null)
  useEffect(() => {
    const totalDebts = debts.reduce((result, debt) => {
      result = result + debt.outstandingLoanBalance
      return result
    }, 0)
    const netWorth = assetsSum(assets) - totalDebts
    const netWorthPercentage = netWorth / (assetsSum(assets) / 100)
    const debtsPercentage = totalDebts / (assetsSum(assets) / 100)
    const stacks = !assets.length && !debts.length ? [] : [
      [
        {
          title: 'Total Assets',
          percentage: 100,
          color: cssValue('--gradient-extra-3-secondary'),
          light: true
        }
      ],
      [
        ...(netWorthPercentage ? [{
          title: 'Net worth',
          percentage: netWorthPercentage,
          color: cssValue('--gradient-savings-primary'),
          light: false
        }] : []),
        ...(debtsPercentage ? [{
          title: 'Total debt',
          percentage: debtsPercentage,
          color: cssValue('--gradient-expense-secondary'),
          light: true
        }] : [])
      ],
    ]
    setResult({
      totalDebts,
      netWorth,
      stacks
    })
  }, [assets, debts])
  return result
}

export default useNetWorth