import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store";
import { deleteAdminHouseholdIdRequest, setAdminHouseholdIdRequest } from "../../store/actions/admin";
import { validate } from 'uuid';

const AdminPanel = () => {
  const admin = useAppSelector(state => state.admin)
  const auth = useAppSelector(state => state.auth)
  const dispatch = useDispatch()

  const [householdId, setHouseholdId] = useState('')

  useEffect(() => {
    setHouseholdId(admin.householdId || "")
  }, [admin])

  const isAdmin = useMemo(() => {
    return auth.user?.['https://oursteward.com/household_id'] === 'admin'
  }, [auth.user])

  const removeHouseholdId = () => {
    if (!window.confirm("Are you sure you want to remove the household ID?")) return
    dispatch(deleteAdminHouseholdIdRequest())
  }

  const applyHouseholdId = () => {
    if (!validate(householdId)) return window.alert("Household ID is invalid!")
    dispatch(setAdminHouseholdIdRequest(householdId))
  }

  if (!isAdmin) return <></>

  return (
    <InputGroup>
      <FormControl
        className={admin.householdId ? "border-warning bg-warning" : ""}
        placeholder="Household ID"
        value={householdId}
        readOnly={admin.householdId}
        onChange={e => setHouseholdId(e.target.value)}
      />
      {admin.householdId ?
        <Button variant="warning" id="button-addon2" onClick={() => removeHouseholdId()}>
          <FontAwesomeIcon
            icon={['fas', 'times']}
          />
        </Button>
        :
        <Button variant="primary" id="button-addon2" onClick={() => applyHouseholdId()}>
          <FontAwesomeIcon
            icon={['fas', 'check']}
          />
        </Button>
      }
    </InputGroup>
  )
}

export default AdminPanel