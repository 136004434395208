import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { MEMBER_TYPES } from "../../lib/constants"
import { Household, User } from "../../lib/types"
import { setHousehold } from "../../store/actions/household"
import { createMember } from "../../store/actions/members"
import Input from "../Input"
import styles from "./Onboarding.module.css"

const Onboarding = (props: { household: Household, user: User }) => {
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [yearOfBirth, setYearOfBirth] = useState<number>(0)
  const [state, setState] = useState<string>("")
  const [city, setCity] = useState<string>("")

  const dispatch = useDispatch()

  useEffect(() => {
    document.body.style['overflow-y'] = 'hidden'
    return () => {
      document.body.style['overflow-y'] = 'auto'
    }
  }, [])

  const handleLocationChange = (val) => {
    const [state, city] = val.split(",")
    setState(state)
    setCity(city)
  }

  const submit = () => {
    if (!(firstName && lastName && yearOfBirth && state && city)) return
    dispatch(createMember({ type: MEMBER_TYPES.PRIMARY, firstName, lastName, yearOfBirth }))
    dispatch(setHousehold(Object.assign({}, props.household, { state, city })))
  }

  return <div className={styles.container}>

    <div className={styles.modal}>
      <div className='mb-4 text-center'>
        <div className='headline-6 mb-3'>
          Hi There.<br />
          Welcome to Steward!
        </div>
        <div className='caption'>
          First off - congrats! You've taken a great step in making the most of your money. Our job is to make this as easy as possible. Your job is to celebrate yourself for doing a brave thing like this (and answer the questions of course...)
        </div>
      </div>
      <div className="mb-3">
        <div className="subtitle-1 fw-bolder mb-3">
          What's your name?
        </div>
        <div className="mb-3">
          <Input
            type="text"
            label="First name"
            name="firstName"
            value={firstName}
            handleChange={(val) => setFirstName(val)}
          />
        </div>
        <div className="mb-3">
          <Input
            type="text"
            label="Last name"
            name="lastName"
            value={lastName}
            handleChange={(val) => setLastName(val)}
          />
        </div>
      </div>
      <div className='mb-3'>
        <div className="subtitle-1 fw-bolder mb-3">
          What's your year of birth?
        </div>
        <Input
          type="number"
          label="Year of birth"
          name="yearOfBirth"
          value={yearOfBirth}
          min={new Date().getFullYear() - 100}
          handleChange={(val) => setYearOfBirth(val)}
        />
      </div>


      <div className="mb-4">
        <div className="subtitle-1 fw-bolder mb-3">
          What's your tax residency?
        </div>
        <div className="mb-3">
          <Input
            type="location"
            label="_"
            name="location"
            value={state + "," + city}
            handleChange={(val) => handleLocationChange(val)}
          />
        </div>
      </div>
      <div className="text-center">
        <Button
          variant="primary"
          disabled={!(firstName && lastName && yearOfBirth && (yearOfBirth >= new Date().getFullYear() - 100) && state && city)}
          onClick={() => submit()}
        >
          Let's get started!
        </Button>
      </div>
    </div>

  </div>
}

export default Onboarding