import { MEMBER_TYPES } from "../../lib/constants"
import { ActionCreator, Member } from "../../lib/types"

export const SET_MEMBERS = "MEMBERS/setMembers"
export const ADD_MEMBER = "MEMBERS/addMember"
export const CREATE_MEMBER = "MEMBERS/createMember"
export const UPDATE_MEMBER = "MEMBERS/updateMember"
export const REMOVE_MEMBER = "MEMBERS/removeMember"

export const setMembers: ActionCreator<{
  [K in keyof Member]: Member[K]
}[]> = (payload) => {
  return {
    type: SET_MEMBERS,
    payload
  }
}

export const createMember: ActionCreator<{
  firstName?: string
  lastName?: string
  yearOfBirth?: number
  type?: keyof typeof MEMBER_TYPES
}> = (payload) => {
  return {
    type: CREATE_MEMBER,
    payload
  }
}

export const addMember: ActionCreator<{
  [K in keyof Member]: Member[K]
}> = (payload) => {
  return {
    type: ADD_MEMBER,
    payload
  }
}

export const updateMember: ActionCreator<{
  [K in keyof Member]: Member[K]
}> = (payload) => {
  return {
    type: UPDATE_MEMBER,
    payload
  }
}

export const removeMember: ActionCreator<{
  id: string
}> = (payload) => {
  return {
    type: REMOVE_MEMBER,
    payload
  }
}