import locations from "../../lib/config/locations";
import { FloatingLabel, Form } from "react-bootstrap";
import styles from './LocationPicker.module.css'
import { useEffect, useState } from "react";

const LocationPicker = (props: {
  name: string,
  value: any,
  readOnly?: boolean
  handleChange: (any, string) => void
}) => {

  const [cities, setCities] = useState<string[]>([])
  const [location, setLocation] = useState({ state: '', city: '' })

  useEffect(() => {
    const [state, city] = props?.value?.split(',')
    if (!state) return
    const _cities = locations[state]
    setLocation({ state, city })
    setCities(_cities)
  }, [props.value])

  useEffect(() => {
    const { state, city } = location
    if (!state || !city) return
    props.handleChange(state + "," + city, props.name)
    // eslint-disable-next-line
  }, [location])

  const onChange = (name, val) => {
    const nextLocation = { ...location }
    nextLocation[name] = val
    const _cities = locations[nextLocation.state]
    nextLocation.city = _cities.find(item => item === nextLocation.city) || _cities?.[0]
    setLocation(nextLocation)
  }

  return (
    <div className={styles.location}>
      <div className={styles.locationPart}>
        <FloatingLabel label='State' className={styles.label}>
          <Form.Select
            className={[styles.input, (location.state ? '' : styles.placeholder)].join(' ')}
            name='state'
            placeholder='State'
            value={location.state || ''}
            disabled={props.readOnly}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          >
            <option value='' disabled>Select state</option>
            {Object.keys(locations).map(_state => <option key={_state} value={_state}>{_state}</option>)}
          </Form.Select>
        </FloatingLabel>
      </div>
      <div className={styles.locationPart}>
        <FloatingLabel label='City' className={styles.label}>
          <Form.Select
            className={[styles.input, (location.city ? '' : styles.placeholder)].join(' ')}
            name='city'
            placeholder='City'
            value={location.city || ''}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            disabled={props.readOnly || !location.state}
          >
            <option value='' disabled>Select city</option>
            {cities?.map(_city => <option key={_city} value={_city}>{_city}</option>)}
          </Form.Select>
        </FloatingLabel>
      </div>
    </div>
  )
}

export default LocationPicker