import { availableYear } from "../../../utils/config"
import { RELATIONSHIP_STATUS_TYPES } from "../../../constants";
import { standardFederalDeductionConfig } from "../types";

const standardFederalDeduction = (standardFederalDeductionData: standardFederalDeductionConfig) =>
  (year: number, relationshipStatus: keyof typeof RELATIONSHIP_STATUS_TYPES): number => {
    const configYear = availableYear(year, Object.keys(standardFederalDeductionData))
    return standardFederalDeductionData[configYear][relationshipStatus]
  }

export default standardFederalDeduction