import { v4 } from "uuid";
import { MEMBER_TYPES } from "../lib/constants";
import { Member } from "../lib/types";

export const createMember = (
  input: {
    id: string
    color: string
    firstName?: string
    lastName?: string
    yearOfBirth?: number
    type?: keyof typeof MEMBER_TYPES
  },
): Member => {
  const member: Member = {
    id: input.id,
    type: input?.type || MEMBER_TYPES.OTHER,
    color: input.color,
    firstName: input?.firstName || "",
    lastName: input?.lastName || "",
    yearOfBirth: input?.yearOfBirth || null,
  }
  return member
}

export const createMemberId = (): string => {
  return v4()
}

export const createMemberColor = (members: Member[]): string => {
  const colors = [
    "#D7F4D5",
    "#F7D1D6",
    "#FFE0CF",
    "#A8E7E0",
    "#A9DBFF",
    "#C7B3FF",
    "#EDB3FF",
    "#FFC5DF",
    "#24C4B1"
  ]
  const availableColors = colors.filter(color => !members.find(member => member.color === color))
  const randomColor = availableColors?.[Math.floor(Math.random() * availableColors.length)]
  return randomColor || "#ffffff"
}