import taxes from "./taxes"
import deductions from "./deductions"
import credits from "./credits"
import { CONTRIBUTION_TYPES, INCOME_TYPES, RELATIONSHIP_STATUS_TYPES } from "../../constants"
import { Taxes } from "./types"

const subtotal = (dependencies: {
  federalIncomeTax: typeof taxes.federalIncomeTax
  stateIncomeTax: typeof taxes.stateIncomeTax
  localIncomeTax: typeof taxes.localIncomeTax
  socialSecurityTax: typeof taxes.socialSecurityTax
  medicareTax: typeof taxes.medicareTax
  standardFederalDeduction: typeof deductions.standardFederalDeduction
  stateStandardDeduction: typeof deductions.stateStandardDeduction
  statePersonalExemptions: typeof deductions.statePersonalExemptions
  traditionalIRADeduction: typeof deductions.traditionalIRADeduction
  _529Deduction: typeof deductions._529Deduction
  _529Credits: typeof credits._529Credits
  HSADeduction: typeof deductions.HSADeduction
}) => (args: {
  year: number
  state: string
  city: string
  relationshipStatus: keyof typeof RELATIONSHIP_STATUS_TYPES
  incomes: {
    [K in keyof typeof INCOME_TYPES]?: number[]
  }
  contributions: {
    [K in keyof typeof CONTRIBUTION_TYPES]?: number[]
  }
}): Taxes => {
    const totalIncome = Object.values(args.incomes).reduce((total, incomes) => {
      const subTotal = incomes.reduce((sum, income) => sum + income, 0)
      return total + subTotal
    }, 0)

    const totalOtherIncome = (args.incomes[INCOME_TYPES.OTHER_ANNUAL_INCOME] || []).reduce((sum, income) => sum + income, 0)

    const total401k = (args.contributions?.traditional401k || []).reduce((sum, contribution) => sum + contribution, 0)
    const totalHCFSA = (args.contributions?.HCFSA || []).reduce((sum, contribution) => sum + contribution, 0)
    const totalDCFSA = (args.contributions?.DCFSA || []).reduce((sum, contribution) => sum + contribution, 0)
    const totalHSA = (args.contributions?.HSA || []).reduce((sum, contribution) => sum + contribution, 0)
    const standardFederalDeduction = dependencies.standardFederalDeduction(args.year, args.relationshipStatus)

    const deductible529 = dependencies._529Deduction(
      args.year,
      args.state,
      args.relationshipStatus,
      (args.contributions?._529 || [])
    )
    const deductibleHSA = dependencies.HSADeduction(
      args.year,
      args.state,
      (args.contributions?.HSA || [])
    )
    const taxCreditFor529 = dependencies._529Credits(
      args.year,
      args.state,
      args.relationshipStatus,
      (args.contributions?._529 || [])
    )

    const stateStandardDeduction = dependencies.stateStandardDeduction(args.year, args.relationshipStatus, args.state)
    const statePersonalExemptions = dependencies.statePersonalExemptions(args.year, args.relationshipStatus, args.state)


    // (M)AGI = (modified) adjusted gross income 

    const federalMAGI = [
      total401k,
      totalHSA,
      totalDCFSA,
      totalHCFSA
    ].reduce((result, item) => result - item, totalIncome)

    const stateMAGI = [
      total401k,
      totalHSA,
      totalDCFSA,
      totalHCFSA,
      deductible529
    ].reduce((result, item) => result - item, totalIncome)

    const federalDeductibleIRA = dependencies.traditionalIRADeduction(
      federalMAGI,
      args.year,
      args.relationshipStatus,
      (args.contributions?.traditionalIRA || []),
      (args.contributions?.traditional401k || [])
    )

    const stateDeductibleIRA = dependencies.traditionalIRADeduction(
      stateMAGI,
      args.year,
      args.relationshipStatus,
      (args.contributions?.traditionalIRA || []),
      (args.contributions?.traditional401k || [])
    )

    const federalTaxableIncome = [
      standardFederalDeduction,
      total401k,
      federalDeductibleIRA,
      totalHSA,
      totalDCFSA,
      totalHCFSA
    ].reduce((result, item) => Math.max(0, result - item), totalIncome)

    const stateTaxableIncome = [
      stateDeductibleIRA,
      total401k,
      deductibleHSA,
      totalDCFSA,
      totalHCFSA,
      deductible529,
      statePersonalExemptions,
      stateStandardDeduction
    ].reduce((result, item) => Math.max(0, result - item), totalIncome)

    const ficaTaxableIncome = [
      totalOtherIncome,
      totalHSA,
      totalHCFSA,
      totalDCFSA,
    ].reduce((result, item) => Math.max(0, result - item), totalIncome)

    const federalIncomeTax = dependencies.federalIncomeTax(federalTaxableIncome, args.year, args.relationshipStatus)
    const stateIncomeTax = dependencies.stateIncomeTax(stateTaxableIncome, args.year, args.state, args.relationshipStatus)
    const localIncomeTax = dependencies.localIncomeTax(stateTaxableIncome, args.year, args.state, args.city)
    const socialSecurityTax = dependencies.socialSecurityTax(ficaTaxableIncome, args.year)
    const medicareTax = dependencies.medicareTax(ficaTaxableIncome, args.year, args.relationshipStatus)

    const total = [
      federalIncomeTax,
      stateIncomeTax,
      localIncomeTax,
      socialSecurityTax,
      medicareTax,
      (taxCreditFor529 * -1)
    ].reduce((result, item) => Math.max(0, result + item), 0)

    return {
      total,
      details: {
        federal: federalIncomeTax,
        state: stateIncomeTax,
        local: localIncomeTax,
        socialSecurity: socialSecurityTax,
        medicare: medicareTax,
        fica: socialSecurityTax + medicareTax
      }
    }
  }

export default subtotal