import React from "react"
import styles from './Cards.module.css'

const Cards = (props: {
  children: React.ReactNode
}) => {
  return (
    <div className={styles.container}>
      {React.Children.map(props.children, (child) => (
        <>
          {child && (
            <div className={styles.col}>
              {child}
            </div>
          )}
        </>
      ))}
    </div>
  )
}

export default Cards