import { useEffect, useState } from "react";
import { incomeSum } from "../lib/utils/income";
import { expenseSum } from "../lib/utils/expense";
import { assetsSum } from "../lib/utils/assets";
import { Asset, Debt, Expense, Household, Income, Member } from "../lib/types";
import { Goal } from "../lib/modules/timeline/types";
import { debtsSum } from "../lib/utils/debts";

type EmptyState = {
  incomes?: boolean,
  expenses?: boolean,
  assets?: boolean,
  debts?: boolean,
  goals?: boolean
}

const useEmptyState = (props: {
  members?: Member[]
  incomes?: Income[]
  household?: Household
  expenses?: Expense[]
  assets?: Asset[]
  debts?: Debt[]
  goals?: Goal[]
}) => {

  const [result, setResult] = useState<EmptyState | null>(null)

  useEffect(() => {

    const _result = {
      incomes: (props.incomes && props.members && props.household) && !incomeSum(props.incomes),
      expenses: props.expenses && (!props.expenses.length || !expenseSum(props.expenses)),
      assets: props.assets && (!props.assets.length || !assetsSum(props.assets)),
      debts: props.debts && (!props.debts.length || !debtsSum(props.debts)),
      goals: props.goals && !props.goals?.length
    }

    const filteredResult = Object.keys(_result).reduce((filtered, key) => {
      if (_result[key] === undefined) return filtered
      return Object.assign({}, filtered, { [key]: _result[key] })
    }, {})

    setResult(filteredResult)

  }, [props.members, props.incomes, props.household, props.expenses, props.assets, props.debts, props.goals])

  return result

}

export default useEmptyState