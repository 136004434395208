import { Effect } from "../../lib/modules/timeline/types"
import { UNLOAD_DATA } from "../actions/data"
import { SET_EFFECTS, ADD_EFFECT, UPDATE_EFFECT, REMOVE_EFFECT } from "../actions/effects"


const initial: Effect[] = []

const effects = (state = initial, action) => {
  switch (action.type) {
    case SET_EFFECTS:
      return action.payload || []

    case ADD_EFFECT:
      const newState = state.filter(effect => effect.id !== action.payload.id)
      newState.push(action.payload)
      return newState

    case UPDATE_EFFECT:
      return state.map(effect => {
        if (effect.id === action.payload.id) effect = Object.assign({}, effect, action.payload)
        return effect
      })

    case REMOVE_EFFECT:
      return state.filter(effect => effect.id !== action.payload.id)
    
    case UNLOAD_DATA:
      return initial
    
    default:
      return state
  }
}

export default effects