import styles from "./CardTitle.module.css"
import Pill from "../Pill"
import CircleIcon from "../CircleIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"

const CardTitle = (props: {
  variant?: "household" | "collection" | 'member'
  title: string
  color?: string
  initial?: string
  icon?: IconName,
  secondaryIcon?: IconName
  type?: "incomes" | "expenses" | "assets" | "debts"
  sum?: number | string
  onRemove?: (any?) => any
}) => {

  return (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <div className="d-flex gap-2 align-items-center">
        <div>
          {props.variant === 'member' &&
            <CircleIcon color={props.color} footerIcon={props?.secondaryIcon}>
              {props.initial ? props.initial : ""}
            </CircleIcon>
          }
          {props.variant === "household" &&
            <CircleIcon color="transparent">
              <img src="/household.svg" alt="Household icon" />
            </CircleIcon>
          }
          {props.variant === "collection" &&
            <CircleIcon color={props.color}>
              {props.icon ? <FontAwesomeIcon icon={props.icon} /> : "@"}
            </CircleIcon>
          }
        </div>
        <div className="subtitle-1 fw-semi-bold">
          {props.title}
        </div>
      </div>
      {props.sum ? <Pill content={props.sum} variant={props.type} /> : ""}
      {props.onRemove && (
        <div className={styles.remove} onClick={props.onRemove}>
          <div className={styles.clickArea} />
          <FontAwesomeIcon icon={["far", "trash-can"]} />
        </div>
      )}
    </div>
  )
}

export default CardTitle