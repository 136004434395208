import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GOAL_CATEGORIES } from "../../lib/constants";
import goalCategories from "../../lib/config/goalCategories";

const GoalIcon = ({ category, outlined }: { category?: keyof typeof GOAL_CATEGORIES, outlined?: boolean }) => {
  const prefix = outlined ? "far" : "fas"
  const icon = category && goalCategories[category].icon ? goalCategories[category].icon : goalCategories[GOAL_CATEGORIES.OTHER].icon
  return <FontAwesomeIcon icon={[prefix, icon]} />
}

export default GoalIcon