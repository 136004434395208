import styles from "./DatePicker.module.css";
import { FloatingLabel, Form } from "react-bootstrap";
import { useEffect, useState } from "react";

const DatePicker = (props: {
  value: any,
  name: string,
  readOnly?: boolean
  handleChange: (any, string) => void
}) => {

  const numberOfYears = 100
  const years = Array(new Date().getFullYear() - (new Date().getFullYear() - numberOfYears))
    .fill({})
    .map((year, index) => (new Date().getFullYear() - numberOfYears + index + 1).toString())
  const months = Array(12)
    .fill({})
    .map((month, index) => index < 9 ? '0' + (index + 1).toString() : (index + 1).toString())

  const handleDateChange = (part: string, partType: 'year' | 'month' | 'day') => {
    let [year = '', month = '', day = ''] = props.value.split('-')
    let newDate: string = ''
    if (partType === 'year') year = part
    if (partType === 'month') month = part
    if (partType === 'day') day = part
    newDate = year + '-' + month + '-' + day
    props?.handleChange(newDate, props.name)
  }

  const [days, setDays] = useState<string[]>([])

  useEffect(() => {
    const year = props.value.split('-')[0] || ''
    const month = props.value.split('-')[1] || ''
    if (!year || !month) return setDays([])
    const numberOfDays = new Date(parseInt(year), parseInt(month), 0).getDate()
    const _days = Array(numberOfDays)
      .fill({})
      .map((month, index) => index < 9 ? '0' + (index + 1).toString() : (index + 1).toString())
    setDays(_days)
  }, [props.value])

  useEffect(() => {
    const day = props.value.split('-')[2] || ''
    if (day && !days.find(item => item === day)) handleDateChange(days[days.length - 1], 'day')
  }, [days])

  return (
    <div className={styles.date}>
      <div className={styles.datePart}>
        <FloatingLabel label='Year' className={styles.label}>
          <Form.Select
            className={styles.input}
            name='year'
            placeholder='Year'
            disabled={props.readOnly}
            value={props.value.split('-')[0] || ''}
            onChange={(e) => handleDateChange(e.target.value, 'year')}
          >
            <option value='' disabled />
            {years.map(year => <option key={year} value={year}>{year}</option>)}
          </Form.Select>
        </FloatingLabel>
      </div>
      <div className={styles.datePart}>
        <FloatingLabel label='Month' className={styles.label}>
          <Form.Select
            className={styles.input}
            name='month'
            placeholder='Month'
            value={props.value.split('-')[1] || ''}
            onChange={(e) => handleDateChange(e.target.value, 'month')}
            disabled={props.readOnly || !props.value.split('-')[0]}
          >
            <option value='' disabled />
            {months.map(month => <option key={'month_' + month} value={month}>{month}</option>)}
          </Form.Select>
        </FloatingLabel>
      </div>
      <div className={styles.datePart}>
        <FloatingLabel label='Day' className={styles.label}>
          <Form.Select
            className={styles.input}
            name='day'
            placeholder='Day'
            value={props.value.split('-')[2] || ''}
            onChange={(e) => handleDateChange(e.target.value, 'day')}
            disabled={props.readOnly || !props.value.split('-')[1]}
          >
            <option value='' disabled />
            {days?.map(_day => <option key={'day_' + _day} value={_day}>{_day}</option>)}
          </Form.Select>
        </FloatingLabel>
      </div>
    </div>
  )
}

export default DatePicker