import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Benchmark as BenchmarkType } from "../../lib/modules/benchmarks/types";
import styles from './Benchmark.module.css'

const Benchmark = (props: { feedback: BenchmarkType }) => {
  if (!props.feedback) return <></>
  return (
    <div className='d-flex align-items-center'>
      <div className={`${props?.feedback?.positive ? styles.positive : styles.negative} ${styles.iconContainer} me-2`}>
        <FontAwesomeIcon
          size='xs'
          icon={["far", props?.feedback?.positive ? "thumbs-up" : "thumbs-down"]}
        />
      </div>
      <div className='small text-black-50'>{props?.feedback?.text}</div>
    </div>
  )
}

export default Benchmark