import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { push } from "connected-react-router"
import { useDispatch } from "react-redux"
import { FREQUENCY_TYPES } from "../../lib/constants"
import { assetsSum } from "../../lib/utils/assets"
import { debtsSum } from "../../lib/utils/debts"
import { expenseSum } from "../../lib/utils/expense"
import { incomeSum } from "../../lib/utils/income"
import { formatAmount } from "../../lib/utils/number"
import { useAppSelector } from "../../store"
import CircleIcon from "../CircleIcon"
import Pill from "../Pill"
import styles from "./Sidebar.module.css"

const Sidebar = ({ active }: { active: string } = { active: "household" }) => {
  const dispatch = useDispatch()
  const members = useAppSelector((state) => state.members)
  const incomes = useAppSelector((state) => state.incomes)
  const expenses = useAppSelector((state) => state.expenses)
  const assets = useAppSelector((state) => state.assets)
  const debts = useAppSelector((state) => state.debts)

  return (
    <div className={styles.container}>
      <div
        className={[
          styles.listItem,
          (!active || active === "household" ? styles.active : "")
        ].join(" ")}
        onClick={() => dispatch(push("/household#household"))}
      >
        <div className={styles.listItemTitle}>
          <div className="d-none d-sm-block">
            <CircleIcon color="transparent">
              <img src={!active || active === 'household' ? '/household-active.svg' : "/household.svg"} alt="Household icon" />
            </CircleIcon>
          </div>
          <div className="d-none d-sm-block">
            Your household
          </div>
          <div className="d-sm-none d-block caption">
            <div className="fw-semi-bold">Household</div>
            <div className={[!active || active === "household" ? "" : "text-gray-40", "caption-small"].join(" ")}>
              {`${members.length} member${members.length > 1 ? 's' : ''}`}
            </div>
          </div>
        </div>
        <div className="d-none d-sm-block">
          <Pill active={!active || active === "household"} content={`${members.length} member${members.length > 1 ? 's' : ''}`} />
        </div>
      </div>

      <div
        className={[
          styles.listItem,
          (active === "income" ? styles.active : "")
        ].join(" ")}
        onClick={() => dispatch(push("/household#income"))}
      >
        <div className={styles.listItemTitle}>
          <div className="d-none d-sm-block">
            <CircleIcon color="transparent">
              <FontAwesomeIcon icon={["fas", "money-bills"]} />
            </CircleIcon>
          </div>
          <div className="d-none d-sm-block">
            Income
          </div>
          <div className="d-sm-none d-block caption">
            <div className="fw-semi-bold">Income</div>
            <div className={[active === "income" ? "" : "text-gray-40", "caption-small"].join(" ")}>
              {formatAmount(incomeSum(incomes), { frequency: FREQUENCY_TYPES.YEARLY, short: true })}
            </div>
          </div>
        </div>
        <div className="d-none d-sm-block">
          <Pill
            variant={"incomes"}
            active={active === "income"}
            content={formatAmount(incomeSum(incomes), { frequency: FREQUENCY_TYPES.YEARLY, short: true })}
          />
        </div>
      </div>

      <div
        className={[
          styles.listItem,
          (active === "expenses" ? styles.active : "")
        ].join(" ")}
        onClick={() => dispatch(push("/household#expenses"))}
      >
        <div className={styles.listItemTitle}>
          <div className="d-none d-sm-block">
            <CircleIcon color="transparent">
              <FontAwesomeIcon icon={["fas", "coins"]} />
            </CircleIcon>
          </div>
          <div className="d-none d-sm-block">
            Expenses
          </div>
          <div className="d-sm-none d-block caption">
            <div className="fw-semi-bold">Expenses</div>
            <div className={[active === "expenses" ? "" : "text-gray-40", "caption-small"].join(" ")}>
              {formatAmount(expenseSum([...expenses]), { frequency: FREQUENCY_TYPES.YEARLY, short: true })}
            </div>
          </div>
        </div>
        <div className="d-none d-sm-block">
          <Pill
            variant='expenses'
            active={active === 'expenses'}
            content={formatAmount(expenseSum([...expenses]), { frequency: FREQUENCY_TYPES.YEARLY, short: true })}
          />
        </div>
      </div>

      <div
        className={[
          styles.listItem,
          (active === "assets" ? styles.active : "")
        ].join(" ")}
        onClick={() => dispatch(push("/household#assets"))}
      >
        <div className={styles.listItemTitle}>
          <div className="d-none d-sm-block">
            <CircleIcon color="transparent">
              <FontAwesomeIcon icon={["fas", "treasure-chest"]} />
            </CircleIcon>
          </div>
          <div className="d-none d-sm-block">
            Assets
          </div>
          <div className="d-sm-none d-block caption">
            <div className="fw-semi-bold">Assets</div>
            <div className={[active === "assets" ? "" : "text-gray-40", "caption-small"].join(" ")}>
              {formatAmount(assetsSum(assets))}
            </div>
          </div>
        </div>
        <div className="d-none d-sm-block">
          <Pill
            variant='assets'
            active={active === 'assets'}
            content={formatAmount(assetsSum(assets))}
          />
        </div>
      </div>

      <div
        className={[
          styles.listItem,
          (active === "debts" ? styles.active : "")
        ].join(" ")}
        onClick={() => dispatch(push("/household#debts"))}
      >
        <div className={styles.listItemTitle}>
          <div className="d-none d-sm-block">
            <CircleIcon color="transparent">
              <FontAwesomeIcon icon={["fas", "building-columns"]} />
            </CircleIcon>
          </div>
          <div className="d-none d-sm-block">
            Debts
          </div>
          <div className="d-sm-none d-block caption">
            <div className="fw-semi-bold">Debts</div>
            <div className={[active === "debts" ? "" : "text-gray-40", "caption-small"].join(" ")}>
              {formatAmount(debtsSum(debts))}
            </div>
          </div>
        </div>
        <div className="d-none d-sm-block">
          <Pill
            variant='debts'
            active={active === 'debts'}
            content={formatAmount(debtsSum(debts))}
          />
        </div>
      </div>

    </div>
  )
}

export default Sidebar