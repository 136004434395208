import { stateStandardDeductionConfig } from "../types";

const stateStandardDeductionData: stateStandardDeductionConfig = {
  "2021": {
    single: [
      { state: "Alabama", amount: 2500 },
      { state: "Alaska", amount: 0 },
      { state: "Arizona", amount: 12400 },
      { state: "Arkansas", amount: 2200 },
      { state: "California", amount: 4601 },
      { state: "Colorado", amount: 12550 },
      { state: "Connecticut", amount: 0 },
      { state: "Delaware", amount: 3250 },
      { state: "District of Columbia", amount: 12550 },
      { state: "Florida", amount: 0 },
      { state: "Georgia", amount: 4600 },
      { state: "Hawaii", amount: 2200 },
      { state: "Idaho", amount: 12550 },
      { state: "Illinois", amount: 0 },
      { state: "Indiana", amount: 0 },
      { state: "Iowa", amount: 2130 },
      { state: "Kansas", amount: 3000 },
      { state: "Kentucky", amount: 2690 },
      { state: "Louisiana", amount: 0 },
      { state: "Maine", amount: 12550 },
      { state: "Maryland", amount: 2300 },
      { state: "Massachusetts", amount: 0 },
      { state: "Michigan", amount: 0 },
      { state: "Minnesota", amount: 12550 },
      { state: "Mississippi", amount: 2300 },
      { state: "Missouri", amount: 12550 },
      { state: "Montana", amount: 4830 },
      { state: "Nebraska", amount: 7100 },
      { state: "Nevada", amount: 0 },
      { state: "New Hampshire", amount: 0 },
      { state: "New Jersey", amount: 0 },
      { state: "New Mexico", amount: 12550 },
      { state: "New York", amount: 8000 },
      { state: "North Carolina", amount: 10750 },
      { state: "North Dakota", amount: 12550 },
      { state: "Ohio", amount: 0 },
      { state: "Oklahoma", amount: 6350 },
      { state: "Oregon", amount: 2350 },
      { state: "Pennsylvania", amount: 0 },
      { state: "Rhode Island", amount: 9050 },
      { state: "South Carolina", amount: 12550 },
      { state: "South Dakota", amount: 0 },
      { state: "Tennessee", amount: 0 },
      { state: "Texas", amount: 0 },
      { state: "Utah", amount: 0 },
      { state: "Vermont", amount: 0 },
      { state: "Virginia", amount: 6150 },
      { state: "Washington", amount: 0 },
      { state: "West Virginia", amount: 0 },
      { state: "Wisconsin", amount: 11200 },
      { state: "Wyoming", amount: 0 },
    ],
    married: [
      { state: "Alabama", amount: 7500 },
      { state: "Alaska", amount: 0 },
      { state: "Arizona", amount: 24800 },
      { state: "Arkansas", amount: 4400 },
      { state: "California", amount: 9202 },
      { state: "Colorado", amount: 25100 },
      { state: "Connecticut", amount: 0 },
      { state: "Delaware", amount: 6500 },
      { state: "District of Columbia", amount: 25100 },
      { state: "Florida", amount: 0 },
      { state: "Georgia", amount: 6000 },
      { state: "Hawaii", amount: 4400 },
      { state: "Idaho", amount: 25100 },
      { state: "Illinois", amount: 0 },
      { state: "Indiana", amount: 0 },
      { state: "Iowa", amount: 5250 },
      { state: "Kansas", amount: 7500 },
      { state: "Kentucky", amount: 2690 },
      { state: "Louisiana", amount: 0 },
      { state: "Maine", amount: 25100 },
      { state: "Maryland", amount: 4600 },
      { state: "Massachusetts", amount: 0 },
      { state: "Michigan", amount: 0 },
      { state: "Minnesota", amount: 25100 },
      { state: "Mississippi", amount: 4600 },
      { state: "Missouri", amount: 25100 },
      { state: "Montana", amount: 9660 },
      { state: "Nebraska", amount: 14200 },
      { state: "Nevada", amount: 0 },
      { state: "New Hampshire", amount: 0 },
      { state: "New Jersey", amount: 0 },
      { state: "New Mexico", amount: 25100 },
      { state: "New York", amount: 16500 },
      { state: "North Carolina", amount: 21500 },
      { state: "North Dakota", amount: 25100 },
      { state: "Ohio", amount: 0 },
      { state: "Oklahoma", amount: 12700 },
      { state: "Oregon", amount: 4700 },
      { state: "Pennsylvania", amount: 0 },
      { state: "Rhode Island", amount: 18100 },
      { state: "South Carolina", amount: 25100 },
      { state: "South Dakota", amount: 0 },
      { state: "Tennessee", amount: 0 },
      { state: "Texas", amount: 0 },
      { state: "Utah", amount: 0 },
      { state: "Vermont", amount: 0 },
      { state: "Virginia", amount: 12300 },
      { state: "Washington", amount: 0 },
      { state: "West Virginia", amount: 0 },
      { state: "Wisconsin", amount: 20730 },
      { state: "Wyoming", amount: 0 },
    ]
  }
}

export default stateStandardDeductionData