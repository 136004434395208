import { ActionCreator, Income } from "../../lib/types"

export const SET_INCOMES = "INCOMES/setIncomes"
export const ADD_INCOME = "INCOMES/addIncome"
export const UPDATE_INCOME = "INCOMES/updateIncome"
export const REMOVE_INCOME = "INCOMES/removeIncome"

export const setIncomes: ActionCreator<{
  [K in keyof Income]: Income[K]
}[]> = (payload) => {
  return {
    type: SET_INCOMES,
    payload
  }
}
export const addIncome: ActionCreator<{
  [K in keyof Income]: Income[K]
}> = (payload) => {
  return {
    type: ADD_INCOME,
    payload
  }
}

export const updateIncome: ActionCreator<{
  [K in keyof Income]: Income[K]
}> = (payload) => {
  return {
    type: UPDATE_INCOME,
    payload
  }
}

export const removeIncome: ActionCreator<{
  id: string
}> = (payload) => {
  return {
    type: REMOVE_INCOME,
    payload
  }
}