import { throttle } from "throttle-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import styles from "./Tooltip.module.css"
import useCaptureOutsideClick from "../../hooks/useCaptureOutsideClick";

const Tooltip = (props: {
  children: React.ReactNode
}) => {
  const [toggled, setToggled] = useState<boolean>(false)
  const [position, setPosition] = useState<any>(null)
  const [alignLeft, setAlignLeft] = useState<boolean>(false)
  const [alignUp, setAlignUp] = useState<boolean>(false)

  const [top, setTop] = useState<number>(-100)
  const [left, setLeft] = useState<number>(-100)

  const containerRef = useRef<HTMLDivElement>(null)
  const panelRef = useRef<HTMLDivElement>(null)

  const [maxHeight, setMaxHeight] = useState<number>(500)
  const minHeight = 200

  useCaptureOutsideClick({ listen: toggled, ref: containerRef, onClick: () => setToggled(false) })

  useEffect(() => {
    if (!toggled) {
      setPosition(null)
    } else {
      const nextPosition = containerRef?.current?.getBoundingClientRect()
      setPosition(nextPosition)
    }
  }, [toggled, containerRef])


  useEffect(() => {
    const refreshPosition = throttle(50, () => {
      setPosition(containerRef?.current?.getBoundingClientRect() || null)
    })
    if (toggled) {
      document.addEventListener("scroll", refreshPosition, true)
      window.addEventListener("resize", refreshPosition)
    }
    return () => {
      document.removeEventListener("scroll", refreshPosition, true)
      window.removeEventListener("resize", refreshPosition)
    }
  }, [toggled, containerRef])

  useEffect(() => {
    if (position) {
      if (position.x + position.width + 200 > window.innerWidth - 200) {
        setAlignLeft(true)
      } else {
        setAlignLeft(false)
      }
      if (position.y > window.innerHeight / 2) {
        setAlignUp(true)
      } else {
        setAlignUp(false)
      }
      setMaxHeight(Math.min(500, Math.max(window.innerHeight / 2, minHeight)))
    }
  }, [position, panelRef])

  useEffect(() => {
    let nextTop = 0;
    if (alignUp) {
      nextTop = (position?.top || -100) - (panelRef?.current?.clientHeight || 0) + (position?.height || 0) + 6
    } else {
      nextTop = (position?.top || -100) - 6
    }
    let nextLeft = 0;
    if (!alignLeft) {
      nextLeft = (position?.left || -100) + (position?.width || 0) + 8
    } else {
      nextLeft = (position?.left || -100) - (position?.width || 0) - (panelRef?.current?.clientWidth || 0) + 4
    }
    console.log(position)
    console.log(nextTop, nextLeft)
    setTop(nextTop)
    setLeft(nextLeft)
  }, [maxHeight, alignLeft, alignUp, position])


  return <>
    <span className={styles.container} ref={containerRef}>
      <div className={[styles.iconContainer, 'text-black'].join(' ')}>
        <FontAwesomeIcon icon={["fas", "info-circle"]} onClick={() => setToggled(!toggled)} className="clickable " />
      </div>
      <div
        className={[
          styles.panel,
          (alignUp ? styles.up : styles.down),
          ((toggled && position) ? styles.toggled : ""),
          "gap-3 justify-content-between caption fw-normal"
        ].join(" ")}
        style={{
          top: top + "px",
          left: left + "px",
          maxHeight: maxHeight + "px"
        }}
        ref={panelRef}
      >
        <div className={alignLeft ? styles.arrowRight : styles.arrowLeft}>
        </div>
        <div className="d-flex align-items-center">
          <div className={styles.body}>
            {props.children}
          </div>
        </div>
        <div className="clickable headline-3" onClick={() => setToggled(false)}>
          <FontAwesomeIcon icon={['fas', 'times']} />
        </div>
      </div>
    </span>
  </>
}

export default Tooltip