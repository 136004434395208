import styles from "./Pill.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge } from "react-bootstrap"
import { IconName } from "@fortawesome/fontawesome-svg-core";
import React from "react";

const Pill = (props: {
  content: string | React.ReactNode,
  className?: string
  icon?: IconName | null
  active?: boolean
  variant?: "incomes" | "expenses" | "assets" | 'goals' | 'savings' | 'retirement' | "debts"
  negative?: boolean
  placeholder?: boolean
  onClick?: (any) => any
}) => {
  return (
    <Badge
      onClick={props.onClick}
      bg=""
      className={[
        styles.base,
        (props.variant ? styles[props.variant] : ""),
        (props.negative ? styles.negative : ''),
        (props.active ? styles.active : ""),
        (props.className || "")
      ].join(" ")}>
        <span className={(props.placeholder ? "invisible" : "")}>
          {props.icon && <FontAwesomeIcon className='me-1' icon={["far", props.icon]} />}
          {props.content}
        </span>
    </Badge>
  )
}

export default Pill