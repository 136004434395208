import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { throttle } from "throttle-debounce";
import { useEffect, useRef, useState, useMemo } from "react"
import { EFFECT_CATEGORIES, GOAL_CATEGORIES, RETIREMENT } from "../../lib/constants"
import { Effect, Goal, Relocation, TimelineResult } from "../../lib/modules/timeline/types"
import { Member } from "../../lib/types"
import { formatAmount } from "../../lib/utils/number"
import { cssValue } from "../../lib/utils/ui"
import Canvas from "./Canvas"
import EffectPin from "./EffectPin"
import GoalPin from "./GoalPin"
import styles from "./Timeline.module.css"
import TopBar from './TopBar';
import effectCategories from "../../lib/config/effectCategories";

export type Point = { x: number, y: number }
export type GoalPoint = Point & { reached: boolean, year: string, goals: { id: string, titles: string[], category?: keyof typeof GOAL_CATEGORIES }[] }

const TimelineChart = (
  { timeline, goals, members, effects, relocations, loading, options, onClick }:
    { timeline: TimelineResult, goals: Goal[], members: Member[], effects: Effect[], relocations: Relocation[], loading, options?, onClick }
) => {
  const height = 240
  const canvasContainer = useRef<any>(null)
  const yearPanel = useRef<any>(null)
  const [width, setWidth] = useState(0)
  const [yearPanelWidth, setYearPanelWidth] = useState(0)
  const [yearPanelLeft, setYearPanelLeft] = useState(0)

  const [line, setLine] = useState<Point[]>([])
  const [peaks, setPeaks] = useState<Point[]>([])
  const [relocationsByYear, setRelocationsByYear] = useState<{ [year: string]: string }>({})
  const [goalPoints, setGoalPoints] = useState<GoalPoint[]>([])
  const [horizontalLabels, setHorizontalLabels] = useState<string[]>([])
  const [verticalLabels, setVerticalLabels] = useState<string[]>([])
  const [years, setYears] = useState<string[]>([])
  const [savings, setSavings] = useState<number[]>([])
  const [effectsByYear, setEffectsByYear] = useState<{ [year: string]: number }>({})
  const [withdraws, setWithdraws] = useState<{ [year: string]: number }>({})

  const [hoveredYear, setHoveredYear] = useState<string | null>(null)

  useEffect(() => {
    const updateWidth = throttle(100, () => setWidth(canvasContainer?.current?.clientWidth))
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  useEffect(() => {
    const retirementTitles = (thisYear: number, retirementYear: number) => {
      const start = `Option not to work in ${retirementYear - thisYear} year${retirementYear - thisYear > 1 ? 's' : ''}`
      return [start, ""]
    }

    if (loading || !timeline?.goals?.[RETIREMENT]) return
    const nextYears = Object.keys(timeline.goals[RETIREMENT].savings)
    const step = width / nextYears.length
    const next = nextYears.reduce((_next: { line: Point[], goalPoints: GoalPoint[], relocations, peaks: Point[], savings, withdraws, effects }, year, index) => {
      const sumOfSavings = Object.keys(timeline.goals).reduce((sum, goalId) => {
        return Math.max(sum + (timeline.goals?.[goalId]?.savings?.[year] || 0), 0)
      }, 0)
      const x = (index + 1) * step
      const y = sumOfSavings
      _next.savings[year] = sumOfSavings
      _next.peaks.push({ x, y })
      _next.line.push({ x, y })
      if ((index + 1) === nextYears.length && timeline.goals[RETIREMENT].reached) _next.goalPoints.push({
        x, y, reached: true, year: nextYears[nextYears.length - 1],
        goals: [{
          id: RETIREMENT, category: GOAL_CATEGORIES.RETIREMENT, titles: retirementTitles(
            parseInt(nextYears[0]),
            parseInt(nextYears[nextYears.length - 1])
          )
        }]
      })

      const reachedGoalsThisYear = goals.filter(goal => goal.year === parseInt(year) && timeline.goals?.[goal.id]?.reached)
      if (reachedGoalsThisYear.length) {
        const pointIndex = _next.goalPoints.findIndex(point => point.year === year)
        const goalsList = reachedGoalsThisYear.map(item => ({ id: item.id, titles: [item.name], category: item.category }))
        if (pointIndex >= 0) {
          _next.goalPoints[pointIndex].goals = [...goalsList, ..._next.goalPoints[pointIndex].goals]
        } else {
          _next.goalPoints.push({
            x, y, reached: true, year, goals: goalsList
          })
        }
      }

      const _withdraws = reachedGoalsThisYear.reduce((sum, goal) => sum + timeline.goals[goal.id].savingsTarget, 0)
      if (_withdraws && (index + 1) !== nextYears.length) {
        _next.withdraws[year] = _withdraws
        _next.line.push({ x, y: Math.max(sumOfSavings - _withdraws, 0) })
      }

      const activeRelocation = relocations.find(relocation => relocation.year === parseInt(year))
      if (activeRelocation) _next.relocations[year] = activeRelocation.id

      const activeEffects = effects
        .filter(effect => effect.year <= parseInt(year) && (!effect.activeYears || (effect.year + effect.activeYears - 1) >= parseInt(year)))
        .reduce((sum, effect) => {
          return sum + effect.amount * (effect.subject === "income" ? 1 : -1)
        }, 0)

      _next.effects[year] = activeEffects

      const unreachedGoalsThisYear = goals.filter(goal => goal.year === parseInt(year) && !timeline.goals?.[goal.id]?.reached)
      const unreachedGoalsAmount = unreachedGoalsThisYear.reduce((sum, goal) => sum + (timeline.goals?.[goal.id]?.savingsTarget || 0), 0)
      if (unreachedGoalsThisYear.length) _next.goalPoints.push({ y: unreachedGoalsAmount, x, reached: false, year, goals: unreachedGoalsThisYear.map(item => ({ id: item.id, titles: [item.name], category: item.category })) })

      return _next
    }, { line: [], goalPoints: [], relocations: {}, peaks: [], savings: {}, withdraws: {}, effects: {} })


    const highestPoint = next.line.reduce((highest, item) => {
      if (item.y > highest) return item.y
      return highest
    }, next.line[0].y)

    const optimizedNextLine = next.line.map(item => {
      item.y = (height / 100) * (item.y / (highestPoint / 100))
      return item
    })

    const optimizedNextPeaks = next.peaks.map(item => {
      item.y = (height / 100) * (item.y / (highestPoint / 100))
      return item
    })
    const optimizedNextGoalPoints = next.goalPoints.map(item => {
      item.y = Math.min(height, (height / 100) * (item.y / (highestPoint / 100)))
      return item
    })

    setHorizontalLabels([nextYears[0], nextYears[Math.floor(nextYears.length / 2)], nextYears[nextYears.length - 1]])

    const roundedHighest = highestPoint / 1000000
    setVerticalLabels([roundedHighest.toFixed(1) + "M", (roundedHighest / 2).toFixed(1) + "M", ""])
    setYears(nextYears)
    setLine(optimizedNextLine)
    setPeaks(optimizedNextPeaks)
    setGoalPoints(optimizedNextGoalPoints)
    setSavings(next.savings)
    setWithdraws(next.withdraws)
    setEffectsByYear(next.effects)
    setRelocationsByYear(next.relocations)
  }, [timeline, goals, width, members, effects, loading, relocations])

  useEffect(() => {
    setWidth(canvasContainer?.current?.clientWidth || 0)
  }, [canvasContainer])

  useEffect(() => {
    setYearPanelWidth(yearPanel?.current?.clientWidth || 0)
  }, [yearPanel, hoveredYear])

  useEffect(() => {
    const nextLeftPercentage = ((years.findIndex(item => item === hoveredYear) + 1) / (years.length / 100)) / 100
    const nextLeft = width * nextLeftPercentage
    if (nextLeft) setYearPanelLeft((nextLeft + yearPanelWidth > width ? width - yearPanelWidth + 15 : nextLeft))
  }, [hoveredYear, yearPanelWidth, width, years])

  const topbarEffects = useMemo(() => {
    return effects.filter(effect =>
      effect.year <= parseInt(hoveredYear || '') &&
      (!effect.activeYears || (effect.year + effect.activeYears - 1) >= parseInt(hoveredYear || "")) &&
      effect.amount)
  }, [hoveredYear, effects])

  return <div className={styles.container}>
    {!options?.simple && timeline.finances && (
      <TopBar
        year={hoveredYear || Object.keys(timeline.finances)[0]}
        savings={savings[hoveredYear || Object.keys(timeline.finances)[0]]}
        effects={topbarEffects}
      />
    )}
    <div className={styles.canvasContainer} ref={canvasContainer}>
      <div className="position-relative">
        {goalPoints.map((point, index) => (
          <GoalPin
            goals={point.goals}
            reached={point.reached}
            position={{ x: point.x, y: point.y }}
            key={"year_" + index + point.year + point.reached}
            onMouseEnter={() => setHoveredYear(point.year)}
            className={years.indexOf(point.year.toString()) < years.length / 2 ? styles.rightSided : styles.leftSided}
            onClick={(id) => onClick(id)}
          />
        ))}
        <Canvas
          colors={{
            up: cssValue("--green-dark"),
            down: cssValue("--red"),
            base: cssValue("--gray-20")
          }}
          width={width}
          height={height}
          line={line}
        />
        <div className={[styles.yearStripes, "d-flex align-items-stretch"].join(" ")} onMouseLeave={() => setHoveredYear(null)}>
          {years.map((year, index) => (
            <div className={[styles.yearStripe, (year === hoveredYear ? styles.active : "")].join(" ")} key={"stripe_" + year} onMouseEnter={() => setHoveredYear(year)}>
              <div className={styles.line} style={{ height: (peaks?.[index]?.y || 0) + "px" }}></div>
              {goalPoints.filter(point => !point.reached && point.year === year).map(point => (
                <div key={"x" + point.x}>
                  <div className={styles.redLine} style={{ height: point.y + "px" }}></div>
                  <div className={styles.redDot}></div>
                </div>
              ))}
              <div className={styles.point} style={{ bottom: (peaks?.[index]?.y || 0) - 10 + "px" }}></div>
              {!options?.simple && effectsByYear[year] !== 0 && <div className={[styles.effect, (effectsByYear[year] > 0 ? styles.positive : styles.negative)].join(" ")}></div>}
              {relocationsByYear[year] && <EffectPin
                icon={effectCategories[EFFECT_CATEGORIES.RELOCATION].icon}
                title={relocations.find(relocation => relocation.id === relocationsByYear[year])?.name || "-"}
                onClick={() => onClick(relocationsByYear[year])}
              />}
            </div>
          ))}
        </div>
        {hoveredYear &&
          <div className={[styles.yearStripePanel, "flex-column caption"].join(" ")} ref={yearPanel} style={{
            left: yearPanelLeft + "px"
          }}>
            <div className="d-flex gap-4 fw-bold justify-content-between text-cta">
              <div className="d-flex">
                <div className={styles.panelIcon}>
                  <FontAwesomeIcon icon={["fas", "circle-small"]} />
                </div>
                <div>
                  {formatAmount(savings[hoveredYear], { short: true })}
                </div>
              </div>
              <div className="text-cta-secondary">{hoveredYear}</div>
            </div>
            <div className="d-flex justify-content-between">
              {withdraws[hoveredYear] && (
                <div className="fw-bold text-outflow mt-1">
                  <div className={styles.panelIcon}>
                    <FontAwesomeIcon icon={["fas", "bullseye-arrow"]} />
                  </div>
                  -{formatAmount(withdraws[hoveredYear] || 0, { short: true })}
                </div>
              )}
            </div>
          </div>
        }
        <div className={[styles.verticalLabels, "d-flex flex-column justify-content-between"].join(" ")}>
          {verticalLabels.map((label, index) => (
            <div className="caption" key={"v_" + label + index}>{label}</div>
          ))}
        </div>
      </div>
    </div>
    <div className={[styles.horizontalLabels, "d-flex justify-content-between"].join(" ")}>
      {horizontalLabels.map((label, index) => (
        <div className="caption" key={"h_" + label + index}>{label}</div>
      ))}
    </div>
  </div>
}

export default TimelineChart
