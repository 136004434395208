import { v4 } from "uuid";
import { Household } from "../lib/types";

export const createHouseholdId = (): string => v4()

export const createHousehold = (input: {
  id: string,
  year?: number,
  city?: string,
  state?: string
}): Household => {
  const household = {
    id: input.id,
    year: new Date().getFullYear(),
    city: "",
    state: ""
  }
  Object.keys(input).filter(key => input[key]).forEach(key => household[key] = input[key])
  return household
}