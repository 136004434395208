import { ALLOCATION_TYPES, INVESTMENT_TYPES } from "../../../constants"
import { AllocationData } from "../types"


const allocation: AllocationData = [{
  minYear: 1,
  avgAnnualReturn: 0.0591,
  allocation: {
    [ALLOCATION_TYPES.EQUITIES]: 30,
    [ALLOCATION_TYPES.FIXED_INCOME]: 70,
  },
  detailedAllocation: {
    [INVESTMENT_TYPES.TOTAL_US_STOCK_MARKET]: 18,
    [INVESTMENT_TYPES.TOTAL_INTL_STOCK_MARKET]: 12,
    [INVESTMENT_TYPES.TOTAL_US_BOND_MARKET]: 37,
    [INVESTMENT_TYPES.TOTAL_INTL_BOND_MARKET]: 16,
    [INVESTMENT_TYPES.SHORT_TERM_INFLATION_PROTECTED_SECURITIES]: 17,
  }
}, {
  minYear: 5,
  avgAnnualReturn: 0.07,
  allocation: {
    [ALLOCATION_TYPES.EQUITIES]: 57,
    [ALLOCATION_TYPES.FIXED_INCOME]: 43,
  },
  detailedAllocation: {
    [INVESTMENT_TYPES.TOTAL_US_STOCK_MARKET]: 34,
    [INVESTMENT_TYPES.TOTAL_INTL_STOCK_MARKET]: 23,
    [INVESTMENT_TYPES.TOTAL_US_BOND_MARKET]: 31,
    [INVESTMENT_TYPES.TOTAL_INTL_BOND_MARKET]: 12,
    [INVESTMENT_TYPES.SHORT_TERM_INFLATION_PROTECTED_SECURITIES]: 0,
  }
}, {
  minYear: 10,
  avgAnnualReturn: 0.0718,
  allocation: {
    [ALLOCATION_TYPES.EQUITIES]: 67,
    [ALLOCATION_TYPES.FIXED_INCOME]: 33,
  },
  detailedAllocation: {
    [INVESTMENT_TYPES.TOTAL_US_STOCK_MARKET]: 40,
    [INVESTMENT_TYPES.TOTAL_INTL_STOCK_MARKET]: 27,
    [INVESTMENT_TYPES.TOTAL_US_BOND_MARKET]: 23,
    [INVESTMENT_TYPES.TOTAL_INTL_BOND_MARKET]: 10,
    [INVESTMENT_TYPES.SHORT_TERM_INFLATION_PROTECTED_SECURITIES]: 0,
  }
}, {
  minYear: 15,
  avgAnnualReturn: 0.0805,
  allocation: {
    [ALLOCATION_TYPES.EQUITIES]: 75,
    [ALLOCATION_TYPES.FIXED_INCOME]: 25,
  },
  detailedAllocation: {
    [INVESTMENT_TYPES.TOTAL_US_STOCK_MARKET]: 45,
    [INVESTMENT_TYPES.TOTAL_INTL_STOCK_MARKET]: 30,
    [INVESTMENT_TYPES.TOTAL_US_BOND_MARKET]: 18,
    [INVESTMENT_TYPES.TOTAL_INTL_BOND_MARKET]: 7,
    [INVESTMENT_TYPES.SHORT_TERM_INFLATION_PROTECTED_SECURITIES]: 0,
  }
}, {
  minYear: 20,
  avgAnnualReturn: 0.0847,
  allocation: {
    [ALLOCATION_TYPES.EQUITIES]: 82,
    [ALLOCATION_TYPES.FIXED_INCOME]: 18,
  },
  detailedAllocation: {
    [INVESTMENT_TYPES.TOTAL_US_STOCK_MARKET]: 49,
    [INVESTMENT_TYPES.TOTAL_INTL_STOCK_MARKET]: 33,
    [INVESTMENT_TYPES.TOTAL_US_BOND_MARKET]: 13,
    [INVESTMENT_TYPES.TOTAL_INTL_BOND_MARKET]: 5,
    [INVESTMENT_TYPES.SHORT_TERM_INFLATION_PROTECTED_SECURITIES]: 0,
  }
}, {
  minYear: 25,
  avgAnnualReturn: 0.0882,
  allocation: {
    [ALLOCATION_TYPES.EQUITIES]: 90,
    [ALLOCATION_TYPES.FIXED_INCOME]: 10,
  },
  detailedAllocation: {
    [INVESTMENT_TYPES.TOTAL_US_STOCK_MARKET]: 54,
    [INVESTMENT_TYPES.TOTAL_INTL_STOCK_MARKET]: 36,
    [INVESTMENT_TYPES.TOTAL_US_BOND_MARKET]: 7,
    [INVESTMENT_TYPES.TOTAL_INTL_BOND_MARKET]: 3,
    [INVESTMENT_TYPES.SHORT_TERM_INFLATION_PROTECTED_SECURITIES]: 0,
  }
}]

export default allocation