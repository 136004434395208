import { useEffect, useState } from "react"
import { MEMBER_TYPES, RELATIONSHIP_STATUS_TYPES } from "../lib/constants"
import tax from "../lib/modules/tax"
import { Taxes } from "../lib/modules/tax/types"
import { Household, Member, Income } from "../lib/types"
import { useAppSelector } from "../store"

const useTax = () => {
  const [result, setResult] = useState<Taxes | null>(null)
  const { household, members, incomes }: { household: Household, members: Member[], incomes: Income[] } = useAppSelector(state => ({
    household: state.household,
    members: state.members,
    incomes: state.incomes,
  }))

  useEffect(() => {
    if (!household && !members.length) return setResult(null)
    const reducedIncomes = incomes.reduce((result, income) => {
      if (!income.amount) return result
      result[income.type] = [...(result[income.type] || []), income.amount]
      return result
    }, {})

    const partner = members.find(member => member.type === MEMBER_TYPES.PARTNER)

    const _result = tax({
      year: household.year,
      state: household.state,
      city: household.city,
      relationshipStatus: partner ? RELATIONSHIP_STATUS_TYPES.married : RELATIONSHIP_STATUS_TYPES.single,
      incomes: reducedIncomes,
      // TODO contributions
      contributions: {}
    })
    setResult(_result)
  }, [household, members])
  return result
}

export default useTax