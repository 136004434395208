import { v4 } from "uuid";
import Card from "../../components/Household/Card"
import PageTitle from "../../components/Household/PageTitle"
import CardTitle from "../../components/Household/CardTitle"
import Cards from "../../components/Household/Cards"
import Pill from "../../components/Pill"
import { assetsSum } from "../../lib/utils/assets"
import CardFooter from "../../components/Household/CardFooter"
import { ASSET_CATEGORIES, ASSET_GROUPS, ASSET_TYPES } from "../../lib/constants"
import Property from "../../components/Household/Property"
import { addAsset, updateAsset, removeAsset } from "../../store/actions/assets"
import { useDispatch } from "react-redux"
import { Asset } from "../../lib/types"
import PropertyList from "../../components/Household/PropertyList";
import { formatAmount } from "../../lib/utils/number";
import { confirmMessages } from "../../lib/config/copy";
import { useState } from "react";
import assetCategories from "../../lib/config/assetCategories";

const Assets = ({ assets }: { assets: Asset[] }) => {
  const dispatch = useDispatch()

  const [lastAdded, setLastAdded] = useState<string | null>(null)

  const requiredFields = ['value', 'name']

  const handleChange = (value: Asset) => {
    dispatch(updateAsset(value))
  }

  const handleCreate = (category: keyof typeof ASSET_CATEGORIES) => {
    // TODO

    // const lastAdded = assets.filter(asset => asset.type === type).pop()
    // if (lastAdded && requiredFields.some(key => !lastAdded[key])) return
    const asset: Asset = {
      id: v4(),
      category,
      name: "",
      value: 0,
      // allocable: true
    }
    setLastAdded(asset.id)
    dispatch(addAsset(asset))
  }

  const handleRemove = (asset: Asset) => {
    if (requiredFields.every(field => !asset[field])) return dispatch(removeAsset({ id: asset.id }))
    if (!window.confirm(confirmMessages['assetRemove'])) return
    dispatch(removeAsset({ id: asset.id }))
  }

  const orderedInvestmentOptions = [
    ASSET_CATEGORIES.PERSONAL_INVESTMENT,
    ASSET_CATEGORIES.TRADITIONAL_401K,
    ASSET_CATEGORIES.ROTH_401K,
    ASSET_CATEGORIES.TRADITIONAL_IRA,
    ASSET_CATEGORIES.ROTH_IRA,
    ASSET_CATEGORIES._529,
    ASSET_CATEGORIES.HSA
  ]

  const orderedPropertyOptions = [
    ASSET_CATEGORIES.HOUSE,
    ASSET_CATEGORIES.OTHER,
  ]

  return (
    <div>
      <PageTitle>
        <div className="headline-5 d-flex align-items-center">
          <div className='me-2'>Assets</div>
          <Pill variant="assets" content={formatAmount(assetsSum(assets))} />
        </div>
      </PageTitle>
      <Cards>
        <Card>
          <CardTitle
            icon={"chart-line"}
            variant="collection"
            title="Investments"
            sum={formatAmount(assetsSum(assets.filter(asset => ASSET_GROUPS[ASSET_TYPES.INVESTMENT].includes(asset.category as any))))}
            type="assets"
          />
          <PropertyList>
            {assets.filter(asset => ASSET_GROUPS[ASSET_TYPES.INVESTMENT].includes(asset.category as any)).map(asset => (
              <Property
                data={asset}
                type={ASSET_TYPES.INVESTMENT}
                icon={assetCategories[asset.category].icon || 'arrow-trend-up'}
                id={asset.id}
                key={asset.id}
                open={lastAdded === asset.id}
                onChange={(value) => handleChange(value)}
                onRemove={() => handleRemove(asset)}
              />
            ))}
          </PropertyList>

          <CardFooter
            options={orderedInvestmentOptions.map(asset => ({ value: asset, title: assetCategories[asset].title, icon: assetCategories[asset].icon }))}
            onAdd={(asset) => handleCreate(asset)}
          />
        </Card>
        <Card>
          <CardTitle
            icon={"house"}
            variant="collection"
            title="Property and other assets"
            sum={formatAmount(assetsSum(assets.filter(asset => ASSET_GROUPS[ASSET_TYPES.PROPERTY].includes(asset.category as any))), { short: true })}
            type="assets"
          />
          <PropertyList>
            {assets.filter(asset => ASSET_GROUPS[ASSET_TYPES.PROPERTY].includes(asset.category as any)).map(asset => (
              <Property
                data={asset}
                type={ASSET_TYPES.PROPERTY}
                icon={assetCategories[asset.category].icon || 'house'}
                id={asset.id}
                key={asset.id}
                open={lastAdded === asset.id}
                onChange={(value) => handleChange(value)}
                onRemove={() => handleRemove(asset)}
              />
            ))}
          </PropertyList>
          <CardFooter
            options={orderedPropertyOptions.map(asset => ({ value: asset, title: assetCategories[asset].title, icon: assetCategories[asset].icon }))}
            onAdd={(asset) => handleCreate(asset)}
          />
        </Card>
        <Card>
          <CardTitle
            icon={"wallet"}
            variant="collection"
            title="Checking and saving accounts"
            sum={formatAmount(assetsSum(assets.filter(asset => ASSET_GROUPS[ASSET_TYPES.CASH_AND_EQUIVALENTS].includes(asset.category as any))), { short: true })}
            type="assets"
          />
          <PropertyList>
            {assets.filter(asset => ASSET_GROUPS[ASSET_TYPES.CASH_AND_EQUIVALENTS].includes(asset.category as any)).map(asset => (
              <Property
                data={asset}
                type={ASSET_TYPES.CASH_AND_EQUIVALENTS}
                icon='wallet'
                id={asset.id}
                key={asset.id}
                open={lastAdded === asset.id}
                onChange={(value) => handleChange(value)}
                onRemove={() => handleRemove(asset)}
              />
            ))}
          </PropertyList>
          <CardFooter onAdd={() => handleCreate(ASSET_CATEGORIES.CASH_AND_EQUIVALENTS)} />
        </Card>
      </Cards>
    </div>
  )
}

export default Assets