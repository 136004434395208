import { call, put } from "@redux-saga/core/effects";
import { Auth0Client } from '@auth0/auth0-spa-js';
import { authCallback, authGetTokenFail, authGetTokenSuccess } from "../store/actions/auth";
import { push } from "connected-react-router";

let { protocol, host } = window.location;
let redirectUri = `${protocol}//${host}/auth-callback`;
const client = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "dev-oursteward.us.auth0.com",
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || "OYxF0n1KDBgXfxZeXwHnJRladxHQS0nL",
  audience: process.env.REACT_APP_AUTH0_API_AUDIENCE || "steward-gql-dev",
  redirect_uri: redirectUri,
  useRefreshTokens: true,
  cacheLocation: "localstorage"
});

export function* login(action) {
  yield call(client.loginWithRedirect.bind(client), { action: action?.payload?.authType || "login" })
}

export function* getToken(action?) {
  let token
  try {
    token = yield call(client.getTokenSilently.bind(client));
  } catch (error) {
    console.log(error)
  }
  if (!token) {
    yield put(authGetTokenFail({ authType: action?.payload?.authType || "login" }))
  } else {
    const user = yield call(client.getUser.bind(client))
    console.log("user", user)
    yield put(authGetTokenSuccess({ user, token }))
  }
}

export function* callback(action: ReturnType<typeof authCallback>) {
  yield call(client.handleRedirectCallback.bind(client))
  yield call(getToken)
  yield put(push(action.payload.redirectTo))
}

export function* logout() {
  // yield call(client.logout.bind(client), { returnTo: `https://www.oursteward.com/` })
  yield call(client.logout.bind(client))
}