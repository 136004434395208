import { Relocation } from "../../lib/modules/timeline/types"
import Input from "../Input"
import { Household } from "../../lib/types";

const RelocationEditor = (props: {
  relocation?: Relocation
  readOnly?: boolean
  hiddenFields?: string[]
  onChange: (any) => any
  context?: { household: Household }
}) => {
  const update = (updates) => {
    const nextLoan = Object.assign({ ...(props.relocation || {}) }, updates)
    props.onChange(nextLoan)
  }

  return <div>
    <div>
      <Input
        type="number"
        name="year"
        value={props.relocation?.year + ""}
        label="Year"
        readOnly={props.readOnly}
        handleChange={(val, field) => update({ [field]: parseInt(val) })}
        min={props?.context?.household.year}
      />
    </div>
    <div>
      <Input
        type="location"
        name=""
        value={(props.relocation?.state || "") + "," + (props.relocation?.city || "")}
        readOnly={props.readOnly}
        label=""
        handleChange={(val) => update({ state: val.split(",")[0], city: val.split(",")[1] })}
      />
    </div>
  </div>
}

export default RelocationEditor