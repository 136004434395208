import { useState, useRef } from "react"
import styles from "./CardFooter.module.css"
import { IconName } from "@fortawesome/fontawesome-svg-core";
import Suggestions from "../Suggestions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardFooter = (props: {
  options?: { title: string, value: string, icon?: IconName }[],
  onAdd: (any?) => any
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const nodeRef = useRef<HTMLDivElement>(null)
  return (
    <div className={styles.container}>
      <div
        className="d-flex align-items-center gap-2 body-bold clickable"
        onClick={() => props.options ? setOpen(!open) : props.onAdd()}
        style={{ padding: ".75rem", margin: "-.75rem" }}
      >
        <FontAwesomeIcon icon={['far', 'plus']} />
        <div>Add new</div>
      </div>
      {props.options &&
        <div
          className={[styles.options].join(" ")}
          style={{ height: open ? nodeRef.current?.scrollHeight + 'px' : '0px' }}
        >
          <div ref={nodeRef} className="pt-3">
            <Suggestions
              suggestions={props.options}
              onClick={(value) => {
                setOpen(!open);
                props.onAdd(value)
              }}
            />
          </div>
        </div>
      }
    </div>
  )
}

export default CardFooter