import { availableYear } from "../../../utils/config"
import { localIncomeTaxConfig } from "../types";

const localIncomeTax = (localIncomeTaxData: localIncomeTaxConfig) =>
  (taxableAmount: number, year: number, state: string, city: string): number => {
    const configYear = availableYear(year, Object.keys(localIncomeTaxData))
    const config = localIncomeTaxData[configYear]
    const tax = config.find(item => item.state === state && item.city === city)
    if (!tax) return 0
    return taxableAmount / 100 * tax.bracket
  }

export default localIncomeTax