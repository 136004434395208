import goalCategories from "../../lib/config/goalCategories"
import { GOAL_CATEGORIES } from "../../lib/constants"
import Suggestions from "../Suggestions"

const GoalTemplates = (props: {
  onClick: (any) => any
}) => {
  const goalCategoryOrder = [
    GOAL_CATEGORIES.EMERGENCY_FUND,
    GOAL_CATEGORIES.HOUSE,
    GOAL_CATEGORIES.NEW_FAMILY_MEMBER,
    GOAL_CATEGORIES.EDUCATION,
    GOAL_CATEGORIES.CAR,
    GOAL_CATEGORIES.HEALTH,
    GOAL_CATEGORIES.HOME_REMODEL,
    GOAL_CATEGORIES.BUSINESS,
    GOAL_CATEGORIES.OTHER
  ]

  return <Suggestions onClick={props.onClick} suggestions={goalCategoryOrder.map(category => ({
    title: goalCategories[category].title,
    value: category,
    icon: goalCategories[category].icon
  }))} />
}

export default GoalTemplates