import { availableYear } from "../../../utils/config"
import { medicareTaxConfig } from "../types";
import { RELATIONSHIP_STATUS_TYPES } from "../../../constants";

const medicareTax = (medicareTaxData: medicareTaxConfig) =>
  (taxableAmount: number, year: number, relationshipStatus: keyof typeof RELATIONSHIP_STATUS_TYPES): number => {
    const configYear = availableYear(year, Object.keys(medicareTaxData))
    const config = medicareTaxData[configYear][relationshipStatus]
    const tax = config.find(item =>
      item.min <= taxableAmount && (!item.max || item.max >= taxableAmount))
    if (!tax) return 0
    const result = (taxableAmount - tax.min) / 100 * tax.bracket
    return tax.floor ? tax.floor + result : result

  }

export default medicareTax