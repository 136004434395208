import styles from "./Property.module.css"
import {
  ASSET_TYPES,
  EXPENSE_TYPES,
  INCOME_TYPES,
  LOCATION,
  ACTIVITY,
  NAME, YEAR_OF_BIRTH, DEBT, INFO_CATEGORIES, ASSET_CATEGORIES
} from "../../lib/constants"

import { useEffect, useRef, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../Input";
import { assetNames, contributionTypes, incomeNames } from "../../lib/config/copy";
import { capitalize } from "../../lib/utils/text";
import { formatAmount } from "../../lib/utils/number"
import useCaptureOutsideClick from "../../hooks/useCaptureOutsideClick";
import debtCategories from "../../lib/config/debtCategories";
import expenseCategories from "../../lib/config/expenseCategories";
import Info from "../Info";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Collapse } from "react-bootstrap";

type PropertyType =
  keyof typeof ASSET_TYPES |
  keyof typeof EXPENSE_TYPES |
  keyof typeof INCOME_TYPES |
  typeof LOCATION |
  typeof NAME |
  typeof YEAR_OF_BIRTH |
  typeof ACTIVITY |
  typeof DEBT

const Property = (props: {
  id: string
  type: PropertyType
  icon?: IconName
  data: {
    [K: string]: any
  }
  context?: {
    [K: string]: any
  }
  open?: boolean
  onChange?: (any) => any
  onSubmit?: (any) => any
  onRemove?: (any?) => any
}) => {

  const containerRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState<boolean>(false)

  useCaptureOutsideClick({ listen: open, ref: containerRef, onClick: () => setOpen(false) })

  useEffect(() => {
    if (props.open) setTimeout(() => setOpen(true))
  }, [props.open])

  const handleChange = (_value, name) => {
    let val = Object.assign({}, props.data)
    if (name === 'location') {
      val['state'] = _value.split(',')[0]
      val['city'] = _value.split(',')[1]
      if (props.onChange) props.onChange(val)
      return
    }
    val[name] = _value
    if (props.onChange) props.onChange(val)
  }

  const handleRemove = (e) => {
    e.stopPropagation()
    if (props.onRemove) props.onRemove()
  }

  const renderInputs = (type, data) => {
    if (Object.keys(ASSET_TYPES).includes(type)) {
      return (
        <>
          <Input
            type='text'
            name='name'
            label='Name'
            placeholder={`Fill name of ${assetNames[type]}`}
            value={data?.name || ''}
            handleChange={handleChange}
          />
          <Input
            type='amount'
            name='value'
            label='Value'
            placeholder={`Fill ${type === ASSET_TYPES.CASH_AND_EQUIVALENTS ? 'balance' : 'value'} of ${assetNames[type]}`}
            tooltip={props?.data?.category === ASSET_CATEGORIES.HOUSE && <Info category={INFO_CATEGORIES.HOME_ASSET_VALUE} />}
            value={data?.value || ''}
            handleChange={handleChange}
          />
        </>
      )
    } else if (type === DEBT) {
      return (
        <>
          <Input
            type='text'
            name='name'
            label='Name'
            placeholder={data.category ? debtCategories[data.category].title : "Fill name of debt"}
            value={data?.name || ''}
            handleChange={handleChange}
          />
          <Input
            type='amount'
            name='outstandingLoanBalance'
            label='Outstanding loan balance'
            placeholder={`Fill outstanding loan balance`}
            value={data?.outstandingLoanBalance || ''}
            handleChange={handleChange}
          />
        </>
      )
    } else if (Object.keys(EXPENSE_TYPES).includes(type)) {
      return (
        <>
          <Input
            type='frequency'
            name='frequency'
            label='Frequency'
            value={data?.frequency || ''}
            handleChange={handleChange}
          />
          <Input
            type='text'
            name='name'
            label='Name'
            placeholder={data.category ? expenseCategories[data.category].title : "Expense"}
            value={data?.name || ''}
            handleChange={handleChange}
          />
          <Input
            type='amount'
            name='amount'
            label='Amount'
            placeholder={`Fill cost of ${data.name ? data.name : expenseCategories[data.category].title}`}
            value={data?.amount || ''}
            handleChange={handleChange}
            tooltip={expenseCategories[data.category].tooltip && <Info category={expenseCategories[data.category].tooltip} />}
          />
          {type === EXPENSE_TYPES.LIABILITY &&
            <Input
              type='number'
              name='years'
              label='Remaining years'
              value={data?.years || ''}
              handleChange={handleChange}
              tooltip={expenseCategories[data.category].tooltip && <Info category={expenseCategories[data.category].tooltip} />}
            />
          }
        </>
      )
    } else if (Object.keys(INCOME_TYPES).includes(type)) {
      return (
        <>
          {type === INCOME_TYPES.OTHER_ANNUAL_INCOME &&
            <Input
              type='text'
              name='name'
              label='Name'
              placeholder={capitalize(incomeNames[type])}
              value={data?.name || ''}
              handleChange={handleChange}
            />
          }
          <Input
            type='amount'
            name='amount'
            label='Amount'
            placeholder='Fill amount'
            value={data?.amount || ''}
            handleChange={handleChange}
          />
        </>
      )
    } else if (type === LOCATION) {
      return (
        <Input
          type='location'
          name='location'
          label='Location'
          value={(data?.state || '') + ',' + (data?.city || '')}
          handleChange={handleChange}
        />
      )
    } else if (type === NAME) {
      return (
        <>
          <Input
            type='text'
            name='firstName'
            label='First name'
            placeholder='Fill first name'
            value={data?.firstName || ""}
            handleChange={handleChange}
          />
          <Input
            type='text'
            name='lastName'
            label='Last name'
            placeholder='Fill last name'
            value={data?.lastName || ""}
            handleChange={handleChange}
          />
        </>
      )
    } else if (type === YEAR_OF_BIRTH) {
      return (
        <Input
          type='number'
          name='yearOfBirth'
          label='Year of birth'
          placeholder='Fill year'
          value={data?.yearOfBirth || null}
          handleChange={handleChange}
          min={new Date().getFullYear() - 100}
        />
      )
    }
  }

  const renderLabel = (type, data) => {
    if (Object.keys(ASSET_TYPES).includes(type)) {
      return capitalize((data?.name || "-")) + (contributionTypes[data.accountType] ? ` (${contributionTypes[data.accountType]})` : "")
    } else if (Object.keys(EXPENSE_TYPES).includes(type)) {
      return capitalize(data?.name || (data.category && expenseCategories[data.category].title) || "Expense")
    } else if (type === DEBT) {
      return capitalize(data?.name || (data.category && debtCategories[data.category].title) || "Debt")
    } else if ([INCOME_TYPES.ANNUAL_BASE_SALARY, INCOME_TYPES.ANNUAL_BONUS].includes(type)) {
      return capitalize(incomeNames[type])
    } else if (type === INCOME_TYPES.OTHER_ANNUAL_INCOME) {
      return capitalize(data?.name || "Other annual income")
    } else if (type === LOCATION) {
      return "Location of household"
    } else if (type === NAME) {
      return "Name"
    } else if (type === YEAR_OF_BIRTH) {
      return "Year of birth"
    } else {
      return type
    }
  }

  const renderValue = (type, data, context) => {
    if (type === ASSET_TYPES.INVESTMENT) {
      return data?.value ? formatAmount(data.value)
        : <span className="text-gray-40 fw-light">Fill value of {data.name ? data.name : 'investment'}</span>
    } else if (type === ASSET_TYPES.CASH_AND_EQUIVALENTS) {
      return data?.value ? formatAmount(data.value)
        : <span className="text-gray-40 fw-light">Fill balance of {data.name ? data.name : 'account'}</span>
    } else if (type === DEBT) {
      return data?.outstandingLoanBalance ? formatAmount(data.outstandingLoanBalance)
        : <span className="text-gray-40 fw-light">Fill outstanding loan balance</span>
    } else if (type === ASSET_TYPES.PROPERTY) {
      return data?.value ? formatAmount(data.value)
        : <span className="text-gray-40 fw-light">Fill value of {data.name ? data.name : 'property'}</span>
    } else if (Object.keys(EXPENSE_TYPES).includes(type)) {
      return data?.amount ? formatAmount(data.amount, { frequency: data.frequency })
        : <span className="text-gray-40 fw-light">Fill cost of {data.name ? data.name : expenseCategories[data.category].title}</span>
    } else if (type === EXPENSE_TYPES.LIABILITY) {
      return data?.outstandingLoanBalance ? formatAmount(data.outstandingLoanBalance)
        : <span className="text-gray-40 fw-light">Fill outstanding balance {data.name ? 'of ' + data.name : ''}</span>
    } else if ([INCOME_TYPES.ANNUAL_BASE_SALARY, INCOME_TYPES.ANNUAL_BONUS].includes(type)) {
      return data?.amount ? formatAmount(data.amount)
        : <span className="text-gray-40 fw-light">Fill {context?.firstName ? context.firstName + '\'s ' : ''}{incomeNames[type]}</span>
    } else if (type === INCOME_TYPES.OTHER_ANNUAL_INCOME) {
      return data?.amount ? formatAmount(data.amount)
        : <span className="text-gray-40 fw-light">Fill {context?.firstName ? context.firstName + '\'s ' : ''}other income</span>
    } else if (type === LOCATION) {
      return [data?.state, data?.city].filter(val => val).join(", ")
        || <span className="text-gray-40 fw-light">Select a location</span>
    } else if (type === NAME) {
      return data?.firstName ? data?.firstName + ' ' + data?.lastName
        : <span className="text-gray-40 fw-light">Fill name</span>
    } else if (type === YEAR_OF_BIRTH) {
      return data?.yearOfBirth
        || <span className="text-gray-40 fw-light">Select a year</span>
    } else {
      return type
    }
  }

  return (
    <div className={[styles.container, (open ? styles.open : "")].join(" ")} ref={containerRef}>
      <div
        className={[
          styles.titles,
          "d-flex gap-2 align-items-center justify-content-between"
        ].join(" ")}
        onClick={() => setOpen(!open)}
      >
        <div className="d-flex gap-3 align-items-center justify-content-start">
          <FontAwesomeIcon icon={['far', props.icon || 'circle']} />
          <div>
            <div className={styles.typeName}>{renderLabel(props.type, props.data)}</div>
            <div className={styles.value}>{renderValue(props.type, props.data, props.context)}</div>
          </div>
        </div>
        {props.onRemove &&
          <div
            className={[
              styles.remove
            ].join(" ")}
            onClick={handleRemove}>
            <div className={styles.clickArea} />
            <FontAwesomeIcon icon={["far", "trash-can"]} />
          </div>
        }
      </div>
      <Collapse in={open}>
        <div className={styles.editor}>
          <div className={styles.inputsContainer}>
            {renderInputs(props.type, props.data)}
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default Property