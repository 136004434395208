import SidebarPanelTitle from "./SidebarPanelTitle";
import Pill from "../Pill";
import SidebarPanel from "./SidebarPanel";
import { TimelineResult } from "../../lib/modules/timeline/types";
import { GOAL_CATEGORIES, INFO_CATEGORIES, MEMBER_TYPES } from "../../lib/constants";
import CircleIcon from "../CircleIcon";
import { useMemo } from "react";
import { Member } from "../../lib/types";
import { formatAmount } from "../../lib/utils/number";
import CTACard from "../CTACard";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { emptyStates } from "../../lib/config/copy";
import Tooltip from "../Tooltip";
import Info from "../Info";


const RetirementForecast = (
  { members, timeline, empty, className }:
    {
      members: Member[],
      timeline: TimelineResult,
      empty?: boolean,
      className?: string
    }
) => {

  const retirementGoal = useMemo(() => {
    return timeline.goals?.[GOAL_CATEGORIES.RETIREMENT]
  }, [timeline])

  const retirementYear = useMemo(() => {
    const keys = Object.keys(retirementGoal?.savings || {})
    return keys.length ? keys[keys.length - 1] : ""
  }, [retirementGoal])

  const dispatch = useDispatch()

  return (<div className={className || ""}>
      <SidebarPanel>
        <SidebarPanelTitle>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='body-small-bold'>Option not to work</div>
            <Pill
              content={retirementGoal?.reached && !empty ? `Possible in ${retirementYear}` : 'Needs attention'}
              negative={!retirementGoal?.reached || empty}
              variant='retirement'
              icon={retirementGoal?.reached && !empty ? 'rocket' : null}
            />
          </div>
        </SidebarPanelTitle>
        {
          empty ? (
            <div className='p-3'>
              <CTACard
                title={emptyStates.retirementGoal.title}
                icons={{ prefix: 'far', items: ['rocket'] }}
                cta={emptyStates.retirementGoal.cta}
                action={() => dispatch(push('/household'))}
                primary={true}
              >
                <div>
                  Add your household details in <span className='text-decoration-underline clickable' onClick={() => dispatch(push('/household'))}>My Household</span> so Steward can calculate your forecasted retirement age and year accurately
                </div>
              </CTACard>
            </div>
          ) : (
            <div className='p-3'>
              {members
                .filter(member => [MEMBER_TYPES.PRIMARY, MEMBER_TYPES.PARTNER as any].includes(member.type))
                .map((filteredMember, index) => (
                  <div className='d-flex align-items-center gap-2 mb-2' key={index}>
                    <CircleIcon color={filteredMember.color}>{filteredMember.firstName.split('')[0]}</CircleIcon>
                    <div className='caption-bold'>
                      <div className='text-gray-60'>{filteredMember.firstName + '\'s option not to work age'}</div>
                      {retirementYear && filteredMember.yearOfBirth &&
                        <div className='headline-6'>{parseInt(retirementYear) - filteredMember.yearOfBirth}</div>
                      }
                    </div>
                  </div>
                ))
              }
              <div className='d-flex justify-content-between align-items-center bg-elevation-1 rounded p-2 caption-bold text-gray-60'>
                <div className='w-75'>
                  You'll need <span className='text-black'>{formatAmount(retirementGoal?.savingsTarget, { short: true })}</span> in liquid assets to have the option not to work
                </div>
                <Tooltip>
                  <Info category={INFO_CATEGORIES.RETIREMENT_TARGET_INFO} />
                </Tooltip>
              </div>
            </div>
          )
        }
      </SidebarPanel>
    </div>
  )
}

export default RetirementForecast
