import { RELATIONSHIP_STATUS_TYPES } from "../../../constants"
import { IRALimitsConfig, traditionalIRATaperingConfig } from "../types"
import { availableYear } from "../../../utils/config"

// TODO this is just a copy from previous app, needs proper specifications

export const findLimit = (income: number, limit: number, bottom: number, step: number) => {
  const difference = income - bottom
  if (difference <= 0) return limit
  const deductFromLimit = difference / 1000 * step
  return Math.max(0, limit - deductFromLimit)
}

const traditionalIRADeduction = (
  IRALimits: IRALimitsConfig,
  traditionalIRATaperingData: traditionalIRATaperingConfig
) => (
  taxableIncome: number,
  year: number,
  relationshipStatus: keyof typeof RELATIONSHIP_STATUS_TYPES,
  traditionalIRAContributions: number[],
  traditional401kContributions: number[]
): number => {
  const limitConfigYear = availableYear(year, Object.keys(IRALimits))
  const taperingConfigYear = availableYear(year, Object.keys(traditionalIRATaperingData))
  const individualLimit = IRALimits[limitConfigYear][0].limit
  const taperingRange = traditionalIRATaperingData[taperingConfigYear][relationshipStatus]
  const totalContribution = traditionalIRAContributions.reduce((sum, contribution) => sum + contribution, 0)

  const limit = individualLimit * ((relationshipStatus === RELATIONSHIP_STATUS_TYPES.married && traditional401kContributions.length) || 1)

  if (!traditional401kContributions.length) return Math.min(limit, totalContribution)

  if (
    relationshipStatus === RELATIONSHIP_STATUS_TYPES.single ||
    traditional401kContributions.length > 1
  ) {
    return Math.min(
      totalContribution,
      findLimit(
        taxableIncome,
        limit,
        taperingRange.covered[0],
        (taperingRange.covered[1] - taperingRange.covered[0]) / 1000
      )
    )
  } else {
    const primaryBottom = taperingRange.covered[0]
    const partnerBottom = taperingRange?.spouseCovered?.[0] || 0
    const primaryStep = (taperingRange.covered[1] - primaryBottom) / 1000
    const partnerStep = ((taperingRange?.spouseCovered?.[1] || 0) - partnerBottom) / 1000
    const totalLimit = Math.max(0, findLimit(taxableIncome, limit, primaryBottom, primaryStep) + findLimit(taxableIncome, limit, partnerBottom, partnerStep))
    return Math.min(totalContribution, totalLimit)
  }
}

export default traditionalIRADeduction