import styles from './Stacks.module.css'
import { StackData } from "../../lib/types";

const Stacks = ({ cols }: { cols?: StackData[][] }) => {
  if (!cols) return <></>
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {cols.map((col, index) => (
          <div key={index} className={styles.col}>
            {col.map((stack, _index) => (
              [stack.percentage > 0 && (
                <div
                  key={_index + '_' + stack.title}
                  className={`${styles.stack}`}
                  style={{ maxHeight: stack.percentage + '%', background: stack.color }}
                >
                  <div className={[stack.light ? 'text-black' : 'text-white', 'body-bold'].join(' ')}>{stack.title}</div>
                </div>
              )]
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Stacks