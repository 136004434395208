import { ActionCreator, Asset } from "../../lib/types"

export const SET_ASSETS = "ASSETS/setAssets"
export const ADD_ASSET = "ASSETS/addAsset"
export const UPDATE_ASSET = "ASSETS/updateAsset"
export const REMOVE_ASSET = "ASSETS/removeAsset"

export const setAssets: ActionCreator<{
  [K in keyof Asset]: Asset[K]
}[]> = (payload) => {
  return {
    type: SET_ASSETS,
    payload
  }
}
export const addAsset: ActionCreator<{
  [K in keyof Asset]: Asset[K]
}> = (payload) => {
  return {
    type: ADD_ASSET,
    payload
  }
}

export const updateAsset: ActionCreator<{
  [K in keyof Asset]: Asset[K]
}> = (payload) => {
  return {
    type: UPDATE_ASSET,
    payload
  }
}

export const removeAsset: ActionCreator<{
  id: string
}> = (payload) => {
  return {
    type: REMOVE_ASSET,
    payload
  }
}