import { useEffect, useMemo, useState } from "react"
import { formatAmount } from "../../lib/utils/number"
import styles from "./FlowChart.module.css"

const FlowChart = (props: {
  inflow: number
  outflow: number
}) => {
  const savingsClasses = useMemo(() => {
    return [
      "savingsSmaller",
      "savingsEqual",
      "savingsGreater",
    ]
  }, [])

  const [savingsClass, setSavingsClass] = useState(savingsClasses[0])

  useEffect(() => {
    const savings = props.inflow - props.outflow
    if (savings > props.outflow) {
      setSavingsClass(savingsClasses[2])
    } else if (savings === props.outflow) {
      setSavingsClass(savingsClasses[1])
    }
  }, [props, savingsClasses, setSavingsClass])

  return <div className={[styles.container, styles?.[savingsClass]].join(" ")}>
    <div className={styles.chart}>
      <div className={styles.inflow}>
        <div className="small fw-light">Income</div>
        <div className="fw-bold">{formatAmount(props.inflow, { short: true })}</div>
      </div>
      <div className={styles.outflow}>
        <div className="small fw-light">Taxes and Expenses</div>
        <div className="fw-bold">{formatAmount(props.outflow, { short: true })}</div>
      </div>
      <div className={styles.savings}>
        <div className="small fw-light">Savings {formatAmount(props.inflow - props.outflow, { short: true })}</div>
        {(props.inflow && props.outflow && (
          <div className="fw-bold">{Math.floor((props.inflow - props.outflow) / (props.inflow / 100))}% of your income</div>
        )) || ""}
        <img className={styles.savingsArrow} src="/savings_arrow.svg" alt="" />
      </div>
      <div className={styles.mask}></div>
      <div className={styles.savingsConnection}></div>
    </div>
  </div>
}

export default FlowChart