import { Loan } from "../../lib/modules/timeline/types"
import { ActionCreator } from "../../lib/types"

export const SET_LOANS = "LOANS/setLoans"
export const ADD_LOAN = "LOANS/addLoan"
export const UPDATE_LOAN = "LOANS/updateLoan"
export const REMOVE_LOAN = "LOANS/removeLoan"


export const setLoans: ActionCreator<{
  [K in keyof Loan]: Loan[K]
}[]> = (payload) => {
  return {
    type: SET_LOANS,
    payload
  }
}
export const addLoan: ActionCreator<{
  [K in keyof Loan]: Loan[K]
}> = (payload) => {
  return {
    type: ADD_LOAN,
    payload
  }
}

export const updateLoan: ActionCreator<{
  [K in keyof Loan]: Loan[K]
}> = (payload) => {
  return {
    type: UPDATE_LOAN,
    payload
  }
}

export const removeLoan: ActionCreator<{
  id: string
}> = (payload) => {
  return {
    type: REMOVE_LOAN,
    payload
  }
}