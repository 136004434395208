import federalIncomeTax from "../taxes/federalIncomeTax";
import federalIncomeTaxData from "../data/federalIncomeTaxData";
import stateIncomeTax from "../taxes/stateIncomeTax";
import stateIncomeTaxProgressiveData from "../data/stateIncomeTaxProgressiveData";
import stateIncomeTaxData from "../data/stateIncomeTaxData";
import localIncomeTax from "../taxes/localIncomeTax";
import localIncomeTaxData from "../data/localIncomeTaxData";
import socialSecurityTax from "../taxes/socialSecurityTax";
import socialSecurityTaxData from "../data/socialSecurityTaxData";
import medicareTaxData from "../data/medicareTaxData";
import medicareTax from "./medicareTax";

export default {
  federalIncomeTax: federalIncomeTax(federalIncomeTaxData),
  stateIncomeTax: stateIncomeTax(stateIncomeTaxProgressiveData, stateIncomeTaxData),
  localIncomeTax: localIncomeTax(localIncomeTaxData),
  socialSecurityTax: socialSecurityTax(socialSecurityTaxData),
  medicareTax: medicareTax(medicareTaxData)
}