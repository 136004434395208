import { standardFederalDeductionConfig } from "../types";

const standardFederalDeductionData: standardFederalDeductionConfig = {
  "2021": {
    single: 12550,
    married: 25100
  },
  "2022": {
    single: 12950,
    married: 25900
  }
}

export default standardFederalDeductionData