import { EXPENSE_TYPES, SAVING } from "../../constants";
import { comparisonWords } from "../../config/copy";

export const incomeText = (percentage: number): string => {
  return `You're in the top ${percentage}% earners in the US!`
}

export const comparisonText = (type: typeof EXPENSE_TYPES.EXPENSE | typeof SAVING, percentage: number, positive: boolean): string => {
  const actionWord = comparisonWords[type]
  let relevantWord: string = positive ? 'more' : 'less'
  if (type === EXPENSE_TYPES.EXPENSE) {
    relevantWord = positive ? 'less' : 'more'
  }
  return `You're ${actionWord} ${Math.abs(percentage)}% ${relevantWord} than the recommended!`
}