import { ActionCreator } from "../../lib/types";

export const GET_ADMIN_HOUSEHOLD_ID_REQUEST = "ADMIN/getAdminHouseholdIdRequest"
export const GET_ADMIN_HOUSEHOLD_ID_SUCCESS = "ADMIN/getAdminHouseholdIdSuccess"
export const GET_ADMIN_HOUSEHOLD_ID_FAILED = "ADMIN/getAdminHouseholdIdFailed"

export const SET_ADMIN_HOUSEHOLD_ID_REQUEST = "ADMIN/setAdminHouseholdIdRequest"
export const SET_ADMIN_HOUSEHOLD_ID_SUCCESS = "ADMIN/setAdminHouseholdIdSuccess"
export const SET_ADMIN_HOUSEHOLD_ID_FAILED = "ADMIN/setAdminHouseholdIdFailed"

export const DELETE_ADMIN_HOUSEHOLD_ID_REQUEST = "ADMIN/deleteAdminHouseholdIdRequest"
export const DELETE_ADMIN_HOUSEHOLD_ID_SUCCESS = "ADMIN/deleteAdminHouseholdIdSuccess"
export const DELETE_ADMIN_HOUSEHOLD_ID_FAILED = "ADMIN/deleteAdminHouseholdIdFailed"

export const getAdminHouseholdIdRequest: ActionCreator = (payload) => {
  return {
    type: GET_ADMIN_HOUSEHOLD_ID_REQUEST,
    payload
  }
}

export const getAdminHouseholdIdSuccess: ActionCreator = (payload) => {
  return {
    type: GET_ADMIN_HOUSEHOLD_ID_SUCCESS,
    payload
  }
}

export const getAdminHouseholdIdFailed: ActionCreator = (payload) => {
  return {
    type: GET_ADMIN_HOUSEHOLD_ID_FAILED,
    payload
  }
}

export const setAdminHouseholdIdRequest: ActionCreator<string> = (payload) => {
  return {
    type: SET_ADMIN_HOUSEHOLD_ID_REQUEST,
    payload
  }
}

export const setAdminHouseholdIdSuccess: ActionCreator = (payload) => {
  return {
    type: SET_ADMIN_HOUSEHOLD_ID_SUCCESS,
    payload
  }
}

export const setAdminHouseholdIdFailed: ActionCreator = (payload) => {
  return {
    type: SET_ADMIN_HOUSEHOLD_ID_FAILED,
    payload
  }
}

export const deleteAdminHouseholdIdRequest: ActionCreator = (payload) => {
  return {
    type: DELETE_ADMIN_HOUSEHOLD_ID_REQUEST,
    payload
  }
}

export const deleteAdminHouseholdIdSuccess: ActionCreator = (payload) => {
  return {
    type: DELETE_ADMIN_HOUSEHOLD_ID_SUCCESS,
    payload
  }
}

export const deleteAdminHouseholdIdFailed: ActionCreator = (payload) => {
  return {
    type: DELETE_ADMIN_HOUSEHOLD_ID_FAILED,
    payload
  }
}