const locations = {
  "Alabama": [
     "Bessemer",
     "Birmingham",
     "Gadsden",
     "Huntsville",
     "Mobile",
     "Montgomery",
     "Tuscaloosa",
     "Other"
  ],
  "Alaska": [
     "Anchorage",
     "Other"
  ],
  "Arizona": [
     "Chandler",
     "Gilbert",
     "Glendale",
     "Mesa",
     "Peoria",
     "Phoenix",
     "Scottsdale",
     "Surprise",
     "Tempe",
     "Tucson",
     "Other"
  ],
  "Arkansas": [
     "Little Rock",
     "Other"
  ],
  "California": [
     "Anaheim",
     "Antioch",
     "Bakersfield",
     "Berkeley",
     "Burbank",
     "Carlsbad",
     "Chico",
     "Chula Vista",
     "Clovis",
     "Concord",
     "Corona",
     "Costa Mesa",
     "Daly City",
     "Downey",
     "El Cajon",
     "El Monte",
     "Elk Grove",
     "Escondido",
     "Fairfield",
     "Fontana",
     "Fremont",
     "Fresno",
     "Fullerton",
     "Garden Grove",
     "Glendale",
     "Hayward",
     "Huntington Beach",
     "Inglewood",
     "Irvine",
     "Jurupa Valley",
     "Lancaster",
     "Long Beach",
     "Los Angeles",
     "Modesto",
     "Moreno Valley",
     "Murrieta",
     "Norwalk",
     "Oakland",
     "Oceanside",
     "Ontario",
     "Orange",
     "Oxnard",
     "Palmdale",
     "Pasadena",
     "Pomona",
     "Rancho Cucamonga",
     "Rialto",
     "Richmond",
     "Riverside",
     "Roseville",
     "Sacramento",
     "Salinas",
     "San Bernardino",
     "San Diego",
     "San Francisco",
     "San Jose",
     "San Mateo",
     "Santa Ana",
     "Santa Clara",
     "Santa Clarita",
     "Santa Maria",
     "Santa Rosa",
     "Simi Valley",
     "Stockton",
     "Sunnyvale",
     "Temecula",
     "Thousand Oaks",
     "Torrance",
     "Vacaville",
     "Vallejo",
     "Ventura",
     "Victorville",
     "Visalia",
     "Vista",
     "West Covina",
     "Other"
  ],
  "Colorado": [
     "Arvada",
     "Aurora",
     "Boulder",
     "Centennial",
     "Colorado Springs",
     "Denver",
     "Fort Collins",
     "Greeley",
     "Lakewood",
     "Pueblo",
     "Thornton",
     "Westminster",
     "Other"
  ],
  "Connecticut": [
     "Bridgeport",
     "Hartford",
     "New Haven",
     "Stamford",
     "Waterbury",
     "Other"
  ],
  "District of Columbia": [
     "Washington",
     "Other"
  ],
  "Florida": [
     "Cape Coral",
     "Clearwater",
     "Coral Springs",
     "Davie",
     "Fort Lauderdale",
     "Gainesville",
     "Hialeah",
     "Hollywood",
     "Jacksonville",
     "Lakeland",
     "Miami",
     "Miami Gardens",
     "Miramar",
     "Orlando",
     "Palm Bay",
     "Pembroke Pines",
     "Pompano Beach",
     "Port St. Lucie",
     "St. Petersburg",
     "Tallahassee",
     "Tampa",
     "West Palm Beach",
     "Other"
  ],
  "Georgia": [
     "Athens",
     "Atlanta",
     "Augusta",
     "Columbus",
     "Macon",
     "Sandy Springs",
     "Savannah",
     "South Fulton",
     "Other"
  ],
  "Hawaii": [
     "Honolulu",
     "Other"
  ],
  "Idaho": [
     "Boise",
     "Meridian",
     "Nampa",
     "Other"
  ],
  "Illinois": [
     "Aurora",
     "Chicago",
     "Elgin",
     "Joliet",
     "Naperville",
     "Peoria",
     "Rockford",
     "Springfield",
     "Other"
  ],
  "Indiana": [
     "Carmel",
     "Evansville",
     "Fort Wayne",
     "Indianapolis",
     "South Bend",
     "Other"
  ],
  "Iowa": [
     "Cedar Rapids",
     "Davenport",
     "Des Moines",
     "Other"
  ],
  "Kansas": [
     "Kansas City",
     "Olathe",
     "Overland Park",
     "Topeka",
     "Wichita",
     "Other"
  ],
  "Kentucky": [
     "Lexington",
     "Louisville",
     "Other"
  ],
  "Louisiana": [
     "Baton Rouge",
     "Lafayette",
     "New Orleans",
     "Shreveport",
     "Other"
  ],
  "Maryland": [
     "Baltimore",
     "Columbia",
     "Other"
  ],
  "Massachusetts": [
     "Boston",
     "Cambridge",
     "Lowell",
     "Springfield",
     "Worcester",
     "Other"
  ],
  "Michigan": [
     "Ann Arbor",
     "Detroit",
     "Grand Rapids",
     "Highland Park",
     "Lansing",
     "Saginaw",
     "Sterling Heights",
     "Warren",
     "Other"
  ],
  "Minnesota": [
     "Minneapolis",
     "Rochester",
     "Saint Paul",
     "Other"
  ],
  "Mississippi": [
     "Jackson",
     "Other"
  ],
  "Missouri": [
     "Columbia",
     "Independence",
     "Kansas City",
     "Lee's Summit",
     "Springfield",
     "St. Louis",
     "Other"
  ],
  "Montana": [
     "Billings",
     "Other"
  ],
  "Nebraska": [
     "Lincoln",
     "Omaha",
     "Other"
  ],
  "Nevada": [
     "Henderson",
     "Las Vegas",
     "North Las Vegas",
     "Reno",
     "Sparks",
     "Other"
  ],
  "New Hampshire": [
     "Manchester",
     "Other"
  ],
  "New Jersey": [
     "Elizabeth",
     "Jersey City",
     "Newark",
     "Paterson",
     "Other"
  ],
  "New Mexico": [
     "Albuquerque",
     "Las Cruces",
     "Other"
  ],
  "New York": [
     "Buffalo",
     "New York",
     "Rochester",
     "Syracuse",
     "Yonkers",
     "Other"
  ],
  "North Carolina": [
     "Cary",
     "Charlotte",
     "Durham",
     "Fayetteville",
     "Greensboro",
     "High Point",
     "Raleigh",
     "Wilmington",
     "Winston–Salem",
     "Other"
  ],
  "North Dakota": [
     "Fargo",
     "Other"
  ],
  "Ohio": [
     "Akron",
     "Cincinnati",
     "Cleveland",
     "Columbus",
     "Dayton",
     "Toledo",
     "Youngstown",
     "Other"
  ],
  "Oklahoma": [
     "Broken Arrow",
     "Norman",
     "Oklahoma City",
     "Tulsa",
     "Other"
  ],
  "Oregon": [
     "Bend",
     "Eugene",
     "Gresham",
     "Hillsboro",
     "Portland",
     "Salem",
     "Other"
  ],
  "Pennsylvania": [
     "Allentown",
     "Bethlehem",
     "Lancaster",
     "Philadelphia",
     "Pittsburgh",
     "Reading",
     "Scranton",
     "Other"
  ],
  "Rhode Island": [
     "Providence",
     "Other"
  ],
  "South Carolina": [
     "Charleston",
     "Columbia",
     "North Charleston",
     "Other"
  ],
  "South Dakota": [
     "Sioux Falls",
     "Other"
  ],
  "Tennessee": [
     "Chattanooga",
     "Clarksville",
     "Knoxville",
     "Memphis",
     "Murfreesboro",
     "Nashville",
     "Other"
  ],
  "Texas": [
     "Abilene",
     "Allen",
     "Amarillo",
     "Arlington",
     "Austin",
     "Beaumont",
     "Brownsville",
     "Carrollton",
     "College Station",
     "Corpus Christi",
     "Dallas",
     "Denton",
     "Edinburg",
     "El Paso",
     "Fort Worth",
     "Frisco",
     "Garland",
     "Grand Prairie",
     "Houston",
     "Irving",
     "Killeen",
     "Laredo",
     "League City",
     "Lewisville",
     "Lubbock",
     "McAllen",
     "McKinney",
     "Mesquite",
     "Midland",
     "Odessa",
     "Pasadena",
     "Pearland",
     "Plano",
     "Richardson",
     "Round Rock",
     "San Angelo",
     "San Antonio",
     "Sugar Land",
     "Tyler",
     "Waco",
     "Wichita Falls",
     "Other"
  ],
  "Utah": [
     "Provo",
     "Salt Lake City",
     "West Jordan",
     "West Valley City",
     "Other"
  ],
  "Virginia": [
     "Alexandria",
     "Chesapeake",
     "Hampton",
     "Newport News",
     "Norfolk",
     "Richmond",
     "Virginia Beach",
     "Other"
  ],
  "Washington": [
     "Bellevue",
     "Everett",
     "Kent",
     "Renton",
     "Seattle",
     "Spokane",
     "Spokane Valley",
     "Tacoma",
     "Vancouver",
     "Other"
  ],
  "Wisconsin": [
     "Green Bay",
     "Madison",
     "Milwaukee"
  ]
}

export default locations