import { availableYear } from "../../../utils/config"
import { socialSecurityTaxConfig } from "../types";

const socialSecurityTax = (socialSecurityTaxData: socialSecurityTaxConfig) =>
  (taxableAmount: number, year: number): number => {
    const configYear = availableYear(year, Object.keys(socialSecurityTaxData))
    const config = socialSecurityTaxData[configYear]
    const taxable = taxableAmount > config.maxTaxableAmount ? config.maxTaxableAmount : taxableAmount
    return taxable / 100 * config.bracket
  }

export default socialSecurityTax