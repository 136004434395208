import { ActionCreator, Household } from "../../lib/types"

export const SET_HOUSEHOLD = "HOUSEHOLD/setHousehold"

export const setHousehold: ActionCreator<{
  [K in keyof Household]?: Household[K]
}> = (payload) => {
  return {
    type: SET_HOUSEHOLD,
    payload
  }
}
