import { Member } from "../../lib/types"
import { UNLOAD_DATA } from "../actions/data"
import { ADD_MEMBER, REMOVE_MEMBER, SET_MEMBERS, UPDATE_MEMBER } from "../actions/members"

const initial: Member[] = []

const members = (state = initial, action) => {
  switch (action.type) {
    case SET_MEMBERS:
      return action.payload || []

    case ADD_MEMBER:
      const newState = state.filter(member => member.id !== action.payload.id)
      newState.push(action.payload)
      return newState

    case UPDATE_MEMBER:
      return state.map(member => {
        if (member.id === action.payload.id) member = Object.assign({}, member, action.payload)
        return member
      })

    case REMOVE_MEMBER:
      return state.filter(member => member.id !== action.payload.id)

    case UNLOAD_DATA:
      return initial

    default:
      return state
  }
}

export default members