import { ASSET_TYPES, CONTRIBUTION_TYPES, EXPENSE_TYPES, INCOME_TYPES, SAVING, TAX_TYPES } from "../constants";

export const incomeNames = {
  [INCOME_TYPES.ANNUAL_BASE_SALARY]: 'annual base salary',
  [INCOME_TYPES.ANNUAL_BONUS]: 'annual bonus',
  [INCOME_TYPES.OTHER_ANNUAL_INCOME]: 'other annual income'
}

export const expenseNames = {
  [EXPENSE_TYPES.EXPENSE]: 'expense',
  [EXPENSE_TYPES.LIABILITY]: 'liability'
}

export const taxNames = {
  [TAX_TYPES.federal]: 'federal income tax',
  [TAX_TYPES.state]: 'state income tax',
  [TAX_TYPES.local]: 'local income tax',
  [TAX_TYPES.socialSecurity]: 'social security tax',
  [TAX_TYPES.medicare]: 'medicare tax',
  [TAX_TYPES.fica]: 'fica tax'
}

export const assetNames = {
  [ASSET_TYPES.INVESTMENT]: 'investment',
  [ASSET_TYPES.PROPERTY]: 'property',
  [ASSET_TYPES.CASH_AND_EQUIVALENTS]: 'account'
}

export const contributionTypes = {
  [CONTRIBUTION_TYPES.traditional401k]: '401k',
  [CONTRIBUTION_TYPES.roth401k]: 'Roth 401k',
  [CONTRIBUTION_TYPES.traditionalIRA]: 'IRA',
  [CONTRIBUTION_TYPES.rothIRA]: 'Roth IRA',
  [CONTRIBUTION_TYPES.HSA]: 'HSA',
  [CONTRIBUTION_TYPES.FSA]: 'FSA',
  [CONTRIBUTION_TYPES.DCFSA]: 'DCFSA',
  [CONTRIBUTION_TYPES.HCFSA]: 'HCFSA',
  [CONTRIBUTION_TYPES._529]: '529',
}

export const comparisonWords = {
  [SAVING]: 'saving',
  [EXPENSE_TYPES.EXPENSE]: 'spending'
}

export const confirmMessages = {
  assetRemove: 'Are you sure you want to remove this asset?',
  expenseRemove: 'Are you sure you want to remove this expense?',
  debtRemove: 'Are you sure you want to remove this debt?',
  incomeRemove: 'Are you sure you want to remove this income?',
  memberRemove: 'Are you sure you want to remove this member?',
  goalRemove: 'Are you sure you want to remove this goal?',
  effectRemove: 'Are you sure you want to remove this effect?',
  relocationRemove: 'Are you sure you want to remove this effect?'
}

export const emptyStates = {
  savings: {
    title: 'Your savings',
    body: 'Easily figure out how much you can save each year = money coming in - money going out',
    cta: 'Add incomes and expenses',
  },
  netWorth: {
    title: 'Your Net Worth',
    body: 'Easily figure out your net worth = what you own minus what you own',
    cta: 'Add your assets and liabilities',
  },
  timeline: {
    title: 'Your timeline',
    body: 'To figure out where you\'re going and how fast you can get there, let\'s start with where you\'re starting',
    cta: 'Add your household\'s current finances',
  },
  timelineGoals: {
    title: 'Your timeline',
    body: 'To figure out where you\'re going and how fast you can get there, let\'s start with where you\'re starting',
    cta: 'Add your first goal',
  },
  members: {
    body: 'Add members of your family here, to tailor your recommendations and tax strategies',
    cta: 'Add members'
  },
  retirementGoal: {
    title: 'Calculate your earliest retirement age',
    cta: 'Add incomes and expenses'
  },
}