import { useEffect } from "react";
import { useAppSelector } from "../store";

const useScrollToAnchor = () => {

  const router = useAppSelector((state) => state.router)

  useEffect(() => {
    if (!router.location.hash) return
    setTimeout(() => {
      const part = router.location.hash.split('#')[1]
      const element = document.getElementById(part)
      const top = (element?.offsetTop || 0)
      window.scrollTo(0, (top < 100 ? 0 : top))
    }, 0)

  }, [router.location.hash])
}

export default useScrollToAnchor