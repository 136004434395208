import { socialSecurityTaxConfig } from "../types";

const socialSecurityTaxData: socialSecurityTaxConfig = {
  "2021": {
    bracket: 6.2,
    maxTaxableAmount: 142800
  },
  "2022": {
    bracket: 6.2,
    maxTaxableAmount: 147000
  }
}
export default socialSecurityTaxData