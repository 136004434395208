import styles from "./MemberList.module.css"
import { Member } from "../../lib/types"
import CircleIcon from "../CircleIcon"
import memberIcons from "../../lib/config/memberIcons";
import { memberAgeCategory } from "../../lib/utils/member";

const MemberList = (props: {
  members: Member[]
}) => {
  return (
    <div className={[styles.container, "d-flex flex-column pe-5 py-2"].join(" ")}>
      {props.members.map((member, index) => (
        <div className={["d-flex align-items-center gap-2", (index < props.members.length - 1 ? "mb-3" : "")].join(" ")}
             key={member.id}>
          <CircleIcon
            color={member.color}
            footerIcon={memberIcons[memberAgeCategory(new Date().getFullYear(), member.yearOfBirth)]}
          >
            {member.firstName?.[0] || ""}
          </CircleIcon>
          <div className="subtitle-1">
            {member.firstName}
          </div>
        </div>
      ))}
    </div>
  )
}

export default MemberList