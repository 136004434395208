import { federalIncomeTaxConfig } from "../types";

const federalIncomeTaxData: federalIncomeTaxConfig = {
  "2021": {
    single: [
      {
        min: 0,
        max: 9950,
        bracket: 10,
        floor: 0
      },
      {
        min: 9950,
        max: 40525,
        bracket: 12,
        floor: 995
      },
      {
        min: 40525,
        max: 86375,
        bracket: 22,
        floor: 4664
      },
      {
        min: 86375,
        max: 164925,
        bracket: 24,
        floor: 14751
      },
      {
        min: 164925,
        max: 209425,
        bracket: 32,
        floor: 33603
      },
      {
        min: 209425,
        max: 523600,
        bracket: 35,
        floor: 47843
      },
      {
        min: 523600,
        bracket: 37,
        floor: 157804
      },
    ],
    married: [
      {
        min: 0,
        max: 19900,
        bracket: 10,
        floor: 0
      },
      {
        min: 19901,
        max: 81050,
        bracket: 12,
        floor: 1990
      },
      {
        min: 81051,
        max: 172750,
        bracket: 22,
        floor: 9328
      },
      {
        min: 172751,
        max: 329850,
        bracket: 24,
        floor: 29502
      },
      {
        min: 329851,
        max: 418850,
        bracket: 32,
        floor: 67206
      },
      {
        min: 418851,
        max: 628300,
        bracket: 35,
        floor: 95686
      },
      {
        min: 628301,
        bracket: 37,
        floor: 168994
      },
    ]
  },
  "2022": {
    single: [
      {
        min: 0,
        max: 10275,
        bracket: 10,
        floor: 0
      },
      {
        min: 10275,
        max: 41775,
        bracket: 12,
        floor: 1027.50
      },
      {
        min: 41775,
        max: 89075,
        bracket: 22,
        floor: 4807.5
      },
      {
        min: 89075,
        max: 170050,
        bracket: 24,
        floor: 15213
      },
      {
        min: 170050,
        max: 215950,
        bracket: 32,
        floor: 34647.5
      },
      {
        min: 215950,
        max: 539900,
        bracket: 35,
        floor: 49335.5
      },
      {
        min: 539900,
        bracket: 37,
        floor: 162718
      },
    ],
    married: [
      {
        min: 0,
        max: 20550,
        bracket: 10,
        floor: 0
      },
      {
        min: 20550,
        max: 83550,
        bracket: 12,
        floor: 2055
      },
      {
        min: 83550,
        max: 178150,
        bracket: 22,
        floor: 9615
      },
      {
        min: 178150,
        max: 340100,
        bracket: 24,
        floor: 30427
      },
      {
        min: 340100,
        max: 431900,
        bracket: 32,
        floor: 69295
      },
      {
        min: 431900,
        max: 647850,
        bracket: 35,
        floor: 98671
      },
      {
        min: 647850,
        bracket: 37,
        floor: 174253
      },
    ]
  }
}

export default federalIncomeTaxData